export function throttle<F extends (...args: any[]) => void>(
	fn: F,
	ms: number,
	should_run_first = true,
	multiplier = 0,
) {
	let interval: number | null = null, is_pending: boolean, args: Parameters<F>, attempt = 1

	let clear = () => {
		clearInterval(interval!)
		interval = null
	}

	return (..._args: Parameters<F>) => {
		is_pending = true
		args = _args

		if (!interval) {
			if (should_run_first) {
				is_pending = false
				fn(...args)
			}

			interval = setInterval(() => {
				if (!is_pending) {
					clear()
					return
				}

				is_pending = false
				fn(...args)
			}, ms + attempt * ms * multiplier) as any
		}
	}
}

export function debounce<Fn extends (...args: Parameters<Fn>) => void>(fn: Fn, ms: number, { first = false } = {}) {
	let timeout: number = null

	return function(...args: Parameters<Fn>) {
		if (timeout) {
			clearTimeout(timeout)
			timeout = null
		}
		else if (first) fn(...args)

		timeout = window.setTimeout(() => {
			timeout = null
			fn(...args)
		}, ms)
	}
}
