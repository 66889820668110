import { noripple, ripple } from "#/lib/appearance/ripple/ripple"
import { imageviewer } from "#/lib/appearance/imageviewer"
import { tracing } from "#/lib/mod"

globalThis.tracing = tracing
globalThis.gtrace = tracing("<GLOBAL>")

globalThis.ripple = ripple
globalThis.noripple = noripple
globalThis.imageviewer = imageviewer

