import { onCleanup } from "solid-js"
import { toast } from "solid-toast"

import { type api, errorHandled, langs, ROUTES, signal } from "#/lib/mod"
import { native_emitter } from "#/lib/behaviour"


const DEPLOYED_CALLBACK_ROOT_URL = "https://unisim.foxpro.su"
const FAKE = UNISIM_BUILD_PLATFORM === "android"
const PAYMENT_TIMEOUT_SEC = !import.meta.env.DEV ? 120 : 15

type PaymentResult = { $: "unknown" } | { $: "cancel" } | { $: "success", balance_amount?: string }

type PaymentOptions = {
	action: "order" | "balance"
	requestUrl({ success_url, cancel_url }): Promise<{ url: string } | api.ApiError>
	onFinish?(result: PaymentResult): void
}
export function createPayment(opts: PaymentOptions) {
	let trace = tracing("payment")
	let paying = signal(false)

	let callback_root_url: string
	let params = new URLSearchParams([["action", opts.action]])

	let channel: BroadcastChannel
	let timeout: number, remaining = signal(PAYMENT_TIMEOUT_SEC), remaining_timeout: number

	switch (UNISIM_BUILD_PLATFORM) {
		case "android":
			callback_root_url = !import.meta.env.DEV ? DEPLOYED_CALLBACK_ROOT_URL : window.location.origin
			params.append("platform", "android")
			break
		case "web":
			callback_root_url = window.location.origin
			params.append("platform", "web")
			break
	}

	FAKE && opts.action === "balance" && params.append("balance_amount", "15.00")

	let base_callback_url = `${callback_root_url}${ROUTES.payment_callback.template}?${params.toString()}`

	let success_url = `${base_callback_url}&status=success&`, cancel_url = `${base_callback_url}&status=cancel&`

	if (UNISIM_BUILD_PLATFORM === "web") {
		onCleanup(() => channel?.close())
	}

	async function perform() {
		let response = await opts.requestUrl({ success_url, cancel_url })

		if (errorHandled(response, `${opts.action} payment`)) {
			return false
		}

		if (!response.url || response.url.endsWith("general-error")) {
			toast.error("Failed to acquire payment information")
			return false
		}

		FAKE && params.append("success_url", success_url)
		FAKE && params.append("cancel_url", cancel_url)
		FAKE && (response.url = `${DEPLOYED_CALLBACK_ROOT_URL}${ROUTES.fake_payment.template}?${params.toString()}`)

		let payment_window: Window
		let close_check_interval: number

		if (native_emitter) {
			native_emitter.on(`${opts.action}_payment_callback`, finish)
			native_emitter.on(`tab_hidden`, finish)
		}
		else {
			channel = new BroadcastChannel(opts.action)
			channel.onmessage = async ({ data }: MessageEvent<PaymentResult>) => finish(data)
		}

		async function finish(result?: PaymentResult) {
			trace.info(`finishing ${opts.action} payment`)

			if (channel) {
				channel.close()
				channel = null
			}
			if (native_emitter) {
				native_emitter.off(`${opts.action}_payment_callback`, finish)
				native_emitter.off(`tab_hidden`, finish)
			}

			clearTimeout(timeout)
			clearTimeout(remaining_timeout)
			clearInterval(close_check_interval)

			paying(false)

			if (payment_window && !payment_window.closed) {
				try { payment_window.close() }
				catch (e) { trace.warn("failed to close window", e) }
			}

			opts.onFinish?.(result)
		}

		payment_window = function() {
			if (UNISIM_BUILD_PLATFORM === "android") {
				let url = "android:" + response.url

				trace.debug("opening android url", url)

				return window.open(url, "_top")
			}

			trace.debug("opening web url", response.url)
			return window.open(response.url, "_blank", "popup=true,width=550,height=810")
		}()

		if (!payment_window) {
			trace.error("failed to open window -_-")
			finish({ $: "cancel" })
			return
		}

		paying(true)
		remaining(PAYMENT_TIMEOUT_SEC)

		timeout = window.setTimeout(() => {
			finish({ $: "cancel" })
		}, PAYMENT_TIMEOUT_SEC * 1000)

		function count() {
			remaining_timeout = window.setTimeout(() => {
				let current = remaining()
				remaining(current - 1)

				if (current === 1)
					return

				count()
			}, 1000 - new Date().getSeconds())
		}
		count()

		close_check_interval = window.setInterval(() => {
			if (!payment_window) {
				finish({ $: "cancel" })
			}
			if (payment_window.closed) {
				trace.warn("payment_window was closed")
				finish({ $: "cancel" })
			}
		}, 450)
	}

	return { perform, paying, remaining }
}

export let payment_langs = langs({
	ru: {
		awaiting_payment: "Ожидание оплаты",
		payment_successful: "Платёж успешно завершен",
		sec: "сек.",

		errors: {
			payment_status_unknown: "Информация о статусе платежа не получена. Перезагрузите страницу и/или повторите платёж",
			payment_cancelled: "Платёж был отменён",
		}
	},
	en: {
		awaiting_payment: "Awaiting payment",
		payment_successful: "Payment was successful",
		sec: "s.",

		errors: {
			payment_status_unknown: "Payment status information is not received. Reload the page and/or repeat the payment",
			payment_cancelled: "Payment was cancelled",
		}
	}
})