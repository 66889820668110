import type { ComponentProps } from "solid-js"
import { ItemIconWrapper } from "./item-icon-wrapper"

export function ArrowBack(props: ComponentProps<"div">) {
	return (
		<svg class=":c: size-6 dark:c-white light:c-gray-300" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M14.3043 17.299C14.565 17.0308 14.5653 16.5956 14.3049 16.327L10.11 11.9995L14.3049 7.67303C14.5652 7.40448 14.565 6.96931 14.3044 6.70104C14.0437 6.43278 13.6213 6.43301 13.3609 6.70156L8.69514 11.5137C8.43497 11.7821 8.43495 12.2168 8.69509 12.4852L13.3609 17.2984C13.6212 17.567 14.0436 17.5672 14.3043 17.299Z" fill="black" />
		</svg>
	)
}
