import { createMemo, For, Show, type ComponentProps, type ParentProps } from "solid-js";
import { Dynamic } from "solid-js/web";
import toast from "solid-toast";
import { Transition } from "solid-transition-group";

import { ROUTES, useRouter, useAuth, langs, type ComponentLike, signal, drop, useLayout, api, errorHandled } from "#/lib/mod"
import { UserAvatar, UnisimLogoIcon, Menu } from "#/components/mod"
import { Theme, useTheme } from "#/lib/appearance/theme";

import { createPageContext, PageContext } from "./page.conext";
import { ViewMode } from "../../layout.context";

export const DESKTOP_NAVBAR_WIDTH = "14rem", DESKTOP_PAGE_WIDTH = "720px"

export function LayoutWrapper(props: ParentProps) {
	let auth = useAuth()

	return (
		<div
			classList={{
				":c: absolute w-full h-full inline-flex": true,
			}}
		>
			<DesktopNavbarPlaceholder />
			<div class=":c: grow-1 h-full relative">
				{props.children}
			</div>
		</div>
	)
}

type DesktopPageProps<R extends ComponentLike<"div"> = ComponentLike<"div">> = {
	centered?: boolean
	Root?: ComponentLike<"div">
	Cols?: ComponentLike<"div">
	MainCol?: ComponentLike<"div">
} & ParentProps & ComponentProps<R>

/**
 * props would be applied to MainCol (most inner component)
 */
export function DesktopPage(props: DesktopPageProps) {
	let other = drop(
		props,
		"centered",

		"Root",
		"Cols",
		"MainCol",

		"children",
	);

	let {
		Root = p => <page_root {...p} />,
		Cols = p => <cols {...p} />,
		MainCol = p => <col {...p} />,
	} = props;


	let page_context = createPageContext()

	return <PageContext.Provider value={page_context}>
		<Root
			classList={{
				":c: absolute h-full w-full overflow-(x-clip y-auto) scrollbar-gutter-stable": true,
			}}
			ref={r => {
				page_context.scrolling_root = r
			}}
		>
			<Cols
				classList={{
					"min-wfit size-full max-w-[var(--page-width)] flex justify-center z-101": true,
					":c: [&>*:nth-child(1)]:(w-750px relative)": true,
					":c: [&>*:nth-child(2)]:(w-150px relative)": true,
				}}
			>
				<MainCol
					{...other}
					ref={r => {
						page_context.modal_root = r
						props.ref?.(r)
					}}
				>
					{props.children}
				</MainCol>
			</Cols>
		</Root>
	</PageContext.Provider>
}

export function DesktopNavbar() {
	let auth = useAuth(), app = useLayout()
	let { theme, transitTheme } = useTheme()
	let isActive = (path: string) => router.top?.pathname === path
	let router = useRouter()
	let i = signal(0)
	let hover = signal(false)

	let t = langs({
		ru: {
			[ROUTES.community.template]: "Сообщество",
			[ROUTES.projects.template]: "Проекты",
			[ROUTES.deals.template]: "Дела",
			[ROUTES.chats.template]: "Сообщения",
			[ROUTES.notifications.template]: "Уведомления",
			["more"]: "Действия",
			[ROUTES.profile.resolve(auth.user.nickname)]: "Профиль",
		},
		en: {
			[ROUTES.community.template]: "Community",
			[ROUTES.projects.template]: "Projects",
			[ROUTES.deals.template]: "Deals",
			[ROUTES.chats.template]: "Messages",
			[ROUTES.notifications.template]: "Notifications",
			["more"]: "Actions",
			[ROUTES.profile.resolve(auth.user.nickname)]: "Profile",
		},
	})

	let logos = [
		p => <UnisimLogoIcon {...p} />
	]

	return (
		<navbar
			ref={r => app.navbar = r}
			class=":c: min-w-fit pt-4 pl-2 grow-1 min-w-fit max-w-[calc(50lvw-var(--page-width)/2)] h-100dvh"
		>
			<div class=":c: h-inherit m-l-auto relative" style={{ "width": DESKTOP_NAVBAR_WIDTH }} >
				<Dynamic component={logos[i()]} class=":c: w-70% h-15 p-l-2" onClick={() => i(i => (i + 1) % logos.length)} />
				<div class=":c: absolute top-18 bottom-0 pb-5 flex flex-col items-start gap-4">
					<For
						each={[
							{
								path: ROUTES.deals.template,
								Icon: p => <i class="i-hero:rectangle-stack" {...p} />,
							},
							{
								path: ROUTES.projects.template,
								Icon: p => <i class="i-hero:squares-plus" {...p} />,
							},
							{
								path: ROUTES.community.template,
								Icon: p => <i class="i-hero:user-group" {...p} />
							},
							{
								path: ROUTES.chats.template,
								Icon: p => <i class="i-hero:chat-bubble-oval-left-ellipsis" {...p} />
							},
							{
								path: ROUTES.notifications.template,
								container_class: "cursor-not-allowed",
								Icon: p => <i class="i-hero:bell" {...p} />,
								onClick: Function.NOOP
							},
						]}
					>
						{({ path, container_class, Icon, onClick }, index) => {
							let active = createMemo(isActive.bind(null, path))
							return (
								<div
									classList={{
										":c: flex items-center gap-3 p-2 ptr rounded-18px": true,
										":c: select-none light:hover:bg-gray-200 dark:hover:bg-gray-800/50": true,
										[container_class]: !!container_class
									}}
									onClick={() => onClick ? onClick() : router.switchTo({ preserve: true, path })}
								>
									<Icon
										active={active()}
										classList={{
											":c: size-7 dark:c-gray-600 light:c-gray-300": true,
											"c-blue-500!": active()
										}}
									/>
									<span
										classList={{
											":c: font-500 text-xl dark:c-gray-600 light:c-gray-300": true,
											"c-blue-500!": active(),
										}}
										innerText={t()[path]}
									/>
								</div>
							)
						}}
					</For>
					<div
						class="rounded-18px mt-auto"
						classList={{ "dark:bg-gray-800/50": hover() }}
						onMouseLeave={() => {
							hover(false)
						}}
					>
						<Show when={hover()}>
							<Menu.MenuItem
								icon_class={app.is_mobile ? "i-hero:computer-desktop" : "i-hero:device-phone-mobile"}
								text={app.is_mobile ? "Десктопная версия" : "Мобильная версия"}
								onClick={() => {
									if (app.is_mobile) {
										app.setViewMode(ViewMode.Desktop)
									}
									else if (app.is_desktop) {
										app.setViewMode(ViewMode.Mobile)
									}
								}}
							/>
							<Menu.MenuItem
								icon_class={theme() === Theme.Dark ? "i-hero:sun-solid" : "i-hero:moon-solid"}
								text={theme() === Theme.Dark ? "Светлая тема" : "Тёмная тема"}
								onClick={() => {
									transitTheme(theme() === Theme.Dark ? Theme.Light : Theme.Dark)
								}}
							/>
							<Menu.MenuItem
								icon_class="i-hero:user"
								text="Профиль"
								onClick={() => {
									router.stack({ path: ROUTES.profile_settings.template })
								}}
							/>
							<Menu.MenuItem
								icon_class="i-hero:pencil"
								text="Интересы"
								onClick={() => {
									router.stack({ path: ROUTES.profile_interests.template })
								}}
							/>
							<Menu.MenuItem
								icon_class="i-hero:sparkles icon-premium"
								text="Premium"
								text_class="text-premium fw-semibold!"
							/>
							<Menu.MenuItem
								class="c-orange-4"
								icon_class="i-hero:trash"
								text="К онбордингу"
								onClick={() => {
									router.switchTo(ROUTES.onboarding.template)
								}}
							/>
							<Menu.MenuItem
								class="c-red"
								icon_class="i-hero:arrow-left-end-on-rectangle"
								text="Выйти"
								onClick={() => {
									router.switchTo(ROUTES.login.template).then(() => {
										console.log("Switched to login", window.location.pathname)
										auth.logout()
									})
								}}
							/>
							<Menu.MenuItem
								class="c-red"
								icon_class="i-hero:trash"
								text="Удалить аккаунт"
								onClick={async () => {
									let result = await api.req<void>("/api/user", {
										method: "DELETE",
									})

									if (errorHandled(result, "Unable to delete account")) {
										return
									}
									toast.success(`Deleted user ${result}`)
									auth.logout()
								}}
							/>
						</Show>
						{function() {
							let path = "more"
							return <div
								classList={{
									":c: flex items-center gap-3 p-2 ptr rounded-inherit": true,
									":c: select-none light:hover:bg-gray-200": true,
								}}
								onMouseEnter={() => {
									hover(true)
								}}

							>
								<i class="i-hero:ellipsis-horizontal-circle"
									classList={{
										":c: size-7 dark:c-gray-600 light:c-gray-300": true,
									}}
								/>
								<span
									classList={{
										":c: font-500 text-xl dark:c-gray-600 light:c-gray-300": true,
									}}
									innerText={t()[path]}
								/>
							</div>
						}()}
					</div>
					{function() {
						let path = ROUTES.profile.resolve(auth.user.nickname)
						let active = createMemo(() => isActive(path))
						return <div
							classList={{
								":c: flex items-center gap-3 p-2 ptr rounded-18px mb-2": true,
								":c: select-none light:hover:bg-gray-200 dark:hover:bg-gray-800/50": true,
							}}
							onClick={(e) => {
								router.switchTo({ preserve: true, path })
							}}
						>
							<UserAvatar
								user={auth.user}
								classList={{
									"uno-layer-v2:(outline-(2px solid blue-500))": active(),
									":c: size-7 dark:c-gray-600 light:c-gray-300": true,
									"c-blue-500!": active()
								}}
							/>
							<span
								classList={{
									":c: font-500 text-xl dark:c-gray-600 light:c-gray-300": true,
									"c-blue-500!": isActive(ROUTES.profile.resolve(auth.user.nickname)),
								}}
								innerText={t()[path]}
							/>
						</div>
					}()}
				</div>
			</div>
		</navbar>
	)
}

export function DesktopNavbarPlaceholder() {
	let router = useRouter()
	let auth = useAuth()
	let isNavbarAvailable = useIsDesktopNavbarAvailable(() => router.top)

	const enter_exit_active_class = ":c: transition-(duration-250 property-[margin,opacity] ease-[cubic-bezier(0.2,0.75,0.15,0.98)])"
	const enter_exit_class = ":c: opacity-0 ml-[-14rem]!"

	let FallBack = <nonavbar style={{ "width": DESKTOP_NAVBAR_WIDTH }} class=":c: grow-1 max-w-[calc(50lvw-var(--page-width)/2)] h-100dvh" />
	return (
		<Show when={auth.user && auth.is_authenticated} fallback={FallBack}>
			<Transition
				enterActiveClass={enter_exit_active_class}
				exitActiveClass={enter_exit_active_class}
				enterClass={enter_exit_class}
				exitToClass={enter_exit_class}
			>
				<Show when={isNavbarAvailable()} fallback={FallBack}>
					<DesktopNavbar />
				</Show>
			</Transition>
		</Show>
	)
}




export function useIsDesktopNavbarAvailable(view = () => useRouter().view) {
	let DESKTOP_NAVBAR_FORBIDDEN_ROUTES = [
		ROUTES.login,
		ROUTES.onboarding,
		ROUTES.chat,
		ROUTES.deal_edit,
		ROUTES.post,
		ROUTES.all,
	] as const

	// @ts-ignore
	return createMemo(() => !DESKTOP_NAVBAR_FORBIDDEN_ROUTES.includes(view()?.placeholder.route))
}
