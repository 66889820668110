import { Show } from "solid-js"

import {
	drop, recompose,
	type ComponentLike, type ComposableComponentLike, type ComposableComponentProps
} from "#/lib/rx/mod"

type InputComponent = ComponentLike<"input">
type InputProps = {
	error?: string
	success?: boolean
	Input?: InputComponent
	Error?: ComponentLike<"div">
	class?: string
} & ComposableComponentProps<InputComponent>

export function BasicInput(props: InputProps) {
	let { composed, uncomposed } = recompose(props, "class", "classList", "Input", "Error", "error", "success")

	let {
		Error = BasicInput.Error,
		Input = p => <input {...p} /> as any,
	} = uncomposed

	return [
		<Input {...composed}
			classList={{
				[uncomposed.class]: uncomposed.class != null,
				...uncomposed.classList,
				":c: w-full h-11 p-inline-3 resize-none font-300 rounded-2px b-unset rounded-t-2px b-b-(2px solid offset-0) shrink-0": true,
				":c: dark:(bg-gray-950 c-white b-b-gray-700/60) light:(bg-gray-000 c-black-999 b-b-gray-200)": true,
				"focus:(b-b-#bae300)": true,
				":c: disabled:(dark:bg-gray-800/30 light:bg-gray-000/30 dark:c-gray-400 light:c-gray-600)": true,
				"uno-layer-v1:b-red-500": !!uncomposed.error,
				"uno-layer-v1:b-green-5": uncomposed.success,
			}}
		/>,
		<Show when={props.error} children={<Error error={props.error} />} />,
	]
}


export function BasicInput2(props: InputProps) {
	return <BasicInput {...props}
		classList={{
			...props.classList,
			"uno-layer-v4:(bg-#FFFFFF rounded-6px b-(1px solid #C3CAD4) font-300 c-#808080 text-4)": true
		}}
	/>
}

BasicInput.Root = function(props: ComposableComponentProps<"div"> & { class?: string }) {
	let other = drop(props, "class", "classList")
	return <div {...other} classList={{ ...props.classList, [props.class]: !!props.class, "relative": true }} />
}

type InputErrorProps = {
	class?: string
	error?: string
} & ComposableComponentProps<"div">

BasicInput.Error = (props: InputErrorProps) => (
	<div
		{...drop(props, "classList")}
		classList={{ ...props.classList, ":c: c-#FF0733 font-300 text-3 mt-1 contain-none": true }}
		innerText={props.error}
	/>
)

type IconWProps = {
	right?: boolean
	x?: number

	wrapperc?: string
	Wrapper?: ComponentLike<"div">

	iconc?: string
	Icon?: ComposableComponentLike<"i">

	base_padding?: string
} & ComposableComponentProps<ComponentLike<"div">>

BasicInput.IconW = function(props: IconWProps) {
	let { composed, uncomposed } = recompose(props, "x", "right", "wrapperc", "Wrapper", "base_padding", "iconc", "Icon", "classList")

	let {
		Wrapper = p => <div {...p} />,
		Icon = p => <i {...p} />,
	} = uncomposed

	return (
		<Wrapper
			{...composed}
			classList={{
				":c: w-7 h-full rounded-inherit abs-centered-y": true,
				...uncomposed.classList,
				[uncomposed.wrapperc]: !!uncomposed.wrapperc,
			}}
			style={{
				[uncomposed.right ? "right" : "left"]: `calc(${uncomposed.base_padding ?? "8px"} + ${uncomposed.x ?? 0} * 30px)`,
			}}
			children={<Icon classList={{ ":c: size-6 abs-centered": true, [uncomposed.iconc]: !!uncomposed.iconc }} />}

		/>
	)
}

type WordCounterProps = {
	max: number
	current: number

	class?: string
} & ComposableComponentProps<"span">

BasicInput.WordCounter = function(props: WordCounterProps) {
	let other = drop(props, "max", "current", "class", "classList")

	return (
		<Show when={props.max - props.current < 20}>
			<span
				innerText={props.max - props.current}
				{...other}
				classList={{
					...props.classList,
					":c: text-3 fw-semibold": true,
					":c: uno-layer-v1:c-red-400": props.max - props.current < 12,
					":c: uno-layer-v2:c-red-500": props.current > props.max,
					[props.class]: props.class != null
				}}
			/>
		</Show>
	)
}
