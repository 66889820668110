import { ComponentLike } from "#/lib/mod"

type FilterButtonProps = {
	Root?: ComponentLike<"div">
	active: boolean
}

export function FilterButton(props: FilterButtonProps) {
	let {
		Root = p => <div {...p} />
	} = props

	return <Root
		classList={{
			":c: p-inline-6 p-block-2.5 rounded-10.5 b-(1px solid gray-200) ptr select-none": true,
			":c: uno-layer-v1:(bg-green-500 font-semibold b-transparent)": props.active
		}}
	/>
}
