import { createEffect, Match, on, onCleanup, Show, Switch } from "solid-js"
import { createMutable } from "solid-js/store"
import { toast } from "solid-toast"

import { MobilePage, createPayment, dictionary, payment_langs } from "#/common/mod"
import { CURRENCY_SYMBOLS, ROUTES, api, formatFloatLike, lang, langs, useAuth, useRouter } from "#/lib/mod"
import { BackGroup, BasicInput, BasicInput2, ContinueButton, DropdownSection, ItemIconWrapper, Spinner, createBankSelect } from "#/components/mod"


const MIN_BALANCE_PRICE_USD = 10.00, MAX_BALANCE_PRICE_USD = 9999

export function BalancePageSheet(props?: { esim?: { imsi: string } }) {
	let router = useRouter()

	return <MobilePage id="balance-sheet"
		classList={{
			":c: uno-layer-v2:(rounded-t-20px bg-white bg-([position:50%_100%] no-repeat))": true,
		}}
	>
		<div class=":c: flex items-end justify-between h-10 m-inline-4 m-b-5">
			<span innerText={t().title.sheet} class=":c: text-xl font-500" />
			<ItemIconWrapper
				children={<i class=":c: i-hero:x-mark size-6" />}
				onClick={() => router.unstack()}
			/>
		</div>
		<BalancePageInner {...props} />
	</MobilePage>
}

export function BalancePageStacked(props?: { esim?: { imsi: string } }) {
	return <MobilePage id="balance-stacked" class=":c: uno-layer-v2:(bg-white)">
		<BackGroup />
		<div class=":c: text-2xl font-600 m-inline-4 m-b-4">{t().title.stacked}</div>
		<BalancePageInner {...props} />
	</MobilePage>
}

function BalancePageInner(props?: { esim?: { imsi: string } }) {
	let router = useRouter(),
		auth = useAuth(),
		trace = tracing("BalancePage")

	let bank = createBankSelect()

	let channel = new BroadcastChannel("balance")

	queueMicrotask(bank.load)

	createEffect(on(lang, async (lang) => {
		if (!bank.bank) await bank.load()

		if (lang !== "ru") {
			bank.variant = "usd"
			trace.debug("forced new bank variant due to language change")
		}
	}, { defer: true }))

	onCleanup(() => {
		channel.close()
	})

	let state = createMutable({
		imsi: (props.esim?.imsi ?? null) as string,
		value_usd: 25,

		submitting: false,
	})

	let err = createMutable({
		imsi: null as string,
		value_usd: null as string,
	})

	let check = {
		imsi() {
			err.imsi = function() {
				if (state.imsi?.length !== 15)
					return "Некорректный IMSI"

				return null
			}()
		},
		value_usd() {
			err.value_usd = function() {
				if (state.value_usd !== Math.clamp(state.value_usd, MIN_BALANCE_PRICE_USD, MAX_BALANCE_PRICE_USD))
					return t().errors.range

				return null
			}()
		},
	}

	let canContinue = () => !Object.values(err).filter(Boolean).length

	let { perform, remaining, paying } = createPayment({
		action: "balance",
		requestUrl: ({ success_url, cancel_url }) => api.createOrder({
			product: "esim_balance",

			imsi: state.imsi,
			amount: state.value_usd,
			bank_id: bank.bank.bank_id as any,

			user_lang: lang(),

			success_url,
			cancel_url,
		}),
		onFinish(result) {
			if (!result || result.$ === "unknown") {
				toast.error(payment_langs().errors.payment_status_unknown)
				trace.error("Unknwon payment status", result)
				return
			}

			if (result.$ === "success") {
				toast.success(payment_langs().payment_successful)
				trace.debug("balance_amount", result.balance_amount)

				if (auth.is_authenticated) auth.refetchUser()
				router.switchTo({ path: ROUTES.balance_completed.template, props: { balance_amount: result.balance_amount } })
				return
			}

			toast.error(payment_langs().errors.payment_cancelled)
		},
	})

	async function submit() {
		state.submitting = true

		for (let fn_key in check) check[fn_key]()

		if (!canContinue()) {
			state.submitting = false
			return
		}

		await perform()
		state.submitting = false
	}

	return <>
		<Show when={paying()}>
			<div class=":c: absolute inset-0 bg-gray-800/90 backdrop-blur-2px rounded-inherit z-1" />
			<span
				class=":c: abs-centered c-white font-600 text-2xl z-2 ws-nowrap"
				children={[payment_langs().awaiting_payment, " ", remaining(), payment_langs().sec]}
			/>
		</Show>

		<BasicInput.Root class=":c: m-inline-4">
			<label innerText="IMSI" class=":c: font-300" />
			<BasicInput2
				class=":c: m-t-2"
				placeholder="3748892098776573"
				value={state.imsi}
				onInput={({ currentTarget: { value } }) => {
					err.imsi = null
					state.imsi = value
				}}
				error={err.imsi}
			/>
		</BasicInput.Root>

		<BasicInput.Root class=":c: m-inline-4 m-t-6">
			<label innerText={t().amount} class=":c: font-300" />
			<BasicInput2 class=":c: m-t-2"
				placeholder="5$"
				value={state.value_usd}
				onInput={(e, { currentTarget: { value } } = e) => {
					let as_number = Number(value)
					if (isNaN(as_number)) {
						e.currentTarget.value = String(state.value_usd)
						return
					}

					if (as_number > MAX_BALANCE_PRICE_USD) {
						e.currentTarget.value = String(MAX_BALANCE_PRICE_USD)
						state.value_usd = MAX_BALANCE_PRICE_USD
						err.value_usd = null
						return
					}

					if (as_number !== Math.clamp(as_number, MIN_BALANCE_PRICE_USD, MAX_BALANCE_PRICE_USD)) {
						err.value_usd = t().errors.range
						return
					}

					e.currentTarget.value = String(as_number)
					state.value_usd = as_number
					err.value_usd = null

				}}
				onChange={({ currentTarget }, { value } = currentTarget) => {
					let as_number = Number(value) || MIN_BALANCE_PRICE_USD
					as_number = Math.clamp(as_number, MIN_BALANCE_PRICE_USD, MAX_BALANCE_PRICE_USD)

					state.value_usd = as_number
					currentTarget.value = String(as_number)
					err.value_usd = null
				}}
				error={err.value_usd}
			/>
		</BasicInput.Root>

		<ContinueButton
			class=":c: uno-layer-v1:(text-4 font-700 flex-center gap-1 m-inline-4 m-t-8 m-b-0)"
			disabled={bank.loading || bank.bank == null || state.submitting || state.value_usd <= 0 || !canContinue()}
			onClick={submit}
			ref={ripple}
		>
			<Switch>
				<Match when={bank.loading || bank.bank == null}>
					Загрузка платёжной инфы
				</Match>
				<Match when={state.submitting}>
					<span innerText={dictionary().common.loading} />
					<Spinner class="size-5" />
				</Match>
				<Match when={state.value_usd <= 0}>
					<span innerText={t().pay} />
				</Match>
				<Match when={true}>
					{t().pay} {bank.variant !== "usd" && "~"}{formatFloatLike(state.value_usd * bank.bank.rate, lang(), { maximumFractionDigits: 0 })} {CURRENCY_SYMBOLS[bank.variant.toUpperCase()]}
				</Match>
			</Switch>
		</ContinueButton>

		<Show when={lang() === "ru"}>
			<div
				class=":c: flex items-center gap-2 m-inline-4"
				onClick={e => {
					e.preventDefault()
					bank.toggle()
				}}
			>
				<bank.Component class=":c: m-t-4.5" />
				<label class=":c: select-none flex items-center gap-2 m-t-4" innerText={t().russian_card} />
			</div>
		</Show>

		<div class=":c: m-t-9">
			{Object.keys(t().problems).map((problem_key, _i, _arr, value = t().problems[problem_key]) =>
				<DropdownSection.FaqSection
					Title={p => <div {...p} children={value.title} />}
					children={value.text}
				/>
			)}
		</div>
	</>
}

let t = langs({
	ru: {
		title: {
			stacked: "Пополнение",
			sheet: "Пополнить eSIM",
		},

		amount: "Сумма",
		pay: "Оплатить",
		russian_card: "Оплата картой РФ",

		errors: {
			range: `Введите сумму от ${MIN_BALANCE_PRICE_USD} до ${MAX_BALANCE_PRICE_USD}`,
		},

		problems: {
			what_is_imsi: {
				title: "Что такое IMSI?",
				text: () => <div class=":c: [&>span]:(underline ptr)">
					Номер IMSI можно узнать в <span>личном кабинете</span> или посмотреть в письме с установочными данными eSIM.
					<br />
					<br />
					Проверка баланса осуществляется через <span class="" tabindex="0">личный кабинет</span>.
					Для авторизации нажмите на иконку в правой верхней части сайта, введите почту и перейдите по ссылке из письма.
					После авторизации обновите баланс, нажав на зеленую стрелку в карточке нужной eSIM.
				</div>
			},
			balance_usd: {
				title: "Баланс в долларах?",
				text: `Баланс UNISIM, тарифы, а также стоимость выпуска eSIM, исчисляется в долларах. В связи с этим на этапе покупки указывается сумма в долларах, а на этапе оплаты производится автоматический пересчет в рубли, если выбрана оплата картой РФ. Для зарубежных карт оплата производится в долларах.`
			},
			hoew_to_check_balance: {
				title: "Как проверить баланс и IMSI?",
				text: `Баланс UNISIM, тарифы, а также стоимость выпуска eSIM, исчисляется в долларах. В связи с этим на этапе покупки указывается сумма в долларах, а на этапе оплаты производится автоматический пересчет в рубли, если выбрана оплата картой РФ. Для зарубежных карт оплата производится в долларах.`
			},
		},

	},
	en: {
		title: {
			stacked: "Top up",
			sheet: "Top up",
		},

		pay: "Pay",
		amount: "Amount",
		russian_card: "Paying with Russian card",

		errors: {
			range: `Enter value from ${MIN_BALANCE_PRICE_USD} to ${MAX_BALANCE_PRICE_USD}`,
		},
		problems: {
			what_is_imsi: {
				title: "What is IMSI?",
				text: () => <div class="[&>span]:(underline ptr)">
					You can find your IMSI number in your <span>personal account</span> or check the email with your eSIM setup data.
					<br />
					<br />
					Balance checking is done through your <span class="" tabindex="0">personal account</span>.
					To authorize, click on the icon in the top right of the website, enter your email and follow the link from the email.
					After authorization, update the balance by clicking the green arrow in the card of the desired eSIM.
				</div>
			},
			balance_usd: {
				title: "Balance in USD?",
				text: `UNISIM balance, tariffs, and eSIM issuance costs are calculated in dollars. Therefore, the amount is shown in dollars at the purchase stage, and at the payment stage, it is automatically converted to rubles if payment is made with a Russian card. For foreign cards, payment is made in dollars.`
			},
			hoew_to_check_balance: {
				title: "How to check balance and IMSI?",
				text: `UNISIM balance, tariffs, and eSIM issuance costs are calculated in dollars. Therefore, the amount is shown in dollars at the purchase stage, and at the payment stage, it is automatically converted to rubles if payment is made with a Russian card. For foreign cards, payment is made in dollars.`
			},
		},
	}
})
