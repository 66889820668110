/* @refresh reload */
import { lazy, Match, Show, Switch } from "solid-js"

import { useAuth, ROUTES } from "#/lib/mod"
import { AuthenticationGuard } from "#/components/mod"
import { Navigate, Router } from "#/lib/navigation/navigation"

import { MobileNavbarPlaceholder } from "./mobile-navbar"

// PAGES
let OnboardingPage = lazy(() => import("#/domains/onboarding/onboarding.page"))
let LoginPage = lazy(() => import("#/domains/login/login.page"))
import MainPage from "#/domains/main/main.page"
import { BalancePageSheet, BalancePageStacked } from "#/domains/balance/balance.page"
import HistoryPage from "#/domains/history/history.page"

import { BonusPageStacked, BonusPageMain } from "#/domains/bonus/bonus.page"

import OrderPage from "#/domains/order/order.page"
let OrderCompletedPage = lazy(() => import("#/domains/order/order_completed.page"))

import MorePage from "#/domains/more/more.page"
import LanguagesPage from "#/domains/more/languages.page"
let TermsPage = lazy(() => import("#/domains/more/terms/terms.page"))
let PrivacyPolicyPage = lazy(() => import("#/domains/more/privacy/privacy.page"))
let ReferalProgramPage = lazy(() => import("#/domains/more/referal/referal.page"))
let ContactsPage = lazy(() => import("#/domains/more/contacts.page"))

import HelpPage from "#/domains/help/help.page"
let GuidePage = lazy(() => import("#/domains/help/guide/guide.page"))
import CompatibilityPage from "#/domains/help/compatibility.page"
import BonusAnonPage from "#/domains/bonus/bonus.anon.page"
let BalanceCompletedPage = lazy(() => import("#/domains/balance/balance_completed.page"))

let PaymentCallbackPage = lazy(() => import("#/domains/payment_callback.page"))
let FakePaymentPage = lazy(() => import("#/domains/fake_payment.page"))


export default function MobileNavigator() {
	let auth = useAuth()

	return (
		<Router>
			<Router.Routes>

				<Router.RoutePlaceholder
					route={ROUTES.onboarding}
					component={OnboardingPage}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.login}
					component={LoginPage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.main}
					component={MainPage}
					preserve
				/>
				<Router.RoutePlaceholder
					route={ROUTES.balance_sheet}
					component={BalancePageSheet}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.balance_stacked}
					component={BalancePageStacked}
					preserve={false}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.balance_completed}
					component={BalanceCompletedPage}
					preserve={false}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.bonus_anon}
					component={BonusAnonPage}
				/>

				<Show when={auth.is_authenticated}>
					<Router.RoutePlaceholder
						route={ROUTES.bonus_main}
						component={BonusPageMain}
						preserve
					/>
					<Router.RoutePlaceholder
						route={ROUTES.bonus_stacked}
						component={BonusPageStacked}
						preserve
					/>

					<Router.RoutePlaceholder
						route={ROUTES.history}
						component={HistoryPage}
						preserve
					/>
				</Show>

				<Router.RoutePlaceholder
					route={ROUTES.order}
					component={OrderPage}
					preserve
				/>
				<Router.RoutePlaceholder
					route={ROUTES.order_completed}
					component={OrderCompletedPage}
					preserve={false}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.help}
					component={HelpPage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.compatibility}
					component={CompatibilityPage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.guide}
					component={GuidePage}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.more}
					component={MorePage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.languages}
					component={LanguagesPage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.contacts}
					component={ContactsPage}
					preserve={false}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.terms}
					component={TermsPage}
					preserve={false}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.privacy}
					component={PrivacyPolicyPage}
				/>
				<Router.RoutePlaceholder
					route={ROUTES.referal_program}
					component={ReferalProgramPage}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.payment_callback}
					component={PaymentCallbackPage}
					preserve={false}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.fake_payment}
					component={FakePaymentPage}
					preserve={false}
				/>

				<Router.RoutePlaceholder
					route={ROUTES.all}
					component={function() {
						return (
							<AuthenticationGuard>
								<Switch>
									<Match when={auth.is_authenticated}
										children={<Navigate to={ROUTES.main.template} />}
									/>
								</Switch>
							</AuthenticationGuard>
						)
					}}
				/>
			</Router.Routes>
			<MobileNavbarPlaceholder />
		</Router>
	)
}
