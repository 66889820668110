import { useRouter } from "#/lib/mod"
import { ArrowBack } from "#/components/mod"
import { dictionary } from "#/common/mod"


export function BackGroup(props) {
	let router = useRouter()

	return <div
		classList={{
			":c: w-full bg-inherit z-99 p-t-safe h-10 relative self-start p-inline-4 m-t-2 m-b-4": true,
			[props.class]: props.class != null,
			...props.classList
		}}
	>
		<div class=":c: abs-centered-y flex items-center ptr" onClick={() => router.unstack()}>
			<ArrowBack />
			<div class=":c: leading-5 font-300" innerText={dictionary().common.back} />
		</div>
	</div>
}
