import { For, Show, createEffect, on } from "solid-js"
import { createMutable } from "solid-js/store"

import { CURRENCY_SYMBOLS, api, errorHandled, formatFloatLike, langs, throttle } from "#/lib/mod"
import { MobilePage } from "#/common/mod"
import { FilterButton, HorizontalLine, Spinner } from "#/components/mod"
import { LANGS_META, useI18n } from "#/lib/appearance/i18n"
import { BackGroup } from "#/components/prefabs/back-group"


export default function HistoryPage() {
	let { lang } = useI18n()

	let state = createMutable({
		scope: "all" as api.GetOperationsParams["scope"],

		operations_per_scope: {
			all: [] as api.GetOperationsResponse,
			purchases: [] as api.GetOperationsResponse,
			bonuses: [] as api.GetOperationsResponse,
		},

		loading: false
	})

	let fetchOperationsForScope = throttle(async () => {
		state.loading = true

		let { scope } = state
		let response = await api.getOperations({ scope })

		if (errorHandled(response)) {
			state.loading = false
			return
		}

		state.operations_per_scope[scope] = response
		state.loading = false
	}, 1500)

	createEffect(on(() => state.scope, fetchOperationsForScope))

	return <MobilePage>
		<BackGroup />

		<div class="m-b-5">
			<div class=":c: flex items-center gap-1 m-inline-4">
				<span class=":c: font-700 text-6" innerText={t().title} />
				{state.loading && <Spinner />}
			</div>

			<div class=":c: flex gap-2 [&>*]:shrink-0 overflow-(x-auto y-none) scrollbar-none p-inline-4 m-t-5"
				children={["all", "purchases", "bonuses"].map(scope =>
					<FilterButton
						Root={p =>
							<div {...p}
								innerText={t().filters[scope]}
								onClick={() => state.scope = scope}
							/>
						}
						active={state.scope === scope}
					/>
				)}
			/>
		</div>

		<For
			each={state.operations_per_scope[state.scope]}
			children={(op, i) =>
				<div class=":c: p-inline-4 relative [&+&]:m-t-2.5" ref={ripple}>
					<div class=":c: flex items-center justify-between">
						<div class=":c: flex flex-col gap-6px">
							<span class=":c: text-3.5 font-300" innerText={t().reasons[op.reason]} />
							<Show when={op.esim != null}>
								<span class=":c: font-300 text-3.5 c-#4D4D4D">{op.esim.alias} / {op.esim?.imsi}</span>
							</Show>
							<span class=":c: font-300 text-3.5 c-#4D4D4D">{new Date(Date.parse(op.created_at)).toLocaleString(LANGS_META[lang()].locale_name)}</span>
						</div>
						<div class=":c: text-4 font-400">
							{op.type === "DR" ? "+" : "-"} {formatFloatLike(op.amount, lang())} {CURRENCY_SYMBOLS.USD}
						</div>
					</div>
					{i() !== state.operations_per_scope[state.scope].length - 1 && <HorizontalLine class=":c: w-full block m-t-2.5" />}
				</div>
			}
		/>
	</MobilePage>
}

let t = langs({
	ru: {
		title: "История операций",
		reasons: {
			esim_purchase: "Покупка eSIM",
			esim_topup: "Пополнение баланса eSIM",
			referral_income: "Начисление бонусов",
			refund: "Возврат средств",
			order_compensation: "Перевод бонусов на баланс",
			manual: "Ручная операция",
		} satisfies Record<api.GetOperationsResponse[number]["reason"], string>,

		filters: {
			all: "Все операции",
			purchases: "Покупки",
			bonuses: "Бонусы",
		}
	},
	en: {
		title: "Operation history",
		reasons: {
			esim_purchase: "eSIM puchase",
			esim_topup: "eSIM balance top up",
			referral_income: "Referral balance awarded",
			refund: "Refund",
			order_compensation: "Manual operation",
			manual: "Manual operation",
		},

		filters: {
			all: "All transactions",
			purchases: "Purchases",
			bonuses: "Bonuses",
		}
	}
})
