import { ComponentProps, type JSX } from "solid-js"

import { ComponentLike, drop } from "#/lib/mod"

type SwitchProps = {
	Root?: ComponentLike<"div">,
	Input?: ComponentLike<"input">,

	checked?: boolean
	onInput?: JSX.InputEventHandler<HTMLInputElement, InputEvent>
} & ComponentProps<"div">

export function SwitchInput(props: SwitchProps) {
	let {
		Root = p => <div {...p} />,
		Input = p => <input {...p} />,
	} = props

	let other = drop(props, "class", "classList", "checked", "onInput")

	return (
		<Root
			{...other}
			classList={{
				...props.classList,
				[props.class]: !!props.class,
				":c: relative w-10 h-6 p-2px rounded-42px b-(1px solid gray-200) ptr": true,
				":c: transition-(property-background-color duration-120 ease-[cubic-bezier(0.25,0.46,0.45,0.94)])": true,
				"uno-layer-v1:(b-green-400)": props.checked,
			}}
		>
			<div
				classList={{
					":c: h-full w-4.5 rounded bg-gray-200": true,
					":c: will-change-transform transition-(property-transform duration-120 ease-[cubic-bezier(0.25,0.46,0.45,0.94)])": true,
					":c: uno-layer-v1:(bg-green-400 translate-x-[16px])": props.checked
				}}
			/>
			<Input
				type="checkbox"
				class=":c: absolute appearance-none inset-0 m-0 p-0 b-none ptr"
				onInput={(e) => {
					typeof props.onInput === "function" && props.onInput(e)
				}}
			/>
		</Root>
	)
}
