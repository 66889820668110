import { For } from "solid-js"

import { MobilePage, MainHeader } from "#/common/mod"
import { ROUTES, langs, signal, useAuth, useRouter } from "#/lib/mod"

import ConnectorImage from "./images/connector.svg"
import ToolImage from "./images/tool.svg"
import PiggyBankCoinImage from "./images/piggy-bank-coin.svg"
import UnicoinGrayCoinImage from "./images/uniocoin-gray.svg"


function CardsSection() {
	let router = useRouter(), auth = useAuth()

	let t = langs({
		ru: {
			cards: {
				0: "Мое устройство поддерживает eSIM?",
				1: "Как установить eSIM?",
				2: "Как проверить и пополнить баланс?",
				3: "Как пользоваться eSIM бесплатно?",
			}
		},
		en: {
			cards: {
				0: "Does my device support eSIM?",
				1: "How to install eSIM?",
				2: "How to check and top up balance?",
				3: "How to use eSIM for free?",
			}
		},
	})

	return <div
		classList={{
			":c: grid grid-cols-[repeat(auto-fit,minmax(150px,1fr))] gap-2.5 relative m-inline-4 m-t-5": true,
			":c: [&>div]:(font-300 rounded-3 p-4 h-34 select-none relative bg-([position:100%_100%] no-repeat [size:4.4rem]))": true,
		}}
		children={[
			[ConnectorImage, ":c: bg-gray-800 c-white",
				router.stack.bind(null, ROUTES.compatibility.template)],
			[ToolImage, ":c: bg-#FF5F7C c-white",
				router.stack.bind(null, ROUTES.guide.template)],
			[PiggyBankCoinImage, ":c: bg-green-500",
				router.stack.bind(null, ROUTES.balance_stacked.template)],
			[UnicoinGrayCoinImage, ":c: b-(1px solid gray-200)",
				() => auth.is_authenticated ? router.stack(ROUTES.bonus_stacked.template) : router.stack(ROUTES.bonus_anon.template)
			],
		].map(([img_url, cl, onClick], i, _arr, text = t().cards[i]) =>
			<div
				class={cl}
				style={{ "background-image": `url(${img_url})` }}
				ref={ripple}
				innerText={text}
				onClick={onClick}
			/>
		)}
	/>
}


import { FaqCategory, faqs } from "./faq/faq"
export default function HelpPage() {
	let help_categories = Object.keys(t().filters)
	let selected = signal<FaqCategory>("top")

	return <MobilePage>
		<MainHeader />

		<CardsSection />
		<div
			class=":c: flex gap-2 [&>*]:shrink-0 overflow-(x-auto y-none) scrollbar-none p-inline-4 m-b-4 m-t-9"
			
			children={help_categories.map(item =>
				<div
					classList={{
						":c: p-inline-6 p-block-2.5 rounded-10.5 b-(1px solid gray-200) ptr select-none": true,
						":c: uno-layer-v1:(bg-green-500 font-semibold b-transparent)": selected() === item
					}}
					innerText={t().filters[item]}
					onClick={[selected, item]}
				/>
			)}
		/>
		<For
			each={faqs.filter(x => x.categories.includes(selected()))}
			children={Comp => <Comp />}
		/>
	</MobilePage>
}


let t = langs({
	ru: {
		filters: {
			top: "Топ",
			payment: "Покупка и оплата",
			configuration: "Настройка",
			usage: "Использование",
			troubleshooting: "Проблемы",
			about: "О Unisim",
		} satisfies Record<FaqCategory, string>,
	},
	en: {
		filters: {
			top: "Top",
			payment: "Payment",
			configuration: "Configuration",
			usage: "Usage",
			troubleshooting: "Problems",
			about: "About Unisim",
		},
	}
})
