export function UnisimLogoIcon(props) {
	return <svg viewBox="0 0 140 142" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M0 85.3773V9.35506C0 6.79473 0.873786 4.59547 2.62136 2.75728C4.43366 0.919094 6.66667 0 9.32039 0H27.4757C29.9353 0 32.0712 0.919094 33.8835 2.75728C35.6958 4.59547 36.6019 6.79473 36.6019 9.35506V85.5742C36.6019 95.2247 38.6731 102.413 42.8155 107.14C47.0227 111.867 53.4951 114.23 62.233 114.23H77.767C86.5049 114.23 92.945 111.867 97.0874 107.14C101.294 102.413 103.398 95.2247 103.398 85.5742V9.35506C103.398 6.79473 104.272 4.59547 106.019 2.75728C107.832 0.919094 110 0 112.524 0H130.583C133.236 0 135.469 0.919094 137.282 2.75728C139.094 4.59547 140 6.79473 140 9.35506V85.3773C140 103.365 134.79 117.316 124.369 127.229C113.948 137.076 98.7379 142 78.7379 142H61.2621C41.1974 142 25.9547 137.076 15.534 127.229C5.17799 117.316 0 103.365 0 85.3773Z" fill="black" />
	</svg>
}

export function UnisimLogoIconWithCircle(props) {
	return (<svg viewBox="0 0 299 299" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<rect width="299" height="299" rx="149.5" fill="#D6FE1E" />
		<path
			d="M75.918 173.98V93.9429C75.918 91.2474 76.851 88.9319 78.7172 86.9967C80.6525 85.0614 83.037 84.0938 85.8708 84.0938H105.258C107.885 84.0938 110.165 85.0614 112.101 86.9967C114.036 88.9319 115.004 91.2474 115.004 93.9429V174.188C115.004 184.348 117.215 191.916 121.639 196.893C126.131 201.869 133.043 204.357 142.374 204.357H158.962C168.293 204.357 175.17 201.869 179.593 196.893C184.086 191.916 186.332 184.348 186.332 174.188V93.9429C186.332 91.2474 187.265 88.9319 189.132 86.9967C191.067 85.0614 193.382 84.0938 196.078 84.0938H215.361C218.195 84.0938 220.58 85.0614 222.515 86.9967C224.45 88.9319 225.418 91.2474 225.418 93.9429V173.98C225.418 192.918 219.854 207.606 208.726 218.042C197.598 228.41 181.356 233.594 159.999 233.594H141.337C119.911 233.594 103.634 228.41 92.506 218.042C81.4473 207.606 75.918 192.918 75.918 173.98Z"
			fill="black" />
	</svg>
	)
}


export function UnisimTextLogo(props) {
	return <svg viewBox="0 0 103 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path d="M0 10.8225V1.18585C0 0.861304 0.111776 0.582524 0.335327 0.349515C0.567157 0.116505 0.852806 0 1.19227 0H3.51472C3.82935 0 4.10258 0.116505 4.33441 0.349515C4.56624 0.582524 4.68215 0.861304 4.68215 1.18585V10.8474C4.68215 12.0707 4.9471 12.982 5.477 13.5811C6.01518 14.1803 6.84315 14.4799 7.9609 14.4799H9.94802C11.0658 14.4799 11.8896 14.1803 12.4195 13.5811C12.9577 12.982 13.2268 12.0707 13.2268 10.8474V1.18585C13.2268 0.861304 13.3385 0.582524 13.5621 0.349515C13.7939 0.116505 14.0713 0 14.3942 0H16.7042C17.0437 0 17.3293 0.116505 17.5612 0.349515C17.793 0.582524 17.9089 0.861304 17.9089 1.18585V10.8225C17.9089 13.1026 17.2424 14.871 15.9094 16.1276C14.5764 17.3759 12.6306 18 10.0722 18H7.83671C5.27001 18 3.32015 17.3759 1.98712 16.1276C0.662374 14.871 0 13.1026 0 10.8225Z" fill="black" />
		<path d="M21.2125 16.7268V1.18585C21.2125 0.861304 21.3243 0.582524 21.5478 0.349515C21.7714 0.116505 22.0446 0 22.3675 0H26.0189C26.3086 0 26.615 0.116505 26.9379 0.349515C27.2608 0.582524 27.5258 0.861304 27.7328 1.18585L35.2341 12.7822V1.16089C35.2341 0.836338 35.3459 0.56172 35.5695 0.337032C35.8013 0.112344 36.0787 0 36.4016 0H38.5377C38.8606 0 39.1339 0.112344 39.3574 0.337032C39.5892 0.56172 39.7052 0.84466 39.7052 1.18585V16.7268C39.7052 17.0513 39.5892 17.3301 39.3574 17.5631C39.1339 17.7878 38.8606 17.9001 38.5377 17.9001H34.4144C34.0915 17.9001 33.7604 17.7878 33.4209 17.5631C33.0814 17.3301 32.8123 17.0513 32.6136 16.7268L25.7456 6.01664V16.7268C25.7456 17.0513 25.6297 17.3301 25.3979 17.5631C25.1743 17.7878 24.9011 17.9001 24.5782 17.9001H22.3924C22.0695 17.9001 21.7921 17.7878 21.5603 17.5631C21.3284 17.3301 21.2125 17.0513 21.2125 16.7268Z" fill="black" />
		<path d="M43.2696 16.7517V1.18585C43.2696 0.861304 43.3855 0.582524 43.6173 0.349515C43.8491 0.116505 44.1265 0 44.4494 0H46.7594C47.0989 0 47.3804 0.116505 47.604 0.349515C47.8358 0.582524 47.9517 0.861304 47.9517 1.18585V16.7268C47.9517 17.0513 47.8316 17.3301 47.5915 17.5631C47.3597 17.7878 47.0823 17.9001 46.7594 17.9001H44.4494C44.1265 17.9001 43.8491 17.792 43.6173 17.5756C43.3855 17.3509 43.2696 17.0763 43.2696 16.7517Z" fill="black" />
		<path d="M71.1008 16.6733V1.1803C71.1008 0.857271 71.217 0.579797 71.4493 0.347878C71.6816 0.115959 71.9595 0 72.283 0H74.5977C74.9379 0 75.22 0.115959 75.444 0.347878C75.6763 0.579797 75.7924 0.857271 75.7924 1.1803V16.6485C75.7924 16.9715 75.6721 17.249 75.4315 17.4809C75.1992 17.7045 74.9213 17.8163 74.5977 17.8163H72.283C71.9595 17.8163 71.6816 17.7087 71.4493 17.4933C71.217 17.2697 71.1008 16.9963 71.1008 16.6733Z" fill="black" />
		<path d="M79.364 16.6236V1.20515C79.364 0.865554 79.476 0.579797 79.7 0.347878C79.924 0.115959 80.1978 0 80.5213 0H85.5863C85.9098 0 86.2168 0.115959 86.5072 0.347878C86.7976 0.579797 86.9925 0.857271 87.0921 1.1803L90.7632 11.7906L94.4344 1.1803C94.5339 0.857271 94.7289 0.579797 95.0193 0.347878C95.3096 0.115959 95.6166 0 95.9401 0H101.005C101.329 0 101.598 0.115959 101.814 0.347878C102.038 0.579797 102.15 0.865554 102.15 1.20515V16.6236C102.15 16.9632 102.034 17.249 101.802 17.4809C101.578 17.7045 101.304 17.8163 100.98 17.8163H98.7402C98.4166 17.8163 98.1387 17.7045 97.9064 17.4809C97.6824 17.249 97.5704 16.9632 97.5704 16.6236V4.48514L93.1775 16.6981C93.0613 17.0046 92.8581 17.2697 92.5677 17.4933C92.2773 17.7087 91.9703 17.8163 91.6468 17.8163H89.8672C89.5436 17.8163 89.2367 17.7087 88.9463 17.4933C88.6642 17.2697 88.461 17.0046 88.3365 16.6981L83.956 4.48514V16.6236C83.956 16.9632 83.8399 17.249 83.6076 17.4809C83.3836 17.7045 83.1098 17.8163 82.7863 17.8163H80.5462C80.2227 17.8163 79.9447 17.7045 79.7124 17.4809C79.4801 17.249 79.364 16.9632 79.364 16.6236Z" fill="black" />
		<path fill-rule="evenodd" clip-rule="evenodd" d="M65.7806 16.4758C64.3861 17.4318 62.397 18 59.9685 18C55.8421 18 52.3419 16.3971 51.2092 13.6984C50.9963 13.1913 51.1262 12.6913 51.4416 12.3334C51.7499 11.9837 52.232 11.7684 52.7518 11.7684H53.8737C54.7259 11.7684 55.3678 12.3579 55.7685 12.8693C56.4613 13.7536 57.9649 14.3574 59.9179 14.3574C61.0658 14.3574 62.0108 14.1314 62.6527 13.7816C63.2954 13.4313 63.5833 12.9925 63.5833 12.554C63.5833 12.1847 63.4445 11.9295 63.1946 11.7238C62.9253 11.5022 62.5106 11.3264 61.948 11.1847C61.3905 11.0443 60.73 10.9472 59.9993 10.8576C59.7458 10.8265 59.4824 10.7962 59.2132 10.7652C58.7185 10.7083 58.2043 10.6491 57.696 10.5788C56.1273 10.3618 54.4793 10.0242 53.2204 9.22399C51.9251 8.40058 51.075 7.10888 51.075 5.0929C51.075 3.47369 52.0022 2.18404 53.4632 1.31778C54.9142 0.457423 56.9069 0 59.1346 0C63.068 0 66.1743 1.4679 67.2747 3.90233C67.5042 4.40994 67.3812 4.91688 67.0665 5.28054C66.7594 5.63536 66.2735 5.85504 65.7485 5.85504H64.6752C63.8302 5.85504 63.1738 5.26697 62.7474 4.80874C62.0871 4.09899 60.7472 3.54844 59.1346 3.54844C57.9421 3.54844 56.9941 3.75911 56.3611 4.0826C55.7268 4.40676 55.4692 4.80476 55.4692 5.18705C55.4692 5.50901 55.5972 5.7339 55.8449 5.92208C56.1143 6.12667 56.531 6.28955 57.0974 6.42222C57.6577 6.55348 58.3202 6.64574 59.0525 6.73443C59.2472 6.75801 59.4469 6.78135 59.6503 6.80511C60.2033 6.86973 60.7829 6.93746 61.3597 7.02158C62.9314 7.25077 64.5794 7.61297 65.8378 8.44138C67.1302 9.29211 67.9775 10.6115 67.9775 12.6482C67.9775 14.163 67.1811 15.5156 65.7806 16.4758Z" fill="black" />
	</svg>

}
