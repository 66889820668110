import { type ComponentProps } from "solid-js"

import { drop } from "#/lib/mod"

import { createPageContext, PageContext } from "./page.conext"
import { MOBILE_NAVBAR_HEIGHT, useIsMobileNavbarAvailable } from "../mod"

type MobilePageProps = {}
export function MobilePage(props: Partial<ComponentProps<"div">> & MobilePageProps) {
	let isMobileNavbarAvailable = useIsMobileNavbarAvailable()

	let other = drop(props, "ref", "children", "class", "classList", "style")

	let page_context = createPageContext()

	return (
		<div
			{...other}
			ref={r => {
				page_context.scrolling_root = page_context.modal_root = r
				props.ref?.(r)
			}}
			classList={{
				":c: absolute w-full max-w-[var(--page-width)] h-full p-top-safe overscroll-contain overflow-(x-hidden y-auto)": true,
				":c: flex flex-col w-full h-100vh max-h-100vh [@supports(height:100dvh)]:(h-100dvh max-h-100dvh) [&>*]:shrink-0": true,
				":c: bg-light scrollbar-none": true,
				[props.class]: !!props.class,
				...props.classList
			}}
			style={{
				"container": "page / inline-size",
				"padding-bottom": isMobileNavbarAvailable() ? `calc(${MOBILE_NAVBAR_HEIGHT} + 0.5rem)` : null,
				"-webkit-overflow-scrolling": "touch",
				...props.style,
			}}
		>
			<PageContext.Provider value={page_context} children={props.children} />
		</div>
	)
}
