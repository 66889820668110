import { isApiError } from "../mod"

export module api {
	const REQUEST_TIMEOUT_MS = 9000

	export type ApiError = {
		$api_error: "remote"
		detail: string
	} | {
		$api_error: "network"
		err: Error
	} | {
		$api_error: "parse"
		inner: Error
		response: Response
	} | {
		$api_error: "bad_response"
		response: Response
	} | {
		$api_error: "unknown"
		response: Response
	}

	export let headers = {}

	type FetchParams = Omit<RequestInit, "body"> & {
		qs?: Record<string, string | number | boolean>
		body?: any

		hooks?: {
			preflight?(params: FetchParams): void
		}
	}

	type CursorOptions = {
		limit?: number
		offset?: number
	}

	async function req<T>(url_str: string, opts: FetchParams = {}): Promise<T | ApiError> {
		let url = new URL("/api/" + url_str, location.origin)

		if (opts.qs) {
			// @ts-ignore
			let params = new URLSearchParams(Object.entries(opts.qs).filter(([k, v]) => v !== undefined))
			url.search = params.toString()
		}

		if (opts.headers)
			Object.assign(opts.headers, headers)
		else
			opts.headers = structuredClone(headers)

		opts.signal ??= AbortSignal.timeout(REQUEST_TIMEOUT_MS)

		opts.hooks?.preflight?.(opts)

		let response = await fetch(url, opts).catch(Function.NOOP_ERR) as Response | Error


		if (response instanceof Error) {
			return { $api_error: "network", err: response }
		}

		if (response.headers.get("content-length") === "0") {
			return response.ok ? null : { $api_error: "unknown", response }
		}
		let value = await response.json().catch(Function.NOOP_ERR)

		if (value instanceof Error) {
			gtrace.warn(value)
			return { $api_error: "parse", response, inner: value }
		}

		if (!response.ok) {
			if ("detail" in value)
				return { $api_error: "remote", detail: value.detail }

			return { $api_error: "unknown", response }
		}

		return value as T
	}

	export type GetRatesParams = {
		category?: string
		lang: string
		search?: string
		cursor?: CursorOptions
	}
	export type GetRatesResonse = {
		count: number
		next?: string
		previous?: string
		results: {
			name: string
			slug: string
			order: number
			flag: string
			lowest_price: string
			rates: { name: string, slug: string, price: string }[]
		}[]
	}
	export let getRates = ({ category, lang, search, cursor = {} }: GetRatesParams, opts?: FetchParams) =>
		req<GetRatesResonse>(`get-rates/`, { qs: { ...cursor, category, search, lang } })


	export type GetEsimPlansResponse = {
		bonus_balance_percent: string
		esim_price: string
		title: string
		upper_price_limit: string
	}[]
	export let getEsimPlans = (opts?: FetchParams) => req<GetEsimPlansResponse>(`esim-plans/`)

	export type GetBanksResponse = {
		currency_slug: string
		currency_symbol: string
		rate: number
		bank_id: string
	}[]
	export let getBanks = async (opts?: FetchParams) => {
		let response = await req<Record<string, {
			currencySymbol: string
			rate: number
			bankId: string
		}>>(`list-banks/`)

		if (isApiError(response))
			return response

		let mapped: GetBanksResponse = []

		for (let [currency_slug, v] of Object.entries(response)) {
			mapped.push({
				currency_slug,
				currency_symbol: v.currencySymbol,
				bank_id: v.bankId,
				rate: v.rate
			})
		}

		return mapped
	}

	type CheckCouponParams = {
		code: string
		email: string
	}
	export type CheckCouponResponse = {}
	export let checkCoupon = ({ code, email }: CheckCouponParams, opts?: FetchParams) =>
		req<CheckCouponResponse>(`check-coupon/`, opts)


	type TProduct = "esim_from_requested_price" | "esim_balance"
	type CreateOrderParams<P extends TProduct> = (
		P extends "esim_from_requested_price"
		? {
			product: P
			amount: number
			customer_email: string
			promocode?: string
		}
		: P extends "esim_balance"
		? {
			product: P
			imsi: string
		} & ({ amount: number } | { balance_amount_to_use: number })
		: never
	)
		& {
			bank_id: "stripe" | "tinkoff"
			user_lang: string
			success_url?: string
			cancel_url?: string
		}


	export type CreateOrderResponse = {
		url: string
	}
	export let createOrder = <P extends TProduct>(p: CreateOrderParams<P>, opts?: FetchParams) => {
		opts ??= {}
		opts.method = "POST"

		opts.qs ??= {}

		opts.headers ??= new Headers({ "accept": "application/json" })

		let params
		if (p.product === "esim_from_requested_price") {
			params = {
				...p,
				customer_email: p.customer_email,
				product: p.product,
				product_data: JSON.stringify({ requested_price: p.amount }),
				bank_id: p.bank_id,
				user_lang: p.user_lang
			}
			if (p.promocode) {
				params.promocode = p.promocode
			}
			else delete params.promocode
		}
		else if (p.product === "esim_balance") {
			if ("amount" in p) {
				params = {
					...p,
					imsi: p.imsi,
					amount: String(p.amount),
					product_data: JSON.stringify({ imsi: p.imsi, balance_amount: p.amount }),
					product: p.product,
					bank_id: p.bank_id,
					user_lang: p.user_lang,
				}
			}
			else if ("balance_amount_to_use" in p) {
				params = {
					...p,
					product: p.product,
					product_data: JSON.stringify({ imsi: p.imsi, balance_amount: p.balance_amount_to_use }),
					balance_amount_to_use: String(p.balance_amount_to_use),
					bank_id: p.bank_id,

					user_lang: p.user_lang,
				}
			}
		}

		if (!params)
			throw null

		params.referer = document.URL


		opts.body = new URLSearchParams(params)
		opts.qs.json = String(true)

		return req<CreateOrderResponse>(`create-order/`, opts)
	}

	type RequestEmailOtpTokenParams = {
		email: string
	}
	type RequestEmailOtpTokenResponse = {
		token: string
	}
	export let requestEmailOtpToken = ({ email }: RequestEmailOtpTokenParams, opts?: FetchParams) => {
		opts ??= {}
		opts.method ??= "GET"

		opts.qs ??= {}
		opts.qs.pin ??= String(true)

		return req<RequestEmailOtpTokenResponse>(`passwordless_auth/request_token/${email}/`, opts)
	}


	type RequestJwtParams = {
		email: string
		pin: string
	}
	type RequestJwtResponse = {
		token: string
	}
	export let requestJwt = ({ email, pin }: RequestJwtParams, opts?: FetchParams) => {
		opts ??= {}
		opts.method ??= "GET"

		return req<RequestJwtResponse>(`passwordless_auth/obtain_jwt_from_pin/${email}/${pin}/`, opts)
	}

	type GetAccountInfoParams = {}
	export type GetAccountInfoResponse = {
		id: string
		email: string
		balance: number
		referral_code: string
	}
	export let getAccountInfo = ({ }: GetAccountInfoParams = {}, opts?: FetchParams) =>
		req<GetAccountInfoResponse>(`account_info/`, opts)


	type GetEsimsParams = {
		cursor?: CursorOptions
	}
	export type GetEsimsResponse = {
		count: number
		next?: string
		previous?: string
		results: {
			id: string,
			favourite: any | null
			imsi: string
			alias: string
			last_balance: string
			last_balance_at: string | null
		}[]
	}
	export let getEsims = ({ cursor }: GetEsimsParams, opts?: FetchParams) =>
		req<GetEsimsResponse>(`esims/`, opts)


	type SyncEsimParams = {
		esim_id: string
	}
	export type SyncEsimResponse = {
		id: string
		favourite: string
		imsi: string
		alias: string
		last_balance: string
		last_balance_at: string
	}
	export let syncEsim = ({ esim_id }: SyncEsimParams, opts?: FetchParams) =>
		req<SyncEsimResponse>(`esims/${esim_id}/sync_balance/`, { method: "POST", ...opts })


	type RenameEsimParams = {
		esim_id: string
		alias: string
	}
	export type RenameEsimResponse = SyncEsimResponse
	export let renameEsim = ({ esim_id, alias }: RenameEsimParams, opts?: FetchParams) =>
		req<RenameEsimResponse>(`esims/${esim_id}/rename/`, {
			method: "PATCH",
			headers: { "content-type": "application/json" },
			body: JSON.stringify({ alias }),
			...opts
		})


	export type GetOperationsParams = {
		cursor?: CursorOptions
		scope: "all" | "purchases" | "bonuses"
	}
	export type GetOperationsResponse = {
		id: string
		created_at: string
		type: "DR"
		reason: "esim_purchase" | "esim_topup" | "referral_income" | "refund" | "order_compensation" | "manual"
		amount: string

		esim: {
			id: string
			imsi: string
			alias: string
		}
	}[]
	export let getOperations = ({ scope }: GetOperationsParams, opts?: FetchParams) => {
		opts ??= {}
		opts.qs ??= {}
		opts.qs.scope ??= scope

		return req<GetOperationsResponse>(`operations/`, opts)
	}


	type CheckPromocodeParams = {
		code: string
		customer_email: string
	}
	export type CheckPromocodeResponse = {
		coupon: {
			id: string,
			action_type: "bonus_balance" | "discount"
			deduction_type: "percentage" | "fixed_amount"
			amount: string
		}
		reason: ""
	} | {
		coupon: null
		reason: "does_not_exist"
	}
	export let checkPromocode = (params: CheckPromocodeParams, opts?: FetchParams) => {
		opts ??= {}
		opts.qs ??= {}
		opts.qs.json = String(true)

		return req<CheckPromocodeResponse>(`check-coupon/`, {
			method: "POST",
			headers: { "content-type": "application/json" },
			body: JSON.stringify(params),
			...opts
		})
	}


	type CheckCompitabilityParams = {
		search: string
		lang?: string
	}
	export type SearchDeviceResponse = {
		count: number
		next: string,
		previous?: string,

		results: {
			name: string
			brand: string
			year: number
			supports_esim: boolean
			info: string
		}[]
	}
	export let searchDevices = (params: CheckCompitabilityParams, opts?: FetchParams) => {
		return req<SearchDeviceResponse>(`devices/`, {
			method: "GET",
			headers: { "content-type": "application/json" },
			qs: params,
			...opts
		})
	}

}
