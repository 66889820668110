import { getOwner } from "solid-js"

type LoggerOptions = {

}
export function tracing(name: string, { }: LoggerOptions = {}) {
	let OWNER = getOwner()

	if (OWNER) OWNER.id = name

	let stack = function getStack() {
		let stack: string[] = [], owner = OWNER

		while (owner != null) {
			if (owner.id) stack.unshift(owner.id)

			owner = owner.owner
		}
		if (stack.isEmpty()) stack.unshift(name)

		return stack.join(" > ")
	}()

	let common = (prefix: string, clr: string) => [
		`%c${prefix} %c%s\n%c%O`,
		`font-size:10px;color:${clr}`,
		`font-size:10px;color:gray`,
		stack,
		`color:inherit`,
	]

	return {
		debug(...args: Parameters<typeof console.log>) {
			console.log(...common("DEBUG", "#006eff"), ...args)
		},
		info(...args: Parameters<typeof console.log>) {
			console.log(...common("INFO", "#16b108"), ...args)
		},
		warn(...args: Parameters<typeof console.log>) {
			console.log(...common("WARN", "#ffd000"), ...args)
		},
		error(...args: Parameters<typeof console.log>) {
			console.log(...common("ERROR", "#CC0000"), ...args)
		},
		custom(...args: Parameters<typeof console.log>) {
			console.log(...args)
		}
	}
}

