import { For, Match, Show, Switch, batch, createEffect, on } from "solid-js"
import { createMutable } from "solid-js/store"

import { MobilePage, MainHeader, dictionary } from "#/common/mod"
import { BasicInput, DropdownSection, FilterButton, HorizontalLine, Spinner, useDropdownSelectionContext } from "#/components/mod"
import { api, CURRENCY_SYMBOLS, errorHandled, formatFloatLike, lang, langs, ROUTES, throttle, useAuth, useCache, useRouter } from "#/lib/mod"

import { Esims } from "./esims"
import { Reviews } from "./reviews"


import TopIcon from "./assets/categories/top.png"
import AfricaIcon from "./assets/categories/africa.png"
import AmericaIcon from "./assets/categories/america.png"
import AsiaIcon from "./assets/categories/asia.png"
import EuropeIcon from "./assets/categories/europe.png"
import OceaniaIcon from "./assets/categories/oceania.png"

let categories_filter = [
	["top", TopIcon],
	["america", AmericaIcon],
	["europe", EuropeIcon],
	["africa", AfricaIcon],
	["oceania", OceaniaIcon],
	["asia", AsiaIcon],
] as const
type Category = typeof categories_filter[number][0]


const COUNTRIES_CHUNK_COUNT = 5

export default function MainPage() {
	let auth = useAuth()
	let cache = useCache()
	let router = useRouter()

	let state = createMutable({
		loading: false,

		filter_mode: "category" as "category" | "search",
		last_cursor: null
	})

	let search = createMutable({
		value: null as string,
		names: [] as string[],

		offset: 0,
	})

	let categories = createMutable({
		key: "top" as Category,

		"top": {
			offset: 0,
			names: [] as string[],
		},
		"america": {
			offset: 0,
			names: [] as string[],
		},
		"europe": {
			offset: 0,
			names: [] as string[],
		},
		"africa": {
			offset: 0,
			names: [] as string[],
		},
		"oceania": {
			offset: 0,
			names: [] as string[],
		},
		"asia": {
			offset: 0,
			names: [] as string[],
		},

		get current() {
			return this[this.key]
		}
	})

	queueMicrotask(fetchRates)

	createEffect(on(lang, () => {
		cache.entities.countries.clear()
		fetchRates()
	}, { defer: true }))

	async function fetchRates() {
		state.loading = true

		let { key } = categories

		let opts = {
			lang: lang(),
			cursor: { limit: COUNTRIES_CHUNK_COUNT },
		} as api.GetRatesParams

		if (state.filter_mode === "search") {
			opts.search = search.value
			opts.cursor.offset = search.offset
		}
		else {
			opts.category = key
			opts.cursor.offset = categories[key].offset
		}

		let response = await api.getRates(opts)
			.finally(() => state.loading = false)

		if (errorHandled(response, "[загрузка тарифов]"))
			return

		let { results: countries, ...cursor } = response

		let target: string[]

		if (state.filter_mode === "search") {
			target = search.names
		}
		else if (state.filter_mode === "category") {
			target = categories[key].names
		}

		batch(() => {
			cache.update("countries", countries)
			state.last_cursor = cursor

			for (let c of countries) {
				if (target.includes(c.name))
					continue

				target.push(c.name)
			}
		})
	}

	let fetchRatesThrottled = throttle(fetchRates, 1000, false)

	return <MobilePage>
		<MainHeader />

		<Switch>
			<Match when={auth.is_auth_pending}>
				<Spinner />
			</Match>
			<Match when={auth.is_authenticated}>
				<Esims />
				<div class=":c: flex gap-2.5 [&>div]:(h-11 bg-white flex-center gap-6px rounded-2 flex-1 ptr relative) m-inline-4 m-t-6">
					<div
						ref={ripple}
						onClick={[router.stack, ROUTES.bonus_stacked.template]}
						children={[<BonusesIcon />, t().bonuses]}
					/>
					<div
						ref={ripple}
						onClick={[router.stack, ROUTES.history.template]}
						children={[<HistoryIcon />, t().history]}
					/>
				</div>
			</Match>
			<Match when={!auth.is_authenticated}>
				<AnonymousView />
			</Match>
		</Switch>

		<BasicInput.Root class=":c: m-inline-4 m-t-12">
			<BasicInput.IconW
				wrapperc=":c: uno-layer-v2:(size-4 left-4!)"
				Icon={p =>
					<svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...p} classList={{ ...p.classList, ":c: uno-layer-v1:size-4": true }}>
						<path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 7.33333C3.33333 5.12733 5.12733 3.33333 7.33333 3.33333C9.53933 3.33333 11.3333 5.12733 11.3333 7.33333C11.3333 9.53933 9.53933 11.3333 7.33333 11.3333C5.12733 11.3333 3.33333 9.53933 3.33333 7.33333ZM13.8047 12.862L11.5413 10.598C12.2433 9.69533 12.6667 8.564 12.6667 7.33333C12.6667 4.39267 10.274 2 7.33333 2C4.39267 2 2 4.39267 2 7.33333C2 10.274 4.39267 12.6667 7.33333 12.6667C8.564 12.6667 9.69533 12.2433 10.598 11.5413L12.862 13.8047C12.992 13.9347 13.1627 14 13.3333 14C13.504 14 13.6747 13.9347 13.8047 13.8047C14.0653 13.544 14.0653 13.1227 13.8047 12.862Z" fill="#808080" />
						<mask id="mask0_2823_13637" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="12" height="12">
							<path fill-rule="evenodd" clip-rule="evenodd" d="M3.33333 7.33333C3.33333 5.12733 5.12733 3.33333 7.33333 3.33333C9.53933 3.33333 11.3333 5.12733 11.3333 7.33333C11.3333 9.53933 9.53933 11.3333 7.33333 11.3333C5.12733 11.3333 3.33333 9.53933 3.33333 7.33333ZM13.8047 12.862L11.5413 10.598C12.2433 9.69533 12.6667 8.564 12.6667 7.33333C12.6667 4.39267 10.274 2 7.33333 2C4.39267 2 2 4.39267 2 7.33333C2 10.274 4.39267 12.6667 7.33333 12.6667C8.564 12.6667 9.69533 12.2433 10.598 11.5413L12.862 13.8047C12.992 13.9347 13.1627 14 13.3333 14C13.504 14 13.6747 13.9347 13.8047 13.8047C14.0653 13.544 14.0653 13.1227 13.8047 12.862Z" fill="white" />
						</mask>
						<g mask="url(#mask0_2823_13637)">
							<rect width="16" height="16" fill="#808080" />
						</g>
					</svg>
				}
			/>
			<BasicInput class=":c: uno-layer-v2:(p-l-11 text-3.5 p-block-0 leading-18px h-9)"
				placeholder={t().search_placeholder}
				onInput={({ currentTarget: { value } }) => {

					batch(() => {
						if (!value || !value.length) {
							state.filter_mode = "category"
						}
						else {
							state.filter_mode = "search"
							search.offset = 0
							search.names.clear()
						}
						search.value = value
					})

					fetchRatesThrottled()
				}}
				onKeyDown={({ currentTarget: { value }, key }) => {
					if (key === "Enter") {
						batch(() => {
							state.filter_mode = "search"
							search.offset = 0
							search.names.clear()
							search.value = value
						})
						fetchRates()
					}
				}}
			/>
			<Show when={state.loading}>
				<BasicInput.IconW right wrapperc=":c: size-5 fill-none" Icon={Spinner} x={0} />
			</Show>
		</BasicInput.Root>

		<div  class=":c: flex gap-2 [&>*]:shrink-0 overflow-(x-auto y-none) scrollbar-none p-inline-4 m-t-6"
			children={categories_filter.map(([category, icon]) =>
				<FilterButton
					Root={p =>
						<div {...p}
							classList={{
								...p.classList,
								":c: uno-layer-v2:(flex items-center gap-2 p-inline-4)": true,
							}}
							onClick={() => {
								state.filter_mode = "category"
								categories.key = category
								fetchRates()
							}}
						>
							<img class="size-5" src={icon} /> {t().filters[category]}
						</div>
					}
					active={categories.key === category}
				/>
			)}
		/>

		<div class=":c: flex flex-col gap-3 m-t-6">
			<For each={cache.resolve("countries", state.filter_mode === "category" ? categories.current.names : search.names)}
				children={rate =>
					<DropdownSection
						title_height="2.9rem"
						Container={p => {
							let ctx = useDropdownSelectionContext()
							return <div
								classList={{
									":c: bg-#d6fe1e": ctx.opened(),
									":c: bg-white": !ctx.opened(),
									":c: flex flex-col m-inline-4 p-inline-4 p-block-3 rounded-2": true,
								}}
							>
								<div {...p} />
							</div>
						}}
						Title={(p) => {
							let ctx = useDropdownSelectionContext()

							return <div {...p}>
								<div>
									<div class=":c: flex items-center gap-2">
										<span innerText={rate.name} class=":c: font-600" />
										<img class=":c: size-5 object-contain" src={`https://unisim.fra1.cdn.digitaloceanspaces.com/flags/${rate.slug}.svg`} />
									</div>
									<Show when={!ctx.opened()}>
										<Switch>
											<Match when={lang() === "ru"}>
												<div class=":c: font-300 text-sm m-t-1.5 c-#4D4D4D">
													{t().price_from} {formatFloatLike(rate.lowest_price, lang())} {CURRENCY_SYMBOLS.USD}/{t().gb}
												</div>
											</Match>
											<Match when={lang() === "en"}>
												<div class=":c: font-300 text-sm m-t-1.5 c-#4D4D4D">
													{t().price_from} {CURRENCY_SYMBOLS.USD}{formatFloatLike(rate.lowest_price, lang())}/{t().gb}
												</div>
											</Match>
										</Switch>

									</Show>
								</div>
								<div
									classList={{
										":c: flex-center rounded-full m-l-auto size-5 [&>i]:m-0!": true,
										":c: transform-origin-c transition-(ease duration-200 property-transform)": true,
										":c: bg-#bae300 [transform:rotate(-180deg)]": ctx.opened(),
										":c: bg-#f1f3f6": !ctx.opened(),
									}}
								>
									<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
										<path d="M7.01562 7.94063L2.29249 4.08301L1.16602 5.4068L6.44226 9.71604C6.76412 9.97976 7.23106 9.98321 7.55643 9.72552L12.8327 5.56452L11.7308 4.22176L7.01562 7.94063Z" fill="#4D4D4D" />
									</svg>

								</div>
							</div>
						}}
					>
						<div class=":c: flex flex-col gap-2 m-t-2">
							<For each={rate.rates}>
								{subrate =>
									<div class=":c: flex justify-between text-3.5 font-300 gap-1">
										<span class=":c:">{subrate.name}</span>
										<Switch>
											<Match when={lang() === "ru"}>
												<span class=":c: text-4 font-500 min-w-max">{formatFloatLike(subrate.price, lang())} {CURRENCY_SYMBOLS.USD}/{t().gb}</span>
											</Match>
											<Match when={lang() === "en"}>
												<span class=":c: text-4 font-500 min-w-max">{CURRENCY_SYMBOLS.USD}{formatFloatLike(subrate.price, lang())}/{t().gb}</span>
											</Match>
										</Switch>
									</div>
								}
							</For>
						</div>
					</DropdownSection>
				}
			/>
		</div>

		<Show when={state.last_cursor?.next != null}>
			<div
				class=":c: self-stretch m-t-3 p-block-2 bg-[#DBE0E7] m-inline-4 flex-center rounded-6px ptr relative"
				onClick={() => {
					if (state.loading) return

					if (state.filter_mode === "search") {
						search.offset += COUNTRIES_CHUNK_COUNT
					}
					else if (state.filter_mode === "category") {
						categories.current.offset += COUNTRIES_CHUNK_COUNT
					}

					fetchRates()
				}}
				ref={ripple}
			>
				<Show when={state.loading} fallback={<span innerText={t().more} />} >
					<div class=":c: flex items-center gap-2">
						<span class=":c: font-500 text-sm c-gray-400">{dictionary().common.loading}</span>
					</div>
				</Show>
			</div>
		</Show>

		<Show when={!auth.is_authenticated}>
			<Reviews />
		</Show>
	</MobilePage>
}

let t = langs({
	ru: {
		bonuses: "Бонусы",
		history: "История",
		search_placeholder: "Интернет в 170+ странах",

		filters: {
			top: "Топ",
			america: "Америка",
			asia: "Азия",
			africa: "Африка",
			oceania: "Океания",
			europe: "Европа",
		} satisfies Record<typeof categories_filter[number][0], string>,

		price_from: "от",
		gb: "гб",

		more: "Ещё",
	},
	en: {
		bonuses: "Bonuses",
		history: "History",
		search_placeholder: "Internet in 170+ countries",

		filters: {
			top: "Top",
			america: "America",
			asia: "Asia",
			africa: "Africa",
			oceania: "Oceania",
			europe: "Europe",
		},

		price_from: "from",
		gb: "gb",

		more: "More",
	}
})

function AnonymousView() {
	let router = useRouter()

	let t = langs({
		ru: {
			buy: "Купить eSIM",
			or: "или",
			login: "Войти",

			cards: {
				infinity_balance: "Вечный баланс и одна виртуальная сим-карта на все страны",
				cheaper: "До 5 раз дешевле роуминга",
				world_cards: "Оплата российскими и зарубежными картами",
				used_traffic: "Плата только за использованный трафик",
				simple_settings: "Простая настройка и мгновенный выпуск — нужен только email",
			},
		},
		en: {
			buy: "Buy eSIM",
			or: "or",
			login: "Login",

			cards: {
				infinity_balance: "Infinity balance and one virtual sim card for all countries",
				cheaper: "Up to 5 times cheaper than roaming",
				world_cards: "Payment with Russian and foreign cards",
				used_traffic: "Payment only for used traffic",
				simple_settings: "Simple setup and instant issuance - just an email is needed",
			},
		}
	})

	let card_images = Object.values(import.meta.glob("./assets/*.png", { eager: true, query: "?url" }))
		// @ts-ignore
		.map(x => x.default)

	return <>
		<div
			classList={{
				":c: w-auto h-18 m-inline-4 bg-green-500 m-t-8": true,
				":c: text-5 font-600 c-[#2C2C2C] flex-center gap-2.5 rounded-2 select-none relative ptr": true,
			}}
			children={[<i class=":c: i-local:sim w-7 c-[#525252]" />, t().buy]}
			onClick={[router.stack, ROUTES.order.template]}
			ref={ripple}
		/>

		<splitter class=":c: flex w-full gap-4 items-center justify-center c-[#808080] p-inline-8 m-t-3">
			<HorizontalLine />
			<span class=":c: min-w-fit font-400" innerText={t().or} />
			<HorizontalLine />
		</splitter>

		<div
			classList={{
				":c: w-auto h-11 m-inline-4 bg-white m-t-3": true,
				":c: text-t font-500 c-[#2C2C2C] flex-center gap-2.5 rounded-2 select-none ptr relative": true,
			}}
			children={[t().login]}
			onClick={[router.stack, ROUTES.login.template]}
			ref={ripple}
		/>
		<div
			
			classList={{
				":c: grid grid-flow-col gap-2.5 overflow-(x-auto y-clip) p-inline-4 scrollbar-none m-t-10 scrollbar-none": true,
				":c: [&>div]:(c-white bg-gray-800 rounded-3 p-4 w-72 h-32.5 relative select-none)": true,
			}}
			children={Object.values(t().cards).map((title, i) =>
				<div
					innerText={title}
					class=":c: bg-(cover no-repeat)"
					style={{ "background-image": `url(${card_images[i]})` }}
					ref={ripple}
				/>
			)}
		/>
	</>
}

let BonusesIcon = () => <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10.1929 13.3532V8.2516V8.1016H10.0429H8.49064H8.34064V8.2516L8.34064 13.3177C7.89505 13.2548 7.36472 13.0985 6.95821 12.6783C6.95821 12.6783 6.95821 12.6783 6.95821 12.6783L6.95599 12.676L6.95521 12.6752L6.95302 12.6729C6.71946 12.4277 6.45732 12.1418 6.33497 11.7052C6.24486 11.3835 6.2444 11.0027 6.24504 10.6087L6.24514 10.515V3V2.85H6.09514H4.41602H4.26602V3V10.515C4.26602 12.1386 4.76672 13.2721 5.64975 14.0513L5.6507 14.0521C6.28042 14.5986 7.15719 14.9889 8.34064 15.1697V17V17.15H8.49064H10.0429H10.1929V17V15.283H11.6391V17V17.15H11.7891H13.3414H13.4914V17V15.1689C14.669 14.9877 15.5429 14.5974 16.172 14.051L16.1725 14.0506C17.0624 13.2709 17.566 12.1378 17.566 10.515V3V2.85H17.416H15.7369H15.5869V3V10.515L15.5871 10.6241L15.5871 10.6243L15.5871 10.6246C15.588 11.0095 15.5878 11.3859 15.5007 11.7018C15.3793 12.1416 15.1151 12.4287 14.8762 12.6758L14.8757 12.6764C14.4703 13.0999 13.9365 13.2557 13.4914 13.3181L13.4914 8.2516V8.1016H13.3414H11.7892H11.6392V8.2516L11.6391 13.3532H10.1929Z" fill="#9CBE00" stroke="#9CBE00" stroke-width="0.3" />
</svg>

let HistoryIcon = () => <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.64244 10.8324C3.68279 8.87436 4.47971 7.00796 5.86607 5.62461C6.56431 4.92637 7.37642 4.37637 8.28091 3.99395C9.21763 3.59863 10.2124 3.39668 11.2372 3.39668C12.2641 3.39668 13.2588 3.59648 14.1934 3.99395C15.0979 4.37637 15.91 4.92637 16.6083 5.62461C16.8231 5.83945 17.0229 6.06504 17.2077 6.29922L16.1335 7.13926C16.1079 7.15905 16.0884 7.18567 16.0772 7.21605C16.0661 7.24642 16.0638 7.27933 16.0705 7.31097C16.0772 7.34262 16.0928 7.37172 16.1153 7.39493C16.1378 7.41814 16.1665 7.43452 16.1979 7.44219L19.5667 8.26504C19.6741 8.29082 19.7794 8.20918 19.7794 8.09961L19.7965 4.62988C19.7965 4.48594 19.6311 4.4043 19.5194 4.49453L18.4924 5.29805C16.8081 3.14316 14.1848 1.76172 11.2393 1.76172C6.20123 1.76172 2.10416 5.80293 2.01177 10.8238C2.0112 10.8468 2.01522 10.8696 2.0236 10.8909C2.03198 10.9123 2.04455 10.9317 2.06057 10.9481C2.07659 10.9646 2.09573 10.9776 2.11687 10.9865C2.138 10.9954 2.16071 11 2.18365 11H3.47271C3.56509 11 3.64029 10.9248 3.64244 10.8324ZM20.3165 11H19.0274C18.935 11 18.8577 11.0752 18.8555 11.1676C18.8152 13.1256 18.0183 14.992 16.6319 16.3754C15.9401 17.0701 15.1199 17.6239 14.217 18.0061C13.2803 18.4014 12.2856 18.6033 11.2608 18.6033C10.236 18.6033 9.23912 18.4035 8.30455 18.0061C7.40166 17.6239 6.58154 17.0701 5.8897 16.3754C5.67486 16.1605 5.47505 15.935 5.29029 15.7008L6.36236 14.8607C6.38795 14.8409 6.40743 14.8143 6.41857 14.784C6.4297 14.7536 6.43204 14.7207 6.42531 14.689C6.41857 14.6574 6.40304 14.6283 6.38051 14.6051C6.35797 14.5819 6.32934 14.5655 6.29791 14.5578L2.92916 13.735C2.82173 13.7092 2.71646 13.7908 2.71646 13.9004L2.69927 17.3744C2.69927 17.5184 2.8647 17.6 2.97642 17.5098L4.00337 16.7062C5.69205 18.8568 8.31529 20.2383 11.2608 20.2383C16.2989 20.2383 20.396 16.1971 20.4883 11.1762C20.4889 11.1532 20.4849 11.1304 20.4765 11.1091C20.4681 11.0877 20.4556 11.0683 20.4395 11.0519C20.4235 11.0354 20.4044 11.0224 20.3832 11.0135C20.3621 11.0046 20.3394 11 20.3165 11Z" fill="#9CBE00"/>
<path d="M11.241 15.2442C10.6844 15.2442 10.1379 15.1647 9.60135 15.0058C9.06481 14.8469 8.61436 14.6182 8.25 14.3198L8.89865 13.3314C9.09084 13.5019 9.31506 13.6473 9.57132 13.7674C9.82758 13.8876 10.0998 13.9806 10.3881 14.0465C10.6764 14.1085 10.9607 14.1395 11.241 14.1395C11.7895 14.1395 12.226 14.0368 12.5503 13.8314C12.8786 13.626 13.0428 13.3275 13.0428 12.936C13.0428 12.6105 12.9227 12.345 12.6824 12.1395C12.4462 11.9341 12.0338 11.7539 11.4452 11.5988L10.5803 11.3953C9.8476 11.1899 9.29705 10.905 8.92868 10.5407C8.56031 10.1764 8.37613 9.71124 8.37613 9.14535C8.37613 8.6686 8.50225 8.25194 8.7545 7.89535C9.00676 7.53488 9.3531 7.25581 9.79354 7.05814C10.238 6.86047 10.7425 6.76163 11.3071 6.76163C11.8516 6.76163 12.3581 6.84109 12.8266 7C13.299 7.15504 13.6834 7.36628 13.9797 7.63372L13.3131 8.5814C13.0688 8.35659 12.7625 8.18023 12.3941 8.05233C12.0298 7.92442 11.6514 7.86047 11.259 7.86047C10.9187 7.86047 10.6224 7.90891 10.3701 8.00581C10.1179 8.09884 9.92167 8.23643 9.78153 8.4186C9.6454 8.5969 9.57733 8.81589 9.57733 9.07558C9.57733 9.40116 9.69344 9.65698 9.92568 9.84302C10.1579 10.0291 10.5703 10.2016 11.1629 10.3605L12.0938 10.564C12.7985 10.7616 13.3331 11.0368 13.6974 11.3895C14.0658 11.7384 14.25 12.2112 14.25 12.8081C14.25 13.2888 14.1299 13.7132 13.8896 14.0814C13.6534 14.4457 13.3111 14.7306 12.8626 14.936C12.4142 15.1415 11.8736 15.2442 11.241 15.2442ZM10.7725 16V6H11.8596V16H10.7725Z" fill="#9CBE00"/>
</svg>

