import { createMutable } from "solid-js/store"
import { onMount, type ComponentProps } from "solid-js"

import { api, errorHandled, useCache, lang } from "#/lib/mod"
import { SwitchInput } from "#/components/mod"

type BankSelectOptions = {
	initial?: api.GetBanksResponse[number]["currency_slug"]
	onChange?(): void
}
export function createBankSelect({ onChange, initial }: BankSelectOptions = {}) {
	let cache = useCache()

	let state = createMutable({
		loading: false,

		variant: (initial ?? null) as api.GetBanksResponse[number]["currency_slug"],

		get bank() {
			return cache.resolve("banks", this.variant)
		}
	})


	async function load() {
		state.loading = true
		let response = await api.getBanks()
			.finally(() => state.loading = false)

		if (errorHandled(response)) {
			return
		}

		cache.update("banks", response)

		let variant: string
		switch (lang()) {
			case "ru":
				variant = "rub"
				break
			case "en":
			default:
				variant = "usd"
				break
		}

		state.variant = variant

		onChange?.()
	}

	function toggle() {
		if (state.variant === "rub")
			return state.variant = "usd"

		if (state.variant === "usd")
			return state.variant = "rub"
	}

	function Component(props: ComponentProps<typeof SwitchInput>) {
		onMount(load)

		return <SwitchInput
			checked={state.variant === "rub"}
			onInput={toggle}
			{...props}
		/>
	}

	return Object.assign(state, { Component, toggle, load })
}
