import type { JSX, ParentProps, ResolvedJSXElement } from "solid-js"
import { batch, children, createComputed, createContext, For, mergeProps, onCleanup, untrack, useContext } from "solid-js"

import { Ref, signal, useLayout } from "#/lib/mod"

import { animateStackBottomSheetIn, animateStackBottomSheetOut, animateStackIn, animateStackOut, highlight } from "./animations"
import {
	createLocation,
	createMatcher,
	expandOptionals,
	hide,
	moveOnTop,
	setVisibility,
	show,
	type MatchFilter,
	type Params,
	type PathMatch,
} from "./helpers"
import type { RouteDefinition } from "./routes"
import { tracing } from "../tracing"

export type View = {
	placeholder: Placeholder

	// View data
	params: Params
	pathname: string
	rpath: string

	props?: any

	next: View | null
	parent: View | null
	active?: true

	// Runtime objects
	element?: HTMLElement
}

type Placeholder = PlaceholderDefinition & {
	matcher: (path: string) => PathMatch[]
}

let getMatchers = (template: string, matchFilters?) =>
	expandOptionals(template).map(path => createMatcher(path, false, matchFilters))

let getPath = () => window.location.pathname + window.location.search + window.location.hash
let getState = () => history.state

function createAdvancedRouteContext() {
	let trace = tracing("AdvancedRouteContext")
	let layout = useLayout()
	let
		wpath = signal(getPath()),
		wstate = signal(getState()),
		top = signal<View>(null),
		placeholders = signal<Placeholder[]>([]),
		blocked = signal(false)

	let ctx = {
		graph: [] as View[],
		subscribers: [] as (() => void)[],
		handlers: new Map<string, (el: Element) => void>(),

		// compare unwrapped values
		get top() {
			return top()
		},
		set top(value) {
			top(value)
		},

		get path() {
			return wpath()
		},
		set path(value) {
			wpath(value)
		},

		location: createLocation(wpath, wstate),

		enqeue,
		switchTo,
		stack,
		unstack,
		onRoutesChanged,

		handleExternal,
		commit,

		placeholders,
		Placeholder,

		blocked,

		view: undefined as View,
	}

	type UpdateBrowserHistoryOptions = {
		path?: string
		state?: any
		force?: "replace" | "push" | "back"
	}

	type RuntimeNavigationOptions = UpdateBrowserHistoryOptions & {
		props?: any
	}

	function commit(path: string): void
	function commit(options: UpdateBrowserHistoryOptions): void
	function commit(options: UpdateBrowserHistoryOptions | string): void {
		let {
			path = getPath(),
			force,
			state = history.state,
		}: UpdateBrowserHistoryOptions = typeof options === "string" ? { path: options } : options

		let method = force ?? (path === getPath() ? "replace" : "push")
		if (force === "back") {
			history.back()
		}
		else {
			if (UNISIM_BUILD_PLATFORM === 'ios') {
				path = window.location.href.split('#')[0] + '#' + path

			}
			history[`${method}State`](state, undefined, path)
		}
		batch(() => {
			wpath(path)
			wstate(state)
		})
	}

	let queue = []
	function enqeue(op) {
		queue.push(op)
		if (!blocked()) {
			perform()
		}
	}
	function perform() {
		queue.shift()?.()
	}
	createComputed(() => {
		if (!blocked()) {
			untrack(perform)
		}
	})

	function handleExternal(preserve = false) {
		let current_path = getPath()
		let matches = getMatches(current_path)
		if (!matches.length) {
			trace.warn("No routes found while performing external navigation", placeholders())
			return
		}
		// switchTo(matches[0][1].path) // matcher matches /* as / always

		let [, match] = matches[0] ?? []
		if (!match) {
			trace.warn("handleExternal: destination is undefined")
			return
		}

		let { top } = ctx

		// exact match
		// this is not ideal but ok
		let view = ctx.graph.find(view => view.pathname === match.path)
		if (!view) {
			let [match_without_hash] = match.path.split("#")
			view = ctx.graph.find(view => view.pathname === match_without_hash)
		}

		if (top && view) {
			if (view.parent?.pathname === top.pathname) {
				stack(current_path)
				return
			}
			if (top.parent?.pathname === view.pathname) {
				unstack({ force_to: current_path, preserve: top.placeholder.preserve ?? true })
				return
			}
		}

		trace.debug("externally switching to", current_path)
		switchTo({ path: current_path, preserve })
	}

	window.addEventListener("popstate", e => {
		if (blocked()) {
			enqeue(() => handleExternal(true))
			return
		}
		handleExternal(true)
	})

	function notifyGraph() {
		ctx.subscribers.forEach(listener => listener())
	}

	function removeView(view: View) {
		if (ctx.top === view) {
			ctx.top = null
		}
		ctx.graph.spliceSafe(ctx.graph.indexOf(view), 1)
		notifyGraph()
		// logGraph(ctx.graph)
	}

	function addView(view: View) {
		ctx.graph.push(view)
		notifyGraph()
		// logGraph(ctx.graph)
	}

	function getMatches(pathname: string) {
		let placeholders_matches: [Placeholder, PathMatch][] = []
		for (let pl of placeholders()) {
			let matches = pl.matcher(pathname).filter(Boolean)

			if (matches.length) {
				placeholders_matches.push([pl, matches[0]])
			}
		}
		return placeholders_matches
	}

	async function navigate(opts: RuntimeNavigationOptions) {
		let url = new URL(opts.path, "http://faundr")

		let placeholders_matches: [Placeholder, PathMatch][] = getMatches(url.pathname)
		if (!placeholders_matches.length) {
			trace.error("No matched placeholders for route", opts.path)
			return null
		}
		let previous_placeholders_matches: [Placeholder, PathMatch][] = getMatches(location.pathname)

		let [previous_placeholder, previous_match] = previous_placeholders_matches[0]
		let [placeholder, match] = placeholders_matches[0]

		let { top } = ctx
		if (top?.element && top.pathname === match.path) {
			if (previous_placeholder) {
				let previous_view = ctx.graph.find(v => v.pathname === previous_match.path)
				if (previous_view) {
					hide(previous_view)
				}
			}
			commit(opts)
			show(top)
			return top
		}

		let existing_view = ctx.graph.find(v => v.pathname === match.path)

		if (existing_view) {
			if (existing_view.element) {
				existing_view.props = opts.props

				ctx.top = existing_view

				applyViewOptions(existing_view, existing_view.placeholder.route)

				commit(opts)
				return existing_view
			}
			ctx.graph.spliceSafe(ctx.graph.indexOf(existing_view), 1)
			notifyGraph()
		}

		if (ctx.graph.length > 25) {
			ctx.graph.pop() // TODO this can cause error if .next or .parent will be null
		}

		let view: View = {
			placeholder,

			pathname: match.path,
			params: match.params,

			rpath: opts.path,

			props: opts.props,

			parent: null,
			next: null,
		}

		let { promise: rendered, resolve } = Promise.withResolvers()
		ctx.handlers.set(view.pathname, resolve)
		addView(view)
		await rendered

		trace.debug("Rendered route", view.pathname)

		applyViewOptions(view, placeholder.route)

		commit(opts)
		ctx.handlers.delete(view.pathname)

		return view
	}

	type StackOptions = {
		animation?: "bottom-sheet" | "swipe"
	}

	async function stack(opts: RuntimeNavigationOptions, so?: StackOptions): Promise<void>
	async function stack(to: string, so?: StackOptions): Promise<void>
	async function stack(_opts: string | RuntimeNavigationOptions, so?: StackOptions) {
		if (ctx.blocked()) return

		ctx.blocked(true)

		let opts: RuntimeNavigationOptions = typeof _opts === "string" ? { path: _opts } : _opts

		let departure = ctx.top

		// TODO: refactor
		let [_, match] = getMatches(opts.path)[0]

		let dest = ctx.graph.find(v => v.pathname === match.path)

		let circular = false
		if (departure && dest && departure?.parent === dest) {
			circular = true
			let _ = dest.parent
			departure.parent = _
			opts.force = "back"
		}

		dest = await navigate(opts)

		if (!dest) {
			trace.warn("stack: destination is undefined")
			ctx.blocked(false)
			return
		}

		if (departure === dest) {
			await highlight(departure.element)
			ctx.blocked(false)
			return
		}

		if (!circular) {
			dest.parent = departure
		}

		let moveBack = moveOnTop(dest.element, departure.element)
		show(dest)

		// TODO better logic here
		let anim: Promise<any>
		switch (so?.animation ?? dest.placeholder.route.stack_animation) {
			case "bottom-sheet":
				anim = animateStackBottomSheetIn(departure.element, dest.element)
				break
			case "swipe":
			default:
				anim = animateStackIn(departure.element, dest.element)
				break
		}

		ctx.top = dest

		await anim
		hide(departure)
		moveBack()

		ctx.blocked(false)
	}

	async function unstack(options?: { fallback?: string; preserve?: boolean; force_to?: string }, runtime_opts?: RuntimeNavigationOptions) {
		if (ctx.blocked()) {
			return
		}
		ctx.blocked(true)

		let departure = ctx.top

		let to = options?.force_to ?? departure?.parent?.rpath ?? options?.fallback ?? "/deals"

		let dest = await navigate({ path: to, ...runtime_opts })

		if (!dest) {
			trace.warn("unstack: destination is undefined")
			ctx.blocked(false)
			return
		}

		let moveBack = moveOnTop(departure.element, dest.element)

		show(dest)

		// TODO better logic here
		let anim: Promise<any>
		
		switch (departure.placeholder.route.stack_animation) {
			case "bottom-sheet":
				anim = animateStackBottomSheetOut(dest.element, departure.element)
				break
			case "swipe":
			default:
				anim = animateStackOut(dest.element, departure.element)
				break
		}

		await anim
		hide(departure)

		ctx.top = dest

		moveBack()

		if (options?.preserve === true || options?.preserve !== false && departure.placeholder.preserve) {
			ctx.blocked(false)
			return
		}

		removeView(departure)
		ctx.blocked(false)
	}

	type SwitchToOptions = RuntimeNavigationOptions & NavigationOptions
	async function switchTo(to: string): Promise<void>
	async function switchTo(to: SwitchToOptions): Promise<void>
	async function switchTo(opts: string | SwitchToOptions) {
		if (ctx.blocked()) {
			return
		}
		ctx.blocked(true)

		opts = typeof opts === "string" ? { path: opts } : opts

		let dep = ctx.top
		let dest = await navigate(opts)
		if (!dest) {
			trace.warn("switchTo: destination is undefined")
			ctx.blocked(false)
			return
		}

		dep && hide(dep)
		ctx.top = dest
		show(dest)

		if (opts.preserve || (opts.preserve === undefined && dep?.placeholder.preserve === true)) {
			ctx.blocked(false)
			return
		}

		if (dep && dep !== dest) {
			removeView(dep)
		}
		ctx.blocked(false)
	}

	function applyViewOptions(view: View, definition: RouteDefinition) {
		// @ts-ignore
		layout.meta.viewport["interactive-widget"] = definition["interactive-widget"]
		if (definition.stack_animation === "bottom-sheet") {
			document.documentElement.style.backgroundColor = "#000000"
		}
	}

	function onRoutesChanged(els: ResolvedJSXElement[]) {
		ctx.placeholders(els as unknown as Placeholder[])
		trace.debug("Routes were reloaded", ctx.placeholders().map(x => x.route))
		handleExternal(false)
	}

	function Placeholder(placeholder: Placeholder) {
		let getViews = () => ctx.graph.filter(view => view.placeholder === placeholder)
		let sViews = signal(getViews(), { equals: false })
		let onGraphChanged = () => sViews(getViews())

		ctx.subscribers.push(onGraphChanged)
		onCleanup(() => {
			ctx.subscribers.spliceSafe(ctx.subscribers.indexOf(onGraphChanged), 1)
			for (let i = ctx.graph.length; i >= 0; i--) {
				if (ctx.graph[i]?.placeholder === placeholder) {
					ctx.graph.splice(i, 1)
				}
			}
		})

		return <For each={sViews()} children={View} />
	}

	return ctx
}

function View(view: View) {
	let trace = tracing("View")
	let router = useRouter()

	function onRef(ref: Element) {
		if (ref instanceof HTMLElement) {
			view.element = ref
			setVisibility(ref, view.active)
			router.handlers.get(view.pathname)?.(ref)
			return
		}
		else if (ref) {
			trace.error("Bad page provided", ref)
			return
		}
	}

	return (
		<RouterContext.Provider
			value={mergeProps(router, { view })}
			children={Ref({
				// TODO: hmr doesn't work ?
				// children: untrack(() => view.placeholder.component(view.props ?? {}, view.placeholder.preload?.())),

				// @ts-ignore
				children: <view.placeholder.component {...view.props} />,
				ref: onRef,
			})}
		/>
	)
}


let RouterContext = createContext<ReturnType<typeof createAdvancedRouteContext>>()
export let useRouter = () => useContext(RouterContext)

// type RouterProps = {
// 	children: (p: JSX.Element) => JSX.Element
// }
export function Router(props: ParentProps) {
	let ctx = createAdvancedRouteContext()
	return <RouterContext.Provider
		value={ctx}
		children={props.children}
	/>
}

Router.Routes = function(props: ParentProps) {
	let { onRoutesChanged, Placeholder, placeholders } = useRouter()
	let c = children(() => props.children)
	// Run immideately
	createComputed(() => {
		let children = c.toArray().filter(Boolean)
		untrack(() => onRoutesChanged(children))
	})
	// return <routes></routes>
	// return null
	return <For each={placeholders()} children={Placeholder} />
}

type NavigationOptions = {
	preserve?: boolean
}

type PlaceholderDefinition<TPreload = any> = {
	route: RouteDefinition
	component<P = {}>(props: P, preload_promise?: Promise<TPreload>): JSX.Element
	match_filters?: Record<string, MatchFilter>
	preload?(): Promise<TPreload>
	// behaviour?: "stack" | "plain"
} & NavigationOptions

Router.RoutePlaceholder = function(props: PlaceholderDefinition) {
	let placeholder = {
		...props,
		matcher: (path: string) =>
			getMatchers(props.route.template, props.match_filters).map(matcher => matcher(path)),
	}
	return placeholder as unknown as JSX.Element
}

// https://developer.mozilla.org/en-US/docs/Web/API/Navigation_API#browser_compatibility


export function Navigate(props: { to: string }) {
	let router = useRouter()
	router.enqeue(() => router.switchTo(props.to))
	// TODO DO NOT TOUCH THIS
	return <div></div>
}
