import { langs } from "#/lib/appearance/i18n"

export let dictionary = langs({
	ru: {
		common: {
			back: "Назад",
			continue: "Продолжить",
			loading: "Загрузка",
			today: "Сегодня",
		},
	},
	en: {
		common: {
			back: "Back",
			continue: "Continue",
			loading: "Loading",
			today: "Today",
		},
	},
})
