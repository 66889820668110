import { MobilePage } from "#/common/mod"
import { BasicInput, BasicInput2 } from "#/components/basic-input"
import { Dropdown } from "#/components/mod"
import { BackGroup } from "#/components/prefabs/back-group"
import { api, errorHandled, lang, langs } from "#/lib/mod"
import { Show } from "solid-js"
import { createMutable } from "solid-js/store"


export default function CompatibilityPage() {

	let state = createMutable({
		selected: null as Awaited<api.SearchDeviceResponse>["results"][number]
	})

	async function searchDevice(v: string) {
		let response = await api.searchDevices({ search: v, lang: lang() })

		if (errorHandled(response))
			return

		return response.results
	}

	return <MobilePage class=":c: uno-layer-v2:(bg-white)">
		<BackGroup />

		<div class=":c: flex items-center gap-1 m-inline-4">
			<span class=":c: font-700 text-6" innerText={t().title} />
		</div>

		<Dropdown
			Root={p =>
				<BasicInput.Root {...p}
					classList={{ ...p.classList, ":c: b-none! w-auto m-inline-4 m-t-6 m-b-10": true }}
					onClick={(e) => {
						state.selected = null
						p.onClick(e)
					}}
				>
					<BasicInput.IconW Icon={LupaIcon} x={0} iconc="uno-layer-v1:size-6" />
					{p.children}
				</BasicInput.Root>
			}
			Input={p =>
				<BasicInput2 {...p}
					classList={{
						...p.classList,
						":c: uno-layer-v4:(c-black font-300 text-4 p-l-10 h-11)": true
					}}
					placeholder={"Iphone 15 Pro Max"}
					onClick={(e) => {
						state.selected = null
						p.onClick(e)
					}}
					onInput={(e) => {
						state.selected = null
						p.onInput(e)
					}}
				/>
			}
			RightIcon={p => null}
			search={searchDevice}
			display={p => p.name}
			afterSelect={(item) => {
				state.selected = item
			}}
		/>
		<Show when={state.selected != null}>
			<div class=":c: flex items-center gap-1 m-inline-4 font-300">
				<Show when={state.selected.supports_esim}>
					<span class=":c: c-#9cbe01" innerText={t().supported} />
					<SupportedMark class=":c: size-4.5" />
				</Show>
				<Show when={!state.selected.supports_esim}>
					<span class=":c: c-#ff0733" innerText={t().unsupported} />
					<UnsupportedMark class=":c: size-4.5" />
				</Show>
			</div>
		</Show>
		<div
			class=":c: flex-center m-inline-4 font-200 leading-20px rounded-8px bg-#F1F3F6 p-4 m-t-3"
			children={t().instruction()}
		/>
	</MobilePage>
}

let LupaIcon = p => <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
	<path d="M19.6 21L13.3 14.7C12.8 15.1 12.225 15.4167 11.575 15.65C10.925 15.8833 10.2333 16 9.5 16C7.68333 16 6.146 15.3707 4.888 14.112C3.63 12.8533 3.00067 11.316 3 9.5C2.99933 7.684 3.62867 6.14667 4.888 4.888C6.14733 3.62933 7.68467 3 9.5 3C11.3153 3 12.853 3.62933 14.113 4.888C15.373 6.14667 16.002 7.684 16 9.5C16 10.2333 15.8833 10.925 15.65 11.575C15.4167 12.225 15.1 12.8 14.7 13.3L21 19.6L19.6 21ZM9.5 14C10.75 14 11.8127 13.5627 12.688 12.688C13.5633 11.8133 14.0007 10.7507 14 9.5C13.9993 8.24933 13.562 7.187 12.688 6.313C11.814 5.439 10.7513 5.00133 9.5 5C8.24867 4.99867 7.18633 5.43633 6.313 6.313C5.43967 7.18967 5.002 8.252 5 9.5C4.998 10.748 5.43567 11.8107 6.313 12.688C7.19033 13.5653 8.25267 14.0027 9.5 14Z" fill="#C3CAD4" />
</svg>

let SupportedMark = p => <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
	<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9954 14.6321H27.9994C28.7341 14.6321 29.3314 15.2268 29.3327 15.9615C29.3434 19.5228 27.9661 22.8761 25.4541 25.4015C22.9434 27.9268 19.5994 29.3228 16.0381 29.3335H15.9994C12.4514 29.3335 9.11408 27.9575 6.59808 25.4548C4.07274 22.9441 2.67674 19.6001 2.66608 16.0388C2.65541 12.4761 4.03274 9.12414 6.54474 6.59881C9.05541 4.07348 12.3994 2.67748 15.9607 2.66681C17.0207 2.68281 18.1007 2.78948 19.1354 3.03748C19.8501 3.21081 20.2914 3.93081 20.1181 4.64681C19.9461 5.36148 19.2221 5.80148 18.5101 5.63081C17.6821 5.43081 16.8034 5.34681 15.9687 5.33348C13.1194 5.34148 10.4434 6.45881 8.43541 8.47881C6.42608 10.4988 5.32474 13.1815 5.33274 16.0308C5.34074 18.8801 6.45808 21.5548 8.47808 23.5641C10.4914 25.5655 13.1607 26.6668 15.9994 26.6668H16.0301C18.8794 26.6588 21.5554 25.5415 23.5634 23.5215C25.5727 21.5001 26.6741 18.8188 26.6661 15.9695C26.6647 15.2335 27.2594 14.6335 27.9954 14.6321ZM11.0567 15.0573C11.5781 14.536 12.4207 14.536 12.9421 15.0573L15.9341 18.0493L24.3301 8.45468C24.8154 7.90401 25.6567 7.84534 26.2114 8.33068C26.7647 8.81468 26.8207 9.65734 26.3354 10.212L17.0021 20.8787C16.7594 21.156 16.4127 21.32 16.0434 21.3333H15.9994C15.6461 21.3333 15.3074 21.1933 15.0567 20.9427L11.0567 16.9427C10.5354 16.4213 10.5354 15.5787 11.0567 15.0573Z" fill="#9CBE00" />
	<mask id="mask0_758_52323" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="2" width="28" height="28">
		<path fill-rule="evenodd" clip-rule="evenodd" d="M27.9954 14.6321H27.9994C28.7341 14.6321 29.3314 15.2268 29.3327 15.9615C29.3434 19.5228 27.9661 22.8761 25.4541 25.4015C22.9434 27.9268 19.5994 29.3228 16.0381 29.3335H15.9994C12.4514 29.3335 9.11408 27.9575 6.59808 25.4548C4.07274 22.9441 2.67674 19.6001 2.66608 16.0388C2.65541 12.4761 4.03274 9.12414 6.54474 6.59881C9.05541 4.07348 12.3994 2.67748 15.9607 2.66681C17.0207 2.68281 18.1007 2.78948 19.1354 3.03748C19.8501 3.21081 20.2914 3.93081 20.1181 4.64681C19.9461 5.36148 19.2221 5.80148 18.5101 5.63081C17.6821 5.43081 16.8034 5.34681 15.9687 5.33348C13.1194 5.34148 10.4434 6.45881 8.43541 8.47881C6.42608 10.4988 5.32474 13.1815 5.33274 16.0308C5.34074 18.8801 6.45808 21.5548 8.47808 23.5641C10.4914 25.5655 13.1607 26.6668 15.9994 26.6668H16.0301C18.8794 26.6588 21.5554 25.5415 23.5634 23.5215C25.5727 21.5001 26.6741 18.8188 26.6661 15.9695C26.6647 15.2335 27.2594 14.6335 27.9954 14.6321ZM11.0567 15.0573C11.5781 14.536 12.4207 14.536 12.9421 15.0573L15.9341 18.0493L24.3301 8.45468C24.8154 7.90401 25.6567 7.84534 26.2114 8.33068C26.7647 8.81468 26.8207 9.65734 26.3354 10.212L17.0021 20.8787C16.7594 21.156 16.4127 21.32 16.0434 21.3333H15.9994C15.6461 21.3333 15.3074 21.1933 15.0567 20.9427L11.0567 16.9427C10.5354 16.4213 10.5354 15.5787 11.0567 15.0573Z" fill="white" />
	</mask>
	<g mask="url(#mask0_758_52323)">
		<rect width="32" height="32" fill="#9CBE00" />
	</g>
</svg>

let UnsupportedMark = p => <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
	<path d="M15.9993 5.33335C13.1704 5.33335 10.4573 6.45716 8.45688 8.45755C6.45649 10.4579 5.33268 13.171 5.33268 16C5.33268 18.829 6.45649 21.5421 8.45688 23.5425C10.4573 25.5429 13.1704 26.6667 15.9993 26.6667C18.8283 26.6667 21.5414 25.5429 23.5418 23.5425C25.5422 21.5421 26.666 18.829 26.666 16C26.666 13.171 25.5422 10.4579 23.5418 8.45755C21.5414 6.45716 18.8283 5.33335 15.9993 5.33335ZM2.66602 16C2.66602 8.63602 8.63535 2.66669 15.9993 2.66669C23.3633 2.66669 29.3327 8.63602 29.3327 16C29.3327 23.364 23.3633 29.3334 15.9993 29.3334C8.63535 29.3334 2.66602 23.364 2.66602 16ZM10.39 10.3907C10.6401 10.1407 10.9791 10.0003 11.3327 10.0003C11.6862 10.0003 12.0253 10.1407 12.2753 10.3907L15.9993 14.1147L19.7233 10.3907C19.8463 10.2633 19.9935 10.1618 20.1561 10.0919C20.3188 10.022 20.4938 9.98522 20.6708 9.98369C20.8479 9.98215 21.0234 10.0159 21.1873 10.0829C21.3511 10.15 21.5 10.249 21.6252 10.3742C21.7504 10.4994 21.8494 10.6482 21.9164 10.8121C21.9835 10.9759 22.0172 11.1515 22.0157 11.3286C22.0141 11.5056 21.9774 11.6806 21.9075 11.8432C21.8376 12.0059 21.736 12.153 21.6087 12.276L17.8847 16L21.6087 19.724C21.8516 19.9755 21.986 20.3123 21.9829 20.6619C21.9799 21.0115 21.8396 21.3459 21.5924 21.5931C21.3452 21.8403 21.0108 21.9805 20.6612 21.9836C20.3116 21.9866 19.9748 21.8522 19.7233 21.6094L15.9993 17.8854L12.2753 21.6094C12.0239 21.8522 11.6871 21.9866 11.3375 21.9836C10.9879 21.9805 10.6535 21.8403 10.4063 21.5931C10.159 21.3459 10.0188 21.0115 10.0158 20.6619C10.0127 20.3123 10.1471 19.9755 10.39 19.724L14.114 16L10.39 12.276C10.1401 12.026 9.99963 11.6869 9.99963 11.3334C9.99963 10.9798 10.1401 10.6407 10.39 10.3907Z" fill="#FF0733" />
</svg>


let t = langs({
	ru: {
		title: "Проверка совместимости",

		supported: "Ваше устройство поддерживает eSIM",
		unsupported: "Ваше устройство не поддерживает eSIM",

		instruction: () => <div class=":c: flex flex-col">
			<div>Чтобы удостовериться в совместимости устройства, наберите *#06# в режиме набора номера. Устройство поддерживает eSIM, если появляется номер EID.*</div>
			<div>* EID и MEID не одно и то же</div>
		</div>
	},
	en: {
		title: "Compatibility check",

		supported: "Your device does support eSIM",
		unsupported: "Your device doesn't support eSIM",

		instruction: () => <div class=":c: flex flex-col">
			<span>To make sure your device supports eSIM, type *#06# in the phone number mode. The device supports eSIM if the EID number appears.*</span>

			<span class="m-t-10">* EID and MEID are not the same</span>
		</div>
	}
})
