import { noripple, ripple } from "#/lib/appearance/ripple/ripple"
import { tracing } from "#/lib/mod"

if (!globalThis.Android) {
	globalThis.Android = null
}

globalThis.tracing = tracing
globalThis.gtrace = tracing("<GLOBAL>")

globalThis.ripple = ripple
globalThis.noripple = noripple

