import "uno.css"
import "#/assets/styles/index.css"
import "#/assets/fonts/fonts.css"

import "./lib/polyfills"
import "./lib/std"
import "./lib/globals"

import { render } from "solid-js/web"
import { toast, Toaster } from "solid-toast"

import { MetaProvider, Title } from "#/lib/meta"
import { AuthContextProvider } from "#/lib/auth"
import { I18n } from "#/lib/appearance/i18n"

// Contexts
import { LayoutContextProvider } from "#/layout.context"
import { CacheContextProvider } from "#/lib/cache/cache.context"

import { MOBILE_NAVBAR_HEIGHT } from "./common/mod"
import MobileNavigator from "./common/layout/navigator.mobile"
import { ensureNativeBridgeInstalled, native_emitter } from "./lib/behaviour"

let dispose: () => void

void function bootstrap() {
	if (import.meta.hot) {
		document.body.replaceChildren()
		mount()

		import.meta.hot.dispose(dispose)
		import.meta.hot.accept(Function.NOOP)
	}
	else mount()
}()

function mount() {
	dispose = render(App, document.body)
}

function App() {
	let trace = tracing("App")

	trace.info(UNISIM_BUILD_PLATFORM, "platfrom")

	if (ensureNativeBridgeInstalled()) {
		native_emitter.on("show_success_toast", data => toast.success(data.text))
	}

	return [
		<MetaProvider>
			<Title innerText={import.meta.env.DEV ? "[локал] UNISIM" : "UNISIM"} />
			<I18n>
				<LayoutContextProvider>
					<CacheContextProvider>
						<AuthContextProvider>
							<MobileNavigator />
						</AuthContextProvider>
					</CacheContextProvider>
				</LayoutContextProvider>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{
				position: "bottom-center",
				duration: 1550
			}}
			containerStyle={{
				bottom: `calc(10px + ${MOBILE_NAVBAR_HEIGHT})`,
			}}
		/>,
	]
}
