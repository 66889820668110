import { For } from "solid-js"

import { type ComposableComponentProps, drop } from "#/lib/mod"

type SegmentNavProps = {
	count: number
	step: number
	onSegmentClick?(index: number): void
} & ComposableComponentProps<"div"> & { class?: string }

export function SegmentNav(props: SegmentNavProps) {
	let other = drop(props, "count", "step", "onSegmentClick", "class")

	return (
		<div {...other}
			classList={{
				":c: flex gap-0.5 justify-between items-center": true,
				[props.class]: !!props.class,
				...props.classList
			}}>
			<For each={new Array(props.count).fill(null)}>
				{(_, i) => (
					<div
						class=":c: flex-1 h3 flex items-center ptr"
						onClick={() => props.onSegmentClick?.(i())}
					>
						<div
							classList={{
								":c: w-full h-6px bg-#f1f3f6 rounded-[0_100px]": true,
								"uno-layer-v2:bg-green-500": i() === props.step,
							}}
						/>
					</div>
				)}
			</For>
		</div>
	)
}
