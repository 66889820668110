import { createEffect, Match, on, onCleanup, Show, Switch } from "solid-js"
import { createMutable } from "solid-js/store"
import { Transition } from "solid-transition-group"
import { toast } from "solid-toast"

import { MobilePage, createPayment, dictionary, payment_langs } from "#/common/mod"
import { ItemIconWrapper } from "#/components/item-icon-wrapper"
import { CURRENCY_SYMBOLS, ROUTES, api, errorHandled, formatFloatLike, isApiError, lang, langs, onlyDev, useAuth, useRouter } from "#/lib/mod"

import PatternImage from "#/assets/pattern.svg?raw"
import {
	Spinner,
	BasicInput,
	ContinueButton,
	createBankSelect,
	DropdownSection,
	useDropdownSelectionContext,
	BasicInput2
} from "#/components/mod"

const MIN_ORDER_PRICE_USD = 8.00, MAX_ORDER_PRICE_USD = 9999

export default function OrderPage() {
	let channel = new window.BroadcastChannel("order")

	let router = useRouter(), auth = useAuth(), trace = tracing("OrderPage")

	let bank = createBankSelect()

	let state = createMutable({
		plans_asc: [] as Awaited<ReturnType<typeof load>>,
		value: 15,

		email: auth.user?.email ?? (onlyDev("tuningiposadka@gmail.com")),

		// show_promocode_field: false,
		loading: false,
		submitting: false,

		promocode: {
			value: null as string,
			coupon: null as api.CheckPromocodeResponse["coupon"]
		},
	})

	let err = createMutable({
		value: null as string,
		email: null as string,
		bank: null as string,
		promocode: null as string,
	})

	let check = {
		value() {
			err.value = function() {
				if (state.value !== Math.clamp(state.value, MIN_ORDER_PRICE_USD, MAX_ORDER_PRICE_USD))
					return `Введите сумму от ${MIN_ORDER_PRICE_USD} до ${MAX_ORDER_PRICE_USD}`
				return null
			}()
		},
		email() {
			err.email = function() {
				// TODO: exhaustive check
				if (!state.email?.includes("@"))
					return "Некорректный email"

				return null
			}()
		},
		bank() {
			err.bank = function() {
				if (!bank.bank)
					return "Выберите способ оплаты"

				return null
			}()
		},
	}

	let canContinue = () => !Object.values(err).filter(e => e != null).length

	const enter_exit_active_class = ":c: transition-(duration-250 property-[margin,opacity] ease-[cubic-bezier(0.2,0.75,0.15,0.98)])"
	const enter_exit_class = ":c: opacity-0 ml-[-14rem]!"

	let { perform, paying, remaining } = createPayment({
		action: "order",
		requestUrl: ({ success_url, cancel_url }) => api.createOrder({
			product: "esim_from_requested_price",

			customer_email: state.email,
			amount: state.value,
			bank_id: bank.bank.bank_id as any,
			promocode: state.promocode.value,

			user_lang: lang(),

			success_url,
			cancel_url,
		}),
		onFinish(result) {
			if (!result || result.$ === "unknown") {
				toast.error(payment_langs().errors.payment_status_unknown)
				trace.error("unknown payment status", result)
				return
			}

			if (result.$ === "success") {
				toast.success(payment_langs().payment_successful)
				if (auth.is_authenticated) auth.refetchUser()
				router.switchTo(ROUTES.order_completed.template)
				return
			}

			toast.error(payment_langs().errors.payment_cancelled)
		},
	})


	queueMicrotask(load)
	queueMicrotask(bank.load)

	createEffect(on(lang, async (lang) => {
		if (!bank.bank) await bank.load()

		if (lang !== "ru") {
			bank.variant = "usd"
			trace.debug("forced new bank variant due to language change")
		}
	}, { defer: true }))

	onCleanup(() => {
		channel.close()
	})

	async function load() {
		state.loading = true

		let plans = await api.getEsimPlans()

		if (errorHandled(plans, "Загрузка предложений")) {
			state.loading = false
			return
		}

		let parsed_plans = plans.map(p => ({
			title: p.title,
			esim_price: Number(p.esim_price),
			upper_price_limit: function() {
				let as_number = Number(p.upper_price_limit)

				if (as_number === 0)
					return Number.POSITIVE_INFINITY

				return as_number
			}(),
			lower_price_limit: 0,
			bonus_balance_percent: Number(p.bonus_balance_percent),
		})).toSorted((o1, o2) => o1.upper_price_limit - o2.upper_price_limit)

		// separate loop cause I'm too lazy to write mapped type
		for (let i = 1; i < parsed_plans.length; i++)
			parsed_plans[i].lower_price_limit = parsed_plans[i - 1].upper_price_limit + 1

		state.plans_asc = parsed_plans

		state.loading = false

		return parsed_plans
	}

	function getAvailablePlan(value: number) {
		return state.plans_asc.find(plan => value >= plan.lower_price_limit && value <= plan.upper_price_limit)
	}

	function getBonusBalance(value: number, plan_only = false) {
		let plan = getAvailablePlan(value)

		if (!plan) return 0

		let { coupon } = state.promocode

		let price_with_applied_plan = value * plan.bonus_balance_percent / 100

		if (!coupon || plan_only) {
			return price_with_applied_plan
		}

		let coupon_amount_as_number = Number(coupon.amount)
		if (isNaN(coupon_amount_as_number) || coupon_amount_as_number < 0 || coupon_amount_as_number > Number.MAX_SAFE_INTEGER) {
			toast.error(t().errors.invalid_promocode)
			state.promocode.coupon = null
			return
		}

		if (coupon.deduction_type === "fixed_amount") {
			return price_with_applied_plan + coupon_amount_as_number
		}
		else if (coupon.deduction_type === "percentage") {
			return price_with_applied_plan + (value * coupon_amount_as_number / 100)
		}
		else {
			toast.error(t().errors.invalid_promocode)
			state.promocode.coupon = null
			return
		}
	}

	function getEsimPrice(value: number) {
		let plan = getAvailablePlan(value)

		if (!plan) return 0

		return plan?.esim_price
	}

	async function checkPromocode() {
		if (!state.promocode.value) {
			return
		}

		state.submitting = true

		err.promocode = null

		let response = await api.checkPromocode({
			code: state.promocode.value.trim(),
			customer_email: auth.user?.email ?? state.email
		})

		state.submitting = false

		if (isApiError(response)) {
			if (response.$api_error === "remote") {
				err.promocode = response.detail
				return
			}

			toast.error(t().errors.check_promocode)
			trace.error("error checking promocode", response)
			return
		}

		if (response.reason === "does_not_exist") {
			err.promocode = t().errors.invalid_promocode
			state.promocode.coupon = null
			return
		}

		state.promocode.coupon = response.coupon
	}

	async function submit() {
		state.submitting = true

		if (state.promocode.value?.trim().length > 0) {
			await checkPromocode()
		}
		else {
			err.promocode = null
		}

		for (let fn_key in check)
			check[fn_key]()

		if (!canContinue()) {
			state.submitting = false
			return
		}

		await perform()
		state.submitting = false
	}

	return <MobilePage id="order" class=":c: uno-layer-v2:(rounded-t-20px bg-white bg-([position:50%_100%] no-repeat))">
		<div class=":c: relative min-h-full h-fit [&>*]:z-1">
			<div
				class=":c: absolute inset-0 overflow-hidden z-0! [&>svg]:(absolute bottom-[-10%] left-[-25%] w-185 [--pattern-color-one:#F1F3F6] [--pattern-color-two:white])"
				innerHTML={PatternImage}
			/>
			<div class=":c: flex items-end justify-between h-10 m-inline-4">
				<span children={t().title} class=":c: text-xl font-500" />
				<ItemIconWrapper
					children={<i class=":c: i-hero:x-mark size-6" />}
					onClick={() => router.unstack()}
				/>
			</div>
			<div class=":c: flex flex-col items-stretch w-full m-t-4 m-b-10">
				<payment_form class=":c: flex flex-col rounded-4 p-inline-4 p-block-4.5 m-inline-4 relative bg-gray-800" >
					<Show when={paying()}>
						<div class=":c: absolute inset-0 bg-gray-800/90 backdrop-blur-2px rounded-inherit z-1" />
						<span
							class=":c: abs-centered c-white font-600 text-2xl z-2 ws-nowrap"
							children={[payment_langs().awaiting_payment, " ", remaining(), payment_langs().sec]}
						/>
					</Show>

					<div class=":c: flex justify-between items-center self-center min-w-fit max-w-full [&>*]:shrink-0" style={{ "width": `calc((2rem + 26px) * 2 + ${(Math.floor(Math.log10(state.value) * 1.5) + 1) + "ch"} + 5rem)` }}>
						<div
							classList={{
								":c: bg-white rounded-1 size-8 relative ptr": true,
								":c: uno-layer-v1:bg-gray-400 cursor-no-drop": state.value === MIN_ORDER_PRICE_USD
							}}
							onClick={e => {
								e.preventDefault()
								state.value > MIN_ORDER_PRICE_USD && --state.value
							}}
						>
							<svg class=":c: size-4 abs-centered" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.5 7H14.5V9H2.5V7Z" fill="black" />
							</svg>
						</div>

						<div class=":c: flex flex-nowrap min-w-fit">
							<BasicInput
								inputmode="numeric"
								name="product_data[requested_price]"
								pattern="[0-9]*"
								min={MIN_ORDER_PRICE_USD}
								max={MAX_ORDER_PRICE_USD}
								class=":c: uno-layer-v2:(b-none p-unset min-w-0 max-w-fit h-auto text-center font-700 text-16 c-white)"
								style={{ "width": (Math.floor(Math.log10(state.value)) + 1) + "ch" }}
								value={state.value}
								error={err.value}
								onInput={(e, { currentTarget: { value } } = e) => {
									let as_number = Number(value)
									if (isNaN(as_number)) {
										e.currentTarget.value = String(state.value)
										return
									}

									if (as_number > MAX_ORDER_PRICE_USD) {
										e.currentTarget.value = String(MAX_ORDER_PRICE_USD)
										state.value = MAX_ORDER_PRICE_USD
										err.value = null
										return
									}

									if (as_number === Math.clamp(as_number, MIN_ORDER_PRICE_USD, MAX_ORDER_PRICE_USD)) {
										e.currentTarget.value = String(as_number)
										state.value = as_number
										err.value = null
									}
									else {
										err.value = ""
									}
								}}
								onChange={({ currentTarget }, { value } = currentTarget) => {
									let as_number = Number(value) || MIN_ORDER_PRICE_USD
									as_number = Math.clamp(as_number, MIN_ORDER_PRICE_USD, MAX_ORDER_PRICE_USD)

									state.value = as_number
									currentTarget.value = String(as_number)
									err.value = null
								}}
							/>

							<span innerText="$" class=":c: font-700 text-16 c-white" />
						</div>

						<div class=":c: bg-white rounded-1 size-8 relative ptr"
							onClick={e => {
								e.preventDefault()
								state.value < MAX_ORDER_PRICE_USD && ++state.value
							}}
						>
							<svg class=":c: size-4 abs-centered" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M7.5 2H9.5V14H7.5V2Z" fill="black" />
								<path d="M2.5 7H14.5V9H2.5V7Z" fill="black" />
							</svg>
						</div>

					</div>

					<Transition
						enterActiveClass={enter_exit_active_class}
						exitActiveClass={enter_exit_active_class}
						enterClass={enter_exit_class}
						exitToClass={enter_exit_class}
					>
						<Show when={!state.plans_asc.isEmpty()}>
							<div class=":c: flex-center gap-2 mt-4 starting:h-0">
								{state.plans_asc.map(plan => {
									let { lower_price_limit: suggested_price } = plan

									if (suggested_price === 0)
										return

									let bonus_balance = getBonusBalance(suggested_price, true)

									return <div
										class=":c: flex-center gap-2 bg-gray-200 p-2 rounded-4px min-w-fit ptr"
										onClick={() => {
											state.value = suggested_price
											err.value = null
										}}
									>
										<span>{suggested_price} {CURRENCY_SYMBOLS.USD}</span>
										<Switch>
											<Match when={plan.esim_price === 0}>
												<div class=":c: p-1 rounded-4px text-3 font-400 bg-green-500">
													+ {bonus_balance} {CURRENCY_SYMBOLS.USD}
												</div>
											</Match>
											<Match when={bonus_balance > 0}>
												<div class=":c: p-1 rounded-4px text-3 font-400 bg-green-500">
													+ {bonus_balance} {CURRENCY_SYMBOLS.USD}
												</div>
											</Match>
										</Switch>
									</div>
								})}
							</div>
						</Show>
					</Transition>

					<span class=":c: font-300 c-gray-300 m-t-8">
						eSIM: <span
							class=":c: c-white font-600"
							innerText={getEsimPrice(state.value) === 0 ? t().for_free : "2 $"}
						/>
					</span>

					<span class=":c: font-300 c-gray-300 m-t-2">
						{t().post_order_balance_value}:{" "}
						<span class=":c: c-white font-600 flex-inline items-center"
							children={function() {
								let total = state.value - getEsimPrice(state.value)
								let bonus_balance = getBonusBalance(state.value)

								let total_formatted = formatFloatLike(total, lang()) + " " + CURRENCY_SYMBOLS.USD

								// if (bonus_balance > 0) {
								return [
									total_formatted,
									<span class=":c: c-green-500 m-l-1">+ {formatFloatLike(bonus_balance, lang())} {CURRENCY_SYMBOLS.USD}</span>,
									<GiftIcon />
								]
								// }

								// return total_formatted
							}()}
						/>
					</span>

					<DropdownSection
						Container={p => <div {...p} classList={{ ...p.classList, ":c: m-t-2": true }} />}
						Title={p => {
							let ctx = useDropdownSelectionContext()

							return <div
								class=":c: font-300 c-gray-300 flex items-center gap-2 ptr select-none"
								onClick={() => ctx.opened(v => !v)}
								ref={p.ref}
							>
								<span innerText={t().promocode} />
								<svg
									class=":c: size-3.5 transform-origin-c transition-(ease 200) [transition-property:rotate]"
									style={{ "transform": ctx.opened() ? "rotate(180deg)" : null, }}
									viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg"
								>
									<path
										d="M7.01562 7.94063L2.29249 4.08301L1.16602 5.4068L6.44226 9.71604C6.76412 9.97976 7.23106 9.98321 7.55643 9.72552L12.8327 5.56452L11.7308 4.22176L7.01562 7.94063Z"
										fill="white"
									/>
								</svg>
							</div>
						}}
						children={function() {
							let input_ref: HTMLInputElement
							return <>
								<BasicInput.Root class=":c: rounded-6px m-t-2">
									<BasicInput2
										class=":c: uno-layer-v1:(font-300 text-4)"
										placeholder="6HF78JGF"
										onInput={({ currentTarget: { value } }) => {
											err.promocode = null
											state.promocode.coupon = null
											state.promocode.value = value
										}}
										ref={ref => input_ref = ref}
									/>
									<BasicInput.IconW right x={1}
										base_padding="12px"
										iconc=":c: i-hero:x-mark bg-#4d4d4d uno-layer-v1:size-5 ptr"
										onClick={() => {
											state.promocode.value = input_ref.value = ""
											state.promocode.coupon = null
											err.promocode = null
										}}
									/>
									<BasicInput.IconW right x={0}
										base_padding="0px"
										iconc=":c: i-hero:check bg-#4d4d4d uno-layer-v1:size-5 ptr"
										wrapperc=":c: bg-#c3cad4 uno-layer-v1:w-10 rounded-l-unset"
										classList={{
											// 13CI8U2I
											":c: uno-layer-v2:bg-green-500 [&>i]:bg-black ptr": !state.promocode?.coupon && state.promocode.value?.length > 0
										}}
										onClick={checkPromocode}
									/>
								</BasicInput.Root>
							</>
						}()}
					/>
					<Show when={err.promocode}>
						<BasicInput.Error innerText={err.promocode} />
					</Show>
					<Show when={state.promocode.coupon} children={function() {
						let value: string, text: string, { coupon } = state.promocode

						if (coupon.deduction_type === "fixed_amount") {
							value = `${formatFloatLike(coupon.amount, lang())} ${CURRENCY_SYMBOLS.USD}`
						}
						else {
							value = `${formatFloatLike(coupon.amount, lang())}%`
						}

						if (coupon.action_type === "discount") {
							text = t().discount(value)
						}
						else {
							text = t().on_balance(value)
						}

						return <BasicInput.Error children={text} class=":c: uno-layer-v1:c-#d6fe1e" />
					}()} />

					<label class=":c: c-white m-t-8" children={t().email_label} />
					<BasicInput2
						class=":c: m-t-2"
						name="customer_email"
						type="email"
						placeholder="ivan.mironov@yandex.ru"
						value={state.email}
						error={err.email}
						onInput={({ currentTarget: { value } }) => {
							err.email = null
							state.email = value
						}}
					/>

					<ContinueButton
						class=":c: uno-layer-v1:(flex-center gap-1 m-t-7 m-b-0 font-600)"
						disabled={state.loading || state.submitting || !canContinue()}
						onClick={submit}
						ref={ripple}
					>
						<Switch>
							<Match when={state.loading}>
								<span children={dictionary().common.loading} />
								<Spinner class="size-5" />
							</Match>
							<Match when={state.submitting}>
								<span children={dictionary().common.loading} />
								<Spinner class="size-5" />
							</Match>
							<Match when={bank.bank}>
								<span>
									{t().order}{" "}
									<Show when={bank.bank.currency_slug !== "usd"}>~</Show>
									{formatFloatLike(state.value * bank.bank.rate, lang(), { maximumFractionDigits: 0 })} {CURRENCY_SYMBOLS[bank.variant.toUpperCase()]}
								</span>
							</Match>
						</Switch>
					</ContinueButton>

					<Show when={lang() === "ru"}>
						<div
							class=":c: flex items-center gap-2 m-t-4 select-none ptr"
							onClick={e => {
								e.preventDefault()
								bank.toggle()
							}}
						>
							<bank.Component />
							<span class=":c: select-none font-400 c-gray-200 text-sm" children={t().russian_card} />
						</div>
					</Show>

					<p class=":c: c-#808080 text-3 font-300 leading-15px [&>a]:(underline ptr)"
						children={t().consent(
							<a onClick={[router.stack, ROUTES.privacy.template]} children={t().privacy_policy} /> as any,
							<a onClick={[router.stack, ROUTES.terms.template]} children={t().terms} /> as any
						)}
					/>

					<Show when={bank.bank?.currency_slug === "rub"}>
						<p class=":c: c-#808080 text-3 leading-15px font-300" children={t().rubles_warning} />
					</Show>
				</payment_form>
			</div>
		</div>
	</MobilePage>
}

let t = langs({
	ru: {
		title: "Оформление",

		email_label: "Эл. почта",
		for_free: "бесплатно",
		discount: value => `Скидка: ${value}`,
		on_balance: value => `+ ${value} на баланс`,
		post_order_balance_value: "Зачислим на баланс",
		promocode: "Промокод",
		order: "Оплатить",
		russian_card: "Оплата картой РФ",

		privacy_policy: "Политикой конфеденциальности",
		terms: "Пользовательским соглашением",

		consent: (privacy, terms) => <>Нажимая «Оплатить», вы подтверждаете свое согласие с {privacy} и {terms}.</>,
		rubles_warning: "Конечная стоимость в рублях фиксируется в момент перехода к оплате. Оплату можно произвести в течение 2 часов.",

		errors: {
			check_promocode: "Ошибка при проверке промокода",
			invalid_promocode: "Некорректный промокод",
		},
	},
	en: {
		title: "Get eSIM",

		email_label: "Email",

		for_free: "free",
		discount: value => `Discount: ${value}`,
		on_balance: value => `+ ${value} on balance`,
		post_order_balance_value: "Balance after order",
		promocode: "Promocode",
		order: "Order",
		russian_card: "Using Russian card",

		privacy_policy: "Privacy Policy",
		terms: "Terms of use",

		consent: (privacy, terms) => <>By clicking "Order", you confirm your consent to {privacy} and {terms}.</>,
		rubles_warning: "The final cost in rubles is fixed at the time of transition to payment. Payment can be made within 2 hours.",

		errors: {
			check_promocode: "Failed to check promocode",
			invalid_promocode: "Invalid promocode",
		},
	}
})

let GiftIcon = p => <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
	<path d="M2.72 13.5363C2.72 13.7928 2.9345 14 3.2 14H7.49V8.55076H2.72V13.5363ZM8.51 14H12.8C13.0655 14 13.28 13.7928 13.28 13.5363V8.55076H8.51V14Z" fill="#D6FE1E" />
	<path d="M13.5 5H12.275C12.418 4.71875 12.5 4.4 12.5 4.0625C12.5 2.92344 11.5766 2 10.4375 2H10.3859C9.63828 2 8.94453 2.39609 8.56484 3.04063L8 4.00391L7.43516 3.04297C7.05547 2.39609 6.36172 2 5.61406 2H5.5625C4.42344 2 3.5 2.92344 3.5 4.0625C3.5 4.4 3.58203 4.71875 3.725 5H2.5C2.2345 5 2 5.27974 2 5.53627V7.56525H7.49V5H7.28047H5.5625C5.04453 5 4.625 4.58047 4.625 4.0625C4.625 3.54453 5.04453 3.125 5.5625 3.125H5.61406C5.96328 3.125 6.28906 3.31016 6.46484 3.6125L7.28047 5H7.49H8.51H8.71953L9.53516 3.6125C9.71328 3.31016 10.0367 3.125 10.3859 3.125H10.4375C10.9555 3.125 11.375 3.54453 11.375 4.0625C11.375 4.58047 10.9555 5 10.4375 5H8.71953H8.51V7.56525H14V5.53627C14 5.27974 13.7655 5 13.5 5Z" fill="#D6FE1E" />
</svg>


