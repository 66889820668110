import { onMount, createContext, createMemo, onCleanup, useContext, type ParentProps } from "solid-js"
import { createMutable } from "solid-js/store"

import { env, Platform } from "#/lib/mod"
import { COLORS } from "#/lib/appearance/colors"
import { Meta } from "#/lib/meta"

let default_mobile_viewport_meta = {
	"width": "device-width",
	"height": "device-height",
	"initial-scale": "1.0",
	"user-scalable": "no",
	"viewport-fit": "cover" as "cover" | "contain", // https://stackoverflow.com/questions/66734725/pwa-disable-or-detect-the-black-bottom-bar-on-ios
	"interactive-widget": "overlays-content" as "resizes-visual" | "resizes-content" | "overlays-content", // don't resize viewport when keyboard is active
} as const



function createLayoutContext() {
	let
		trace = tracing("LayoutContext"),
		fs_query = window.matchMedia("(display-mode: fullscreen)"),
		standalone_query = window.matchMedia("(display-mode: standalone)")

	let store = createMutable({
		viewport: {
			height: visualViewport.height,
			isFullscreen: () => standalone_query.matches || fs_query.matches,
			is_scaled: visualViewport.scale != 1,
			/**
			 * keyboard is opened?
			 *
			 * FIXME could be wrong
			 */
			is_shrinked: false,
		},
		meta: {
			viewport: {} as typeof default_mobile_viewport_meta,
		},

		is_mobile: true,

		navbar: null as HTMLElement
	})

	onMount(() => {
		document.documentElement.style.setProperty("--viewport-width", "min(100lvw, 450px)")
		document.documentElement.style.setProperty("--page-width", "min(100lvw, 450px)")
		document.documentElement.style.setProperty("--bg-light", COLORS["gray-100"])
		document.documentElement.style.setProperty("--bg-dark", COLORS["black-999"])
		store.meta.viewport = { ...default_mobile_viewport_meta }

		if (env.rt.platform === Platform.IOS) {
			trace.debug("mocking touchstart on IOS")

			window.addEventListener("touchstart", (o_ev, { touches } = o_ev) => {
				if (touches.length !== 1) return

				let [{ clientX, clientY }] = touches, { innerWidth } = window

				if (clientX >= innerWidth * 0.1 || clientX <= innerWidth * 0.9) return

				o_ev.preventDefault()

				// from most inner to most outer
				for (let node of o_ev.composedPath()) {
					if (node === document.body || node === window) continue

					function invokeHandler(node, key, event) {
						let handler = node[key], data = node[`${key}Data`]
						try {
							if (data !== undefined) handler.call(node, data, event)
							else handler.call(node, event)
						} catch (error) { trace.warn("failed to propagate event", error) }
					}

					if ("$$touchstart" in node) {
						let c_ev = Reflect.construct(o_ev.constructor, [{ ...o_ev, target: node, currentTarget: node }])
						invokeHandler(node, "$$touchstart", c_ev)
						if (c_ev.cancelBubble) break
						continue
					}

					if ("$$click" in node) {
						let c_ev = new MouseEvent("click", { clientX, clientY })
						Object.defineProperty(c_ev, "currentTarget", {
							configurable: true,
							get() { return node },
						})
						invokeHandler(node, "$$click", c_ev)
						if (c_ev.cancelBubble) break
						continue
					}
				}
			}, { passive: false })
		}

	})

	function onVvResize() {
		if (visualViewport.scale === 1) {
			// Guess if keyboard is open -_-
			store.viewport.is_shrinked = visualViewport.height < store.viewport.height
		}
		store.viewport.height = visualViewport.height
	}

	visualViewport.addEventListener("resize", onVvResize)
	onCleanup(() => {
		visualViewport.removeEventListener("resize", onVvResize)
	})

	return store
}

let LayoutContext = createContext<ReturnType<typeof createLayoutContext>>()
export function LayoutContextProvider(props: ParentProps) {
	let ctx = createLayoutContext()
	let viewport_content = createMemo(() =>
		Object.entries(ctx.meta.viewport).map(([key, value]) => `${key}=${value}`).join(", ")
	)

	return [
		<Meta name="viewport" content={viewport_content()} />,
		<LayoutContext.Provider value={ctx}>{props.children}</LayoutContext.Provider>,
	]
}

export let useLayout = () => useContext(LayoutContext)