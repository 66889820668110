import PatternImage from "#/assets/pattern.svg?raw"
import { MobilePage } from "#/common/mod"

import { ContinueButton, DropdownSection } from "#/components/mod"
import { BackGroup } from "#/components/prefabs/back-group"

import { langs } from "#/lib/mod"

import { Digit1, Digit2, Digit3 } from "./bonus.page"

import CheckIconSvg from "./check-coin.svg"
import UnicoinIcon1Svg from "./unicoin1.svg"
import UnicoinIcon2Svg from "./unicoin2.svg"


export default function BonusAnonPage() {
	let t = langs({
		ru: {
			title: "Бонусная программа",
			subtitle: "Рекомендуйте UNISIM – получайте 5% за покупки друзей",
			get_bonuses: "Получить бонусы",
			how_it_works: "Как это работает?",

			more_questions: "Остались вопросы?",

			intro_cards: {
				0: {
					t1: "5%",
					t2: "Получите вы",
					t3: "от суммы покупки eSIM",
				},
				1: {
					t1: "5%",
					t2: "Получит ваш друг",
					t3: "как бонус"
				},
				2: {
					t1: "Без",
					t2: "подводных камней",
					t3: "простые правила",
				},
			},
			instruction_cards: {
				0: {
					t1: "Зайдите в личный кабинет",
					t2: "Воспользуйтесь почтой, на которую офрмлена eSIM.",
				},
				1: {
					t1: "Поделитесь уникальной ссылкой",
					t2: "Или промокодом."
				},
				2: {
					t1: "Пользуйтесь интернетом бесплатно",
					t2: "Начислим бонусы сразу после покупки друга.",
				},
			},

			problems: {
				detailed: {
					title: "Вы сможете рассказать подробнее?",
					text: ""
				},
				limitations: {
					title: "Есть ли какие-то ограничения?",
					text: ""
				},
				payment_speed: {
					title: "Как быстро начисляются бонусы?",
					text: ""
				},
			},
		},
		en: {
			title: "Bonus program",
			subtitle: "Recommend UNISIM – get 5% from friends' purchases",
			get_bonuses: "Get bonuses",
			how_it_works: "How does it work?",
			more_questions: "More questions?",

			intro_cards: {
				0: {
					t1: "5%",
					t2: "You get",
					t3: "from eSIM purchase amount",
				},
				1: {
					t1: "5%",
					t2: "Your friend gets",
					t3: "as a bonus"
				},
				2: {
					t1: "No",
					t2: "hidden catches",
					t3: "simple rules",
				},
			},
			instruction_cards: {
				0: {
					t1: "Log in to your account",
					t2: "Use the email address you used to sign up.",
				},
				1: {
					t1: "Share your unique link",
					t2: "Or a promo code."
				},
				2: {
					t1: "Enjoy free internet",
					t2: "We'll credit your bonus balance as soon as your friend makes a purchase.",
				},
			},

			problems: {
				detailed: {
					title: "Can you tell me more details?",
					text: ""
				},
				limitations: {
					title: "Are there any limitations?",
					text: ""
				},
				payment_speed: {
					title: "How quickly are bonuses credited?",
					text: ""
				},
			},
		}
	})
	return <MobilePage class="uno-layer-v2:bg-white">

		<BackGroup />
		<div class=":c: text-2xl font-600 m-inline-4" innerText={t().title} />
		<div class=":c: m-inline-4 m-t-2" innerText={t().subtitle} />

		{[UnicoinIcon1Svg, UnicoinIcon2Svg, CheckIconSvg].map((icon, i, _arr, card_texts = t().intro_cards[i]) =>
			<div
				class=":c: w-auto h-35 m-inline-4 rounded-8px p-4 bg-#F1F3F6 flex flex-col m-t-4 relative bg-(no-repeat contain right)"
				ref={ripple}
				style={{ "background-image": `url(${icon})` }}
			>
				<div class=":c: text-2xl font-600" innerText={card_texts.t1} />
				<div class=":c: text-xl font-400 m-t-auto" innerText={card_texts.t2} />
				<div class=":c: font-300" innerText={card_texts.t3} />
			</div>
		)}

		<ContinueButton
			innerText={t().get_bonuses}
			class=":c: uno-layer-v2:(m-inline-4 m-t-8 font-600)" />

		<div
			classList={{
				":c: p-block-12 bg-(bottom no-repeat #F1F3F6) [&>*]:(relative z-1) relative overflow-hidden": true,
				":c: [&>svg]:(absolute bottom-[-25%] left-0 right-0 w-185 [--pattern-color-one:#DBE0E7] [--pattern-color-two:#F1F3F6] z-0)": true,
			}}
			innerHTML={PatternImage}
		>
			<div class=":c: text-xl font-600 m-inline-4 m-b-6" innerText={t().how_it_works} />
			{[Digit1, Digit2, Digit3,].map((img, i, _arr, texts = t().instruction_cards[i]) => <div class=":c: rounded-8px b-(1px solid #ABABAB) bg-#F1F3F6 m-inline-4 p-4 [&+&]:m-t-4 [&>svg>path]:fill-#525252">
				{img}
				<div class=":c: m-t-4.5 font-400 text-xl leading-25px" innerText={texts.t1} />
				<div class=":c: font-200 m-t-2 leading-20px" innerText={texts.t2} />
			</div>
			)}
		</div>

		<div class=":c: text-xl font-600 m-inline-4 m-t-12 m-b-6" innerText={t().more_questions} />
		{Object.keys(t().problems).map(problem_key => 
			<DropdownSection.FaqSection
				Title={p => <div>{t().problems[problem_key].title}</div>}
				children={<div innerText={t().problems[problem_key].text} class=":c: m-t-2" />} />
		)}
	</MobilePage>
}
