import { createEffect, onCleanup, onMount } from "solid-js"
import "./ripple.css"

let RIPPLE_DURATION_MS = 250
let RIPPLE_TIMEOUT_MS = 100

export function ripple(ref: HTMLElement, { duration = RIPPLE_DURATION_MS } = {}) {

	if (import.meta.env.DEV) {
		onMount(() => {
			if (getComputedStyle(ref).position != "relative") {
				console.log(getComputedStyle(ref))
				gtrace.warn("did you forget to make element", ref, "relative for ripple?")
			}
		})
	}

	// aware here! Solid use synthetic events by default
	// If you want to avoid bubble / cancel event from child element, consider using on:__
	// btw this is fixed by `e.target !== e.currentTarget`
	ref.addEventListener("pointerdown", onPointerDown)
	ref.addEventListener("pointerup", onPointerUp, { passive: true })
	ref.addEventListener("pointercancel", onPointerUp, { passive: true })
	ref.addEventListener("pointerleave", onTouchMove)

	onCleanup(() => {
		ref.removeEventListener("pointerdown", onPointerDown)
		ref.removeEventListener("pointerup", onPointerUp)
		ref.removeEventListener("pointercancel", onPointerUp)
		window.removeEventListener("touchmove", onTouchMove)
	})

	let rectangle = <div class="ripple wrapper" /> as HTMLDivElement
	// createEffect is needed to prepend AFTER solid set some attributes like innerText
	createEffect(() => {
		ref.appendChild(rectangle)
	})

	onMount(() => {
		rectangle.style.borderRadius = getComputedStyle(ref).borderRadius
	})

	let is_pointer_down = false
	let was_moved = false
	let is_drawing = false
	let requestStop: (force?: boolean) => void
	let hold: number

	function onPointerDown(e: PointerEvent) {
		if ("noripple" in e) return
		if (document.getSelection().type === "Range") return

		e.preventDefault()

		is_pointer_down = true
		was_moved = false

		if (hold) {
			clearTimeout(hold)
			hold = null
		}
		let local_hold = hold = window.setTimeout(start, RIPPLE_TIMEOUT_MS)

		function start() {
			if (local_hold !== hold) return
			if (ref.hasAttribute("disabled")) return

			if (!is_pointer_down || is_drawing || was_moved) return

			draw(e.clientX, e.clientY)
		}
	}

	function onTouchMove(e: TouchEvent) {
		was_moved = true
		if (is_drawing) {
			e.stopPropagation()
			requestStop?.()
		}
	}

	function onPointerUp(e: PointerEvent) {
		was_moved = false
		is_pointer_down = false
		requestStop?.()
	}

	function draw(x: number, y: number) {
		is_drawing = true
		let circle = <div /> as HTMLDivElement

		let started_at = Date.now()

		let stop = function(force = false) {
			if (force === true) {
				circle?.remove()
				return
			}
			let elapsedTime = Date.now() - started_at

			if (elapsedTime < duration) {
				let delay = Math.max(duration - elapsedTime, duration / 2)

				setTimeout(() => circle.classList.add("hiding"), Math.max(delay - duration / 2, 0))

				setTimeout(() => {
					circle.remove()
					is_drawing = false
				}, delay)
			}
			else {
				circle.classList.add("hiding")
				setTimeout(() => {
					circle.remove()
					is_drawing = false
				}, duration / 2)
			}
			requestStop = null
		}

		requestStop = stop

		requestAnimationFrame(() => {
			if (requestStop != stop) {
				return
			}

			let r = rectangle.getBoundingClientRect()
			circle.classList.add("rectangle")

			let click_x = x - r.left,
				click_y = y - r.top

			let radius = Math.sqrt(
				(Math.abs(click_y - r.height / 2) + r.height / 2) ** 2 +
				(Math.abs(click_x - r.width / 2) + r.width / 2) ** 2
			)

			let circle_x = click_x - radius / 2,
				circle_y = click_y - radius / 2

			circle.style.width = circle.style.height = radius + "px"
			circle.style.left = circle_x + "px"
			circle.style.top = circle_y + "px"

			rectangle.append(circle)
		})
	}
}

export function noripple(ref: HTMLElement) {
	let ignore = (e: PointerEvent) => e["noripple"] = true

	ref.addEventListener("pointerdown", ignore)
	onCleanup(() => ref.removeEventListener("pointerdown", ignore))
}
