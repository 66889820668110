import { batch } from "solid-js"
import { type ReconcileOptions, modifyMutable, reconcile } from "solid-js/store"

export function mergeMutableAdditive<T, U extends T>(
	target: T[],
	value: U[],
	options: ReconcileOptions = { merge: true }
) {
	let { key } = options
	batch(() => {
		for (let i = 0; i < value.length; i++) {
			let new_item = value[i]
			let existing_item_index = target.findIndex(item => item[key] != null && item[key] === new_item[key])
			if (existing_item_index < 0) {
				target.push(new_item)
			}
			else {
				modifyMutable(
					target[existing_item_index],
					// TODO make special recursive merger for each entitiy type
					reconcile({ ...target[existing_item_index], ...new_item }, options)
				)
			}
		}
	})
}

