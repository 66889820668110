import type { useLayout } from "#/lib/mod"

type RouteFn<T extends string | number> = (...args: (T)[]) => string

export type RouteDefinition<
	R extends string | number = string | number,
	T extends string = string,
	Fn extends RouteFn<R> = RouteFn<R>
> = {
	template: T
	resolve?: Fn
} & ViewOptions

type ViewOptions = {
	"interactive-widget"?: ReturnType<typeof useLayout>["meta"]["viewport"]["interactive-widget"]
	"stack_animation"?: "swipe" | "bottom-sheet"
}

export let ROUTES = {
	onboarding: {
		template: "/onboarding"
	},

	login: {
		template: "/login",
		stack_animation: "bottom-sheet"
	},
	main: {
		template: "/main"
	},
	balance: {
		template: "/balance",
	},
	history: {
		template: "/history"
	},

	bonus1: {
		template: "/bonus1"
	},
	bonus2: {
		template: "/bonus2"
	},
	bonus_anon: {
		template: "/bonus-program"
	},
	
	order: {
		template: "/order",
		stack_animation: "bottom-sheet"
	},
	order_completed: {
		template: "/order-completed",
	},


	help: {
		template: "/help"
	},
	compatibility: {
		template: "/compatibility"
	},
	guide: {
		template: "/guide"
	},

	more: {
		template: "/more"
	},
	terms: {
		template: "/terms"
	},
	privacy: {
		template: "/privacy"
	},
	referal_program: {
		template: "/referal"
	},
	languages: {
		template: "/languages"
	},
	contacts: {
		template: "/contacts"
	},



	index: {
		template: "/",
	},
	all: {
		template: "/*",
	},


} as const satisfies Record<string, RouteDefinition>
