import { createComponent, type JSX } from "solid-js"

import { DropdownSection } from "#/components/dropdown-section"
import { lang, Lang } from "#/lib/appearance/i18n"

export type FaqCategory = "top" | "payment" | "configuration" | "usage" | "troubleshooting" | "about"

type FaqContext = { categories: FaqCategory[] }

function faqSection(
	_categories: FaqCategory | FaqCategory[],
	variants: Record<Lang, {
		Title: JSX.Element | ((ctx: FaqContext) => JSX.Element)
		Text: JSX.Element | ((ctx: FaqContext) => JSX.Element)
	}>,
	title_height?: string
) {
	let categories = Array.isArray(_categories) ? _categories : [_categories]
	let ctx: FaqContext = { categories }

	let Component = () => (
		<DropdownSection.FaqSection
			title_height={title_height}
			Title={p => {
				let Title = () => variants[lang()].Title
				let comp = () => {
					let title = Title()
					if (typeof title === "function") {
						return createComponent(title, ctx)
					}
					return title
				}
				return comp()
			}}
			children={function() {
				let text = variants[lang()].Text
				let comp = typeof text === "function" ? createComponent(text, ctx) : text
				return comp
			}()}
		/>
	)

	return Object.assign(Component, ctx)
}


export let faqs = [
	// TOP
	faqSection(["top"], {
		ru: {
			Title: "Что такое eSIM для путешествий?",
			Text: <>
				eSIM — электронный аналог обычной сим-карты. В устройства, поддерживающие эту технологию, встроен специальный чип, на который записываются данные eSIM после покупки. Вы можете за несколько кликов <a href="/ru#buy-esim">купить и установить eSIM</a> от UNISIM в любой стране, не выходя из дома.
			</>
		},
		en: {
			Title: "What is eSIM for travel?",
			Text: <>
				eSIM is an electronic alternative to a regular SIM card. In devices that support this technology, a special chip is embedded, onto which eSIM data is recorded after purchase. You can <a href="/ru#buy-esim">buy and install an eSIM</a> from UNISIM in any country in just a few clicks, without leaving home.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "Как работает eSIM от UNISIM и ваш баланс?",
			Text: <>
				Международная eSIM от UNISIM позволяет пользоваться высокоскоростным мобильным интернетом в более 170 странах. Для этого необходимо лишь один раз установить eSIM. Каждый раз, когда вы посещаете новую страну, UNISIM автоматически подключается к интернету в течение нескольких минут. Баланс UNISIM можно потратить на мобильный интернет во всех поддерживаемых странах. Баланс тратится за каждый использованный килобайт в соответствии с <a href="/ru#prices">нашими тарифами</a>. Если вы не пользуетесь UNISIM, баланс сохраняется бессрочно до следующего использования.
			</>
		},
		en: {
			Title: "How does UNISIM eSIM and your balance work?",
			Text: <>
				The international eSIM from UNISIM allows you to use high-speed mobile internet in more than 170 countries. You only need to install the eSIM once. Every time you visit a new country, UNISIM automatically connects to the internet within a few minutes. You can spend your UNISIM balance on mobile internet in all supported countries, with charges based on each kilobyte used according to <a href="/ru#prices">our rates</a>. If you’re not using UNISIM, your balance remains indefinitely until your next use.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "В тариф входит только использование интернета?",
			Text: <>
				Да, все верно. UNISIM пока не поддерживает звонки и СМС, но мы работаем над тем, чтобы добавить их в ближайшем будущем.
			</>
		},
		en: {
			Title: "Does the plan include only internet usage?",
			Text: <>
				Yes, that’s correct. UNISIM currently does not support calls and SMS, but we are working to add them in the near future.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "Как проверить баланс?",
			Text: <>
				Проверить баланс можно через <span>личный кабинет</span>
				<a href="#">личный кабинет</a>. Для авторизации нажмите на иконку в правой верхней части сайта, введите почту и перейдите по ссылке из письма. После авторизации обновите баланс, нажав на зеленую стрелку в карточке нужной eSIM.
			</>
		},
		en: {
			Title: "How to check the balance?",
			Text: <>
				You can check your balance via your <span>personal account</span>
				<a href="#">personal account</a>. To log in, click the icon in the top right of the website, enter your email, and follow the link from the email. After logging in, refresh your balance by clicking the green arrow on the desired eSIM card.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "У вас есть личный кабинет, как он работает?",
			Text: <>
				Чтобы зайти в личный кабинет, кликните на иконку в правой верхней части сайта или по <span>ссылке</span>
				<a href="#">ссылке</a>. В открывшемся поле введите электронную почту, на которую оформлена eSIM. Через несколько секунд вам на почту придет письмо. Кликните по ссылке в письме — и вы авторизованы. Авторизация сохраняется в течение четырех недель. В личном кабинете можно проверить и пополнить баланс, посмотреть историю операций и воспользоваться реферальной программой. Благодаря реферальной программе вы можете получать бонусы за рекомендации UNISIM и пользоваться интернетом бесплатно. Подробнее о программе можно узнать по <a href="referral-promo.html">ссылке</a>.
			</>
		},
		en: {
			Title: "You have a personal account, how does it work?",
			Text: <>
				To access your personal account, click the icon in the top right corner of the site or use the <span>link</span>
				<a href="#">link</a>. Enter the email registered with your eSIM in the field that opens. Within seconds, you'll receive an email—click the link to log in. Your session will remain active for four weeks. In your personal account, you can check and top up your balance, view transaction history, and use the referral program. With the referral program, you can earn bonuses for recommending UNISIM and enjoy free internet. Learn more about the program <a href="referral-promo.html">here</a>.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "Мой телефон поддерживает eSIM?",
			Text: <>
				Для проверки совместимости устройства с eSIM наберите команду <strong>*#06#</strong> в режиме набора номера. Если на экране появился номер EID, то устройство поддерживает eSIM. Вы также можете <a href="compatibility-check.html">проверить совместимость</a> модели устройства с eSIM на нашем сайте.
			</>
		},
		en: {
			Title: "Does my phone support eSIM?",
			Text: <>
				To check if your device is eSIM compatible, dial <strong>*#06#</strong> in the dialer. If an EID number appears, your device supports eSIM. You can also <a href="compatibility-check.html">check your device’s compatibility</a> on our website.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "Основная сим-карта может быть активной?",
			Text: <>
				Да, eSIM работает как вторая сим-карта. Вам нужно настроить устройство так, чтобы мобильный интернет работал от UNISIM, а звонки и СМС от основной сим-карты. Обращаем внимание, что провайдер основной сим-карты может взимать дополнительную плату за звонки и СМС в роуминге.
			</>
		},
		en: {
			Title: "Can my main SIM card remain active?",
			Text: <>
				Yes, eSIM works as a second SIM card. You need to set up your device so that mobile internet is provided by UNISIM, while calls and SMS are handled by your primary SIM card. Please note that your primary SIM provider may charge additional fees for calls and SMS in roaming.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "UNISIM подойдет для маршрута с несколькими странами?",
			Text: <>
				UNISIM особенно хороша в поездках с несколькими странами. Интернет появится автоматически через несколько минут после включения нашей eSIM в новой стране. Для всех стран используется одна и та же сим-карта и один и тот же баланс. Оплачиваются лишь потраченные килобайты — никаких скрытых доплат и комиссий.
			</>
		},
		en: {
			Title: "Is UNISIM suitable for multi-country trips?",
			Text: <>
				UNISIM is especially useful for multi-country trips. Internet access will appear automatically within minutes of activating our eSIM in a new country. The same SIM card and balance are used for all countries. You pay only for the data used—no hidden fees or charges.
			</>
		}
	}),

	faqSection(["top"], {
		ru: {
			Title: "Не проще ли будет купить иностранную eSIM за границей?",
			Text: <>
				Покупка eSIM или обычной сим-карты за границей доставит больше трудностей: С UNISIM доступ к интернету появится, как только вы приземлитесь в стране назначения. Вы сразу сможете связаться с близкими и ответить на важные сообщения или письма. Тарифы UNISIM прозрачные и понятные. Тарифы зарубежных операторов для туристов часто включают в себя непредвиденные траты, в том числе платный выпуск сим-карты. Для покупки UNISIM не нужно стоять в очереди, предоставлять паспортные данные и взаимодействовать с персоналом, который не разговаривает на вашем родном языке.
			</>
		},
		en: {
			Title: "Isn't it easier to buy a foreign eSIM abroad?",
			Text: <>
				Buying an eSIM or regular SIM card abroad can be more challenging: With UNISIM, internet access is available as soon as you land at your destination. You can immediately contact loved ones and respond to important messages or emails. UNISIM rates are transparent and clear. Foreign operator rates for tourists often come with unexpected costs, including paid SIM issuance. With UNISIM, there's no need to wait in line, provide passport information, or interact with staff who don’t speak your native language.
			</>
		}
	}),


	// PAYMENT
	faqSection(["payment"], {
		ru: {
			Title: "Как быстро и каким способом доставляется eSIM после покупки?",
			Text: <>
				eSIM выпускается моментально. Данные для настройки поступят на вашу электронную почту сразу же после оплаты. Вы сможете установить eSIM за несколько минут, не выходя из дома.
			</>
		},
		en: {
			Title: "How quickly and by what method is the eSIM delivered after purchase?",
			Text: <>
				eSIM is issued instantly. Setup data will be sent to your email immediately after payment. You can install the eSIM in a few minutes without leaving home.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Как получить бонусный баланс при покупке UNISIM?",
			Text: <>
				При покупке UNISIM есть несколько уровней бонусов:<br />
				<br />
				1. При покупке от 15 долларов выпуск eSIM становится бесплатным.<br />
				2. При покупке от 40 долларов начисляется 5% бонусного баланса.<br />
				3. При покупке от 100 долларов начисляется 10% бонусного баланса.
			</>
		},
		en: {
			Title: "How to get bonus balance when purchasing UNISIM?",
			Text: <>
				There are several bonus levels for purchasing UNISIM:<br />
				<br />
				1. For purchases over $15, eSIM issuance is free.<br />
				2. For purchases over $40, 5% bonus balance is credited.<br />
				3. For purchases over $100, 10% bonus balance is credited.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Почему указывается стоимость в долларах, а оплата производится в рублях?",
			Text: <>
				Баланс UNISIM, тарифы, а также стоимость выпуска eSIM исчисляются в долларах. В связи с этим на этапе покупки указывается сумма в долларах, а на этапе оплаты производится автоматический пересчет в рубли, если выбрана оплата картой РФ. Для зарубежных карт оплата производится в долларах.
			</>
		},
		en: {
			Title: "Why is the price in dollars but payment made in rubles?",
			Text: <>
				UNISIM balance, rates, and eSIM issuance costs are denominated in dollars. Therefore, the purchase amount is shown in dollars, and at the payment stage, it is automatically converted to rubles if payment by Russian card is chosen. For foreign cards, payment is made in dollars.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Почему финальная сумма оплаты может отличаться от той, которая отображалась на сайте?",
			Text: <>
				Баланс UNISIM, тарифы, а также стоимость выпуска eSIM исчисляются в долларах. При оплате картой РФ происходит пересчет в рубли по текущему обменному курсу. Так как курс может меняться в процессе изучения вами информации на сайте, финальная сумма формируется в момент перехода к оплате.
			</>
		},
		en: {
			Title: "Why can the final payment amount differ from what was displayed on the site?",
			Text: <>
				UNISIM balance, rates, and eSIM issuance costs are in dollars. When paying by Russian card, it is converted to rubles at the current exchange rate. Since the rate may change while you browse, the final amount is determined at the time of payment.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Нужна ли верификация личности (KYC) для покупки?",
			Text: <>
				Нет, для покупки нашей eSIM нужен лишь адрес электронной почты.
			</>
		},
		en: {
			Title: "Is identity verification (KYC) required for purchase?",
			Text: <>
				No, only an email address is needed to purchase our eSIM.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Как можно оплатить выпуск eSIM и пополнить баланс?",
			Text: <>
				UNISIM можно оплатить как картами, выпущенными в РФ, так и зарубежными картами.<br /><br />
				Для карт РФ поддерживаются все платежные системы (MasterCard, VISA, МИР, UnionPay). Доступна оплата по СБП, Яндекс Pay, Tinkoff Pay.<br /><br />
				Для зарубежных карт поддерживается большинство платежных систем (MasterCard, Visa, AMEX, UnionPay, Maestro и прочие). Доступна оплата с помощью Apple Pay, Google Pay и прочих инструментов.
			</>
		},
		en: {
			Title: "How can I pay for eSIM issuance and top up balance?",
			Text: <>
				UNISIM can be paid with both Russian-issued and foreign-issued cards.<br /><br />
				For Russian cards, all payment systems are supported (MasterCard, VISA, MIR, UnionPay). Payment is available via SBP, Yandex Pay, and Tinkoff Pay.<br /><br />
				For foreign cards, most payment systems are supported (MasterCard, Visa, AMEX, UnionPay, Maestro, etc.). Payment is also available via Apple Pay, Google Pay, and other tools.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Насколько безопасно вводить мои платежные данные?",
			Text: <>
				UNISIM не получает ваших платежных данных. Сбором и обработкой платежных данных занимаются эквайеры, которые принимают платежи.
			</>
		},
		en: {
			Title: "How secure is it to enter my payment details?",
			Text: <>
				UNISIM does not receive your payment details. Payment processors responsible for accepting payments handle the collection and processing of payment data.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Какая у вас политика возвратов?",
			Text: <>
				Возврат средств за покупку eSIM (стоимость выпуска eSIM и сумма первичного пополнения) производится в одном из случаев:<br /><br />
				- Мобильное устройство не поддерживает eSIM.<br />
				- Устройство может работать только с одним провайдером связи.<br />
				- Прочие технические сложности, по причине которых eSIM не может быть установлена.<br /><br />
				Если eSIM была успешно установлена на устройство, возврат средств не осуществляется.
			</>
		},
		en: {
			Title: "What is your refund policy?",
			Text: <>
				Refunds for eSIM purchases (issuance cost and initial top-up amount) are available in certain cases:<br /><br />
				- The mobile device does not support eSIM.<br />
				- The device can only operate with one service provider.<br />
				- Other technical issues preventing eSIM installation.<br /><br />
				If the eSIM has been successfully installed on the device, refunds are not provided.
			</>
		}
	}),

	faqSection(["payment"], {
		ru: {
			Title: "Как получить чек после оплаты?",
			Text: <>
				Чек будет отправлен на указанную электронную почту сразу после оплаты.
			</>
		},
		en: {
			Title: "How can I receive a receipt after payment?",
			Text: <>
				A receipt will be sent to the provided email address immediately after payment.
			</>
		}
	}),


	// CONFIGURATION
	faqSection(["configuration"], {
		ru: {
			Title: "Мое устройство поддерживает eSIM?",
			Text: <>
				Для проверки совместимости устройства с eSIM наберите команду <strong>*#06#</strong> в режиме набора номера. Если на экране появился номер EID, то устройство поддерживает eSIM. Вы также можете <a href="compatibility-check.html">проверить совместимость</a> модели устройства с eSIM на нашем сайте.
			</>
		},
		en: {
			Title: "Does my device support eSIM?",
			Text: <>
				To check if your device is compatible with eSIM, dial <strong>*#06#</strong> in the dialer. If an EID number appears on the screen, your device supports eSIM. You can also <a href="compatibility-check.html">check your device's compatibility</a> on our website.
			</>
		}
	}),

	faqSection(["configuration"], {
		ru: {
			Title: "Как установить eSIM на устройствах Apple (iPhone, iPad)?",
			Text: <>
				Установка eSIM на устройства Apple занимает пару минут и осуществляется автоматически с помощью сканирования QR-кода или вручную. Подробная инструкция по установке доступна <a href="guide.html">по ссылке</a>.
			</>
		},
		en: {
			Title: "How to install eSIM on Apple devices (iPhone, iPad)?",
			Text: <>
				Installing eSIM on Apple devices takes a few minutes and can be done automatically by scanning a QR code or manually. A detailed installation guide is available <a href="guide.html">here</a>.
			</>
		}
	}),

	faqSection(["configuration"], {
		ru: {
			Title: "Как установить eSIM на Android (Google Pixel, Samsung Galaxy, Huawei и пр.)?",
			Text: <>
				Установка eSIM на устройства Android занимает пару минут и осуществляется автоматически с помощью сканирования QR-кода или вручную. Подробная инструкция по установке доступна <a href="guide.html">по ссылке</a>.
			</>
		},
		en: {
			Title: "How to install eSIM on Android (Google Pixel, Samsung Galaxy, Huawei, etc.)?",
			Text: <>
				Installing eSIM on Android devices takes a few minutes and can be done automatically by scanning a QR code or manually. A detailed installation guide is available <a href="guide.html">here</a>.
			</>
		}
	}),

	faqSection(["configuration"], {
		ru: {
			Title: "Когда лучше установить eSIM?",
			Text: <>
				Мы рекомендуем устанавливать UNISIM сразу после покупки. Так вам не придется искать письмо с установочными данными во время поездки.
			</>
		},
		en: {
			Title: "When is the best time to install eSIM?",
			Text: <>
				We recommend installing UNISIM right after purchase. This way, you won’t need to look for the installation email while traveling.
			</>
		}
	}),

	faqSection(["usage", "configuration"], {
		ru: {
			Title: "Можно ли перенести UNISIM с одного устройства на другое?",
			Text: <>
				Переустановить eSIM на другое устройство нельзя. При смене устройства потребуется <a href="/ru#buy-esim">приобрести новую eSIM</a>.<br /><br />Мы бесплатно перенесем весь оставшийся баланс с вашей старой eSIM на новую. Для этого достаточно обратиться в <a href="contacts.html">службу поддержки</a>.
			</>
		},
		en: {
			Title: "Can I transfer UNISIM from one device to another?",
			Text: <>
				eSIM cannot be reinstalled on another device. When changing devices, you will need to <a href="/ru#buy-esim">purchase a new eSIM</a>.<br /><br />We will transfer your remaining balance from the old eSIM to the new one for free. Just contact our <a href="contacts.html">support team</a> for assistance.
			</>
		}
	}),


	// USAGE
	faqSection(["usage"], {
		ru: {
			Title: "Как переключать мобильный интернет между основной сим-картой и UNISIM?",
			Text: <>
				Каждый раз, когда вы отправляетесь в путешествие, необходимо переключить мобильный интернет с основной сим-карты на UNISIM.
				<br /><br />
				<b>На iOS:</b> Зайдите в Настройки → Сотовая связь → Сотовые данные → Выберите UNISIM. Чтобы избежать дополнительных затрат в роуминге, не включайте «Переключение сотовых данных».<br />
				<img src="https://cdn.prod.website-files.com/657859788d975c88b424eb13/65ed991d797e1a05199a93e8_Apple-img.jpg" />
				<br />
				<b>На Android:</b> Зайдите в настройки → Сотовая связь → Интернет → Выберите UNISIM.
				<img src="https://cdn.prod.website-files.com/657859788d975c88b424eb13/65ed991d6a8f8ff05f131b25_Android-img.jpg" />
			</>
		},
		en: {
			Title: "How to switch mobile internet between the main SIM card and UNISIM?",
			Text: <>
				Each time you go on a trip, you need to switch mobile internet from the main SIM card to UNISIM.<br /><br />
				<b>On iOS:</b> Go to Settings → Cellular → Cellular Data → Select UNISIM. To avoid additional roaming costs, do not enable “Cellular Data Switching.”
				<br />
				<img src="https://cdn.prod.website-files.com/657859788d975c88b424eb13/65ed991d797e1a05199a93e8_Apple-img.jpg" />
				<br />
				<b>On Android:</b> Go to Settings → Cellular → Internet → Select UNISIM.
				<br />
				<img src="https://cdn.prod.website-files.com/657859788d975c88b424eb13/65ed991d797e1a05199a93e8_Apple-img.jpg" />
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Как избежать больших трат при использовании UNISIM?",
			Text: <>
				Чтобы минимизировать траты на мобильный интернет, мы советуем:<br /><br />
				- Подключаться к Wi-Fi, когда это возможно.<br />
				- Использовать оффлайн-режим в приложениях (например, заранее скачать музыку или загрузить карты).<br />
				- Ограничить использование социальных сетей и просмотр онлайн видео.<br />
				- Включить режим пониженного использования мобильного интернета в настройках устройства.<br />
				- Отключить автоматические обновления приложений в фоновом режиме в настройках устройства.
			</>
		},
		en: {
			Title: "How to avoid high expenses while using UNISIM?",
			Text: <>
				To minimize mobile internet costs, we recommend:<br /><br />
				- Connecting to Wi-Fi whenever possible.<br />
				- Using offline modes in apps (for example, download music or maps in advance).<br />
				- Limiting social media use and online video streaming.<br />
				- Enabling low data usage mode on your device.<br />
				- Disabling automatic app updates in the background in your device’s settings.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Как проверить баланс?",
			Text: <>
				Проверить баланс можно через <a href="#" class="link-in-text">личный кабинет</a>. Для авторизации нажмите на иконку в правой верхней части сайта, введите почту и перейдите по ссылке из письма. После авторизации обновите баланс, нажав на зеленую стрелку в карточке нужной eSIM.
			</>
		},
		en: {
			Title: "How to check balance?",
			Text: <>
				You can check your balance via <a href="#" class="link-in-text">personal account</a>. To log in, click the icon in the top-right corner of the site, enter your email, and follow the link in the email. Once logged in, refresh your balance by clicking the green arrow on the desired eSIM card.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "У вас есть личный кабинет, как он работает?",
			Text: <>
				Чтобы зайти в личный кабинет, кликните на иконку в правой верхней части сайта или по <a href="#" class="link-in-text">ссылке</a>. В открывшемся поле введите электронную почту, на которую оформлена eSIM. Через несколько секунд вам на почту придет письмо. Кликните по ссылке в письме — и вы авторизованы. Авторизация сохраняется в течение четырех недель.<br /><br />
				В личном кабинете можно проверить и пополнить баланс, посмотреть историю операций и воспользоваться реферальной программой.<br /><br />
				Благодаря реферальной программе вы можете получать бонусы за рекомендации UNISIM и пользоваться интернетом бесплатно. Подробнее о программе можно узнать по <a href="referral-promo.html">ссылке</a>.
			</>
		},
		en: {
			Title: "Do you have a personal account, and how does it work?",
			Text: <>
				To access the personal account, click the icon in the top-right corner of the site or <a href="#" class="link-in-text">here</a>. Enter the email linked to your eSIM. You will receive an email with a link to log in, which stays valid for four weeks.<br /><br />
				In the personal account, you can check and top up your balance, view transaction history, and access the referral program.<br /><br />
				With the referral program, you can earn bonuses for recommending UNISIM, allowing free internet access. Learn more about the program <a href="referral-promo.html">here</a>.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Что такое IMSI?",
			Text: <>
				IMSI — это уникальный код вашей eSIM. Его обязательно следует записать или сохранить письмо с установочными данными. IMSI нужен, чтобы пополнить баланс UNISIM и обратиться в службу поддержки.
			</>
		},
		en: {
			Title: "What is IMSI?",
			Text: <>
				IMSI is a unique code for your eSIM. You should record it or keep the installation email. The IMSI is needed to top up your UNISIM balance and contact customer support.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Как узнать свой IMSI?",
			Text: <>
				Номер IMSI можно узнать в <a href="#" class="link-in-text">личном кабинете</a> или посмотреть в письме с установочными данными eSIM.
			</>
		},
		en: {
			Title: "How to find your IMSI?",
			Text: <>
				You can find the IMSI number in your <a href="#" class="link-in-text">personal account</a> or in the eSIM installation email.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Как пополнить баланс?",
			Text: <>
				Перейдите на <a href="balance.html">страницу пополнения</a> и введите IMSI и сумму. IMSI подставится автоматически, если перейти на страницу пополнения из <a href="#" class="link-in-text">личного кабинета</a>.
			</>
		},
		en: {
			Title: "How to top up balance?",
			Text: <>
				Go to the <a href="balance.html">top-up page</a> and enter the IMSI and amount. The IMSI will auto-fill if you access the page from your <a href="#" class="link-in-text">personal account</a>.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Что будет с балансом, когда я вернусь из поездки?",
			Text: <>
				Баланс UNISIM не сгорает. Если вы не пользуетесь UNISIM, баланс сохраняется бессрочно до следующего использования.
			</>
		},
		en: {
			Title: "What happens to the balance when I return from a trip?",
			Text: <>
				Your UNISIM balance does not expire. If you are not using UNISIM, the balance remains indefinitely until next use.
			</>
		}
	}),
	faqSection(["usage"], {
		ru: {
			Title: "Можно ли перенести UNISIM с одного устройства на другое?",
			Text: <>
				Переустановить eSIM на другое устройство нельзя. При смене устройства потребуется <a href="/ru#buy-esim">приобрести новую eSIM.</a><br /><br />
				Мы бесплатно перенесем весь оставшийся баланс с вашей старой eSIM на новую. Для этого достаточно обратиться в <a href="contacts.html">службу поддержки</a>.
			</>
		},
		en: {
			Title: "Can I transfer UNISIM to another device?",
			Text: <>
				You cannot reinstall eSIM on another device.You will need to<a href="/en#buy-esim">purchase a new eSIM</a> when switching devices.< br /> <br />
				We will transfer your remaining balance from your old eSIM to the new one for free.Just contact < a href="contacts.html" > customer support</a >.
			</>
		}
	}),

	// TROUBLESHOOTING
	faqSection(["troubleshooting"], {
		ru: {
			Title: "UNISIM не может автоматически найти сеть. Что делать?",
			Text: <>
				Если UNISIM не подключилась к сети автоматически в течение 5 минут, вы можете выбрать сеть вручную. Рекомендуем предварительно ознакомиться с тарифами в нужной стране.
				<br /><br />
				<b>Как выбрать сеть вручную на iOS:</b>
				Зайдите в Настройки → Сотовая связь → Выберите UNISIM в списке сим-карт → Выбор сети → Отключите «Автоматически». Подождите пару минут и выберите нужную сеть.<br /><br />
				<b>Как выбрать сеть вручную на Android:</b> Зайдите в Настройки → Сотовая связь → Выберите UNISIM в списке сим-карт → Отключите «Автоматически». Подождите пару минут и выберите нужную сеть.
			</>
		},
		en: {
			Title: "UNISIM cannot automatically find a network. What should I do?",
			Text: <>
				If UNISIM did not connect to the network automatically within 5 minutes, you can manually select a network. We recommend checking the rates for the country in advance.
				<br /><br />
				<b>How to select a network manually on iOS:</b>
				Go to Settings → Cellular → Select UNISIM from the list of SIM cards → Network Selection → Turn off "Automatic". Wait a couple of minutes and select the desired network.
				<br /><br />
				<b>How to select a network manually on Android:</b> Go to Settings → Cellular → Select UNISIM from the list of SIM cards → Turn off "Automatic". Wait a couple of minutes and select the desired network.
			</>
		}
	}),
	faqSection(["troubleshooting"], {
		ru: {
			Title: "Пополнил не ту eSIM. Я потерял эти деньги?",
			Text: <>
				В таком случае нужно сразу обратиться <a href="contacts.html">в поддержку</a>. Мы вручную зачислим средства на баланс вашей eSIM.<br /><br />
				Рекомендуем переходить на страницу пополнения из <span class="link-in-text no-login">личного кабинета</span>
				<a href="#" class="link-in-text login">личного кабинета</a>, чтобы IMSI подставлялся автоматически.
			</>
		},
		en: {
			Title: "I topped up the wrong eSIM. Did I lose that money?",
			Text: <>
				In this case, you should immediately contact <a href="contacts.html">support</a>. We will manually credit the funds to your eSIM balance.<br /><br />
				We recommend going to the top-up page from <span class="link-in-text no-login">your personal account</span>
				<a href="#" class="link-in-text login">personal account</a>, so that the IMSI is automatically filled in.
			</>
		}
	}),
	faqSection(["troubleshooting"], {
		ru: {
			Title: "Установил eSIM, но интернет не работает. Что делать?",
			Text: <>
				Проверьте:<br />
				- Включен ли в настройках eSIM роуминг данных<br />
				- Включены ли сотовые данные в телефоне<br />
				- Есть ли на балансе средства<br /><br />
				Если ничего не помогло, обратитесь в <a href="contacts.html">службу поддержки</a>. Мы с радостью вам поможем.
			</>
		},
		en: {
			Title: "I installed eSIM, but the internet isn’t working. What should I do?",
			Text: <>
				Check:<br />
				- Is data roaming enabled in the eSIM settings?<br />
				- Are mobile data turned on on your phone?<br />
				- Do you have enough balance?<br /><br />
				If none of these worked, please contact <a href="contacts.html">customer support</a>. We will be happy to assist you.
			</>
		}
	}),
	faqSection(["troubleshooting"], {
		ru: {
			Title: "Не сканируется QR-код. Почему не устанавливается eSIM?",
			Text: <>
				Если вы столкнулись с ошибкой «Сбой активации eSIM» или «Не удается добавить eSIM», значит ваша eSIM уже установлена. Проверьте список сим-карт в настройках устройства (UNISIM может быть названа системой как-то иначе). <br /><br />
				Если это не помогло, обратитесь в <a href="contacts.html">службу поддержки</a>. Мы с радостью вам поможем.
			</>
		},
		en: {
			Title: "QR code is not scanning. Why isn’t eSIM installing?",
			Text: <>
				If you encounter the error "eSIM activation failed" or "Unable to add eSIM", it means your eSIM is already installed. Check the list of SIM cards in your device’s settings (UNISIM might be listed under a different name). <br /><br />
				If this doesn't help, please contact <a href="contacts.html">customer support</a>. We will be happy to assist you.
			</>
		}
	}),


	// ABOUT
	faqSection(["about"], {
		ru: {
			Title: "Что такое eSIM?",
			Text: <>
				eSIM — электронный аналог обычной сим-карты. В устройства, поддерживающие эту технологию, встроен специальный чип, на который записываются данные eSIM после покупки. Вы можете за несколько кликов <a href="/ru#buy-esim" class="link-in-text">купить и установить eSIM</a> от UNISIM в любой стране, не выходя из дома.
			</>
		},
		en: {
			Title: "What is eSIM?",
			Text: <>
				eSIM is a digital alternative to a regular SIM card. Devices that support this technology have a built-in chip, where eSIM data is stored after purchase. You can <a href="/en#buy-esim" class="link-in-text">buy and install UNISIM eSIM</a> from anywhere, without leaving home.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Что такое UNISIM?",
			Text: <>
				UNISIM предлагает удобную, выгодную и надежную eSIM для путешествий по более 170 странам. Наши клиенты наслаждаются путешествием, не переживая за качество связи.
			</>
		},
		en: {
			Title: "What is UNISIM?",
			Text: <>
				UNISIM provides a convenient, affordable, and reliable eSIM for travel across more than 170 countries. Our customers enjoy their travels without worrying about connection quality.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Как работает UNISIM?",
			Text: <>
				Международная eSIM от UNISIM позволяет пользоваться высокоскоростным мобильным интернетом в более 170 странах. Для этого необходимо лишь один раз установить eSIM.<br /><br />
				Каждый раз, когда вы посещаете новую страну, UNISIM автоматически подключается к интернету в течение нескольких минут.<br /><br />
				Баланс UNISIM можно потратить на мобильный интернет во всех поддерживаемых странах. Баланс тратится за каждый использованный килобайт в соответствии с нашими тарифами. Если вы не пользуетесь UNISIM, баланс сохраняется бессрочно до следующего использования.
			</>
		},
		en: {
			Title: "How does UNISIM work?",
			Text: <>
				The international eSIM from UNISIM allows you to use high-speed mobile internet in more than 170 countries. You only need to install the eSIM once.<br /><br />
				Every time you visit a new country, UNISIM automatically connects to the internet within a few minutes.<br /><br />
				You can use your UNISIM balance for mobile internet in all supported countries. The balance is used based on each kilobyte consumed, according to our rates. If you are not using UNISIM, the balance remains indefinitely until next use.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Кому подойдет UNISIM?",
			Text: <>
				Наша eSIM универсальна и подойдет для путешествий, командировок и удаленной работы.
			</>
		},
		en: {
			Title: "Who is UNISIM for?",
			Text: <>
				Our eSIM is versatile and suitable for travel, business trips, and remote work.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "В каких странах работает UNISIM?",
			Text: <>
				UNISIM работает в более 170 странах. С полным списком стран и подробными ценами вы можете ознакомиться в нашем разделе тарифов.
			</>
		},
		en: {
			Title: "Which countries does UNISIM work in?",
			Text: <>
				UNISIM operates in more than 170 countries. You can view the full list of countries and detailed pricing in our rates section.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Есть скрытые платежи и другие «подводные камни»?",
			Text: <>
				У UNISIM нет абонентской платы и прочих скрытых платежей за обслуживание. Средства, внесенные на баланс, расходуются только для оплаты использованного мобильного интернета.
			</>
		},
		en: {
			Title: "Are there any hidden fees or other 'fine print'?",
			Text: <>
				UNISIM has no subscription fees or hidden service charges. The funds added to your balance are only used to pay for consumed mobile internet.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Основная сим-карта может быть активной?",
			Text: <>
				Да, eSIM работает как вторая сим-карта. Вам нужно настроить устройство так, чтобы мобильный интернет работал от UNISIM, а звонки и СМС от основной сим-карты.<br /><br />
				Обращаем внимание, что провайдер основной сим-карты может взимать дополнительную плату за звонки и СМС в роуминге.
			</>
		},
		en: {
			Title: "Can my main SIM card remain active?",
			Text: <>
				Yes, eSIM works as a second SIM card. You need to configure your device to use mobile internet from UNISIM while keeping calls and SMS on your main SIM card.<br /><br />
				Please note that your main SIM provider may charge extra for calls and SMS in roaming.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "UNISIM подойдет для маршрута с несколькими странами?",
			Text: <>
				UNISIM особенно хороша в поездках, включающих несколько стран. Интернет появится автоматически через несколько минут после включения нашей eSIM в новой стране. Для всех стран используется одна и та же сим-карта и один и тот же баланс. Оплачиваются лишь потраченные килобайты — никаких скрытых доплат и комиссий.
			</>
		},
		en: {
			Title: "Is UNISIM suitable for a multi-country route?",
			Text: <>
				UNISIM is especially convenient for trips that include multiple countries. Internet will connect automatically within a few minutes of activating our eSIM in a new country. The same SIM card and balance are used across all countries, and only the data you use is charged—no hidden fees or commissions.
			</>
		}
	}),
	faqSection(["about"], {
		ru: {
			Title: "Не проще ли будет купить иностранную eSIM за границей?",
			Text: <>
				Покупка eSIM или обычной сим-карты за границей доставит больше трудностей:<br />‍<br />- С UNISIM доступ к интернету появится, как только вы приземлитесь в стране назначения. Вы сразу сможете связаться с близкими и ответить на важные сообщения или письма.<br />- Тарифы UNISIM прозрачные и понятные. Тарифы зарубежных операторов для туристов часто включают в себя непредвиденные траты, в том числе платный выпуск сим-карты.<br />- Для покупки UNISIM не нужно стоять в очереди, предоставлять паспортные данные и взаимодействовать с персоналом, который не разговаривает на вашем родном языке.
			</>
		},
		en: {
			Title: "Isn’t it easier to buy a local eSIM abroad?",
			Text: <>
				Buying an eSIM or regular SIM card abroad can involve more hassle:<br />‍<br />- With UNISIM, internet access will be available as soon as you land in your destination. You can immediately connect with loved ones or respond to important messages.<br />- UNISIM's rates are transparent and straightforward. Rates from foreign providers often include unforeseen charges, including paid SIM card issuance.<br />- Purchasing UNISIM requires no queuing, passport details, or interaction with staff who may not speak your language.
			</>
		}
	}),
]
