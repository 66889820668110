// tailwindshades.com

export let COLORS = {
	"black": "#000000",

	"gray-100": "#F1F3F6", // Background/Light
	"gray-200": "#C3CAD4", // Text & Borders/Blue
	"gray-300": "#C3CAD4", // Text & Borders/Blue


	"gray-800": "#2C2C2C",

	"green-400": "#BAE300",
	"green-500": "#D6FE1E", // Main accent

	"red-500": "#FF0733"
} as const
