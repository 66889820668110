/* @refresh reload */

import "uno.css"
import "#/assets/styles/index.css"
import "#/assets/fonts/fonts.css"

import "./lib/std"
import "./lib/globals"

import { lazy } from "solid-js"
import { render } from "solid-js/web"
import { Toaster } from "solid-toast"

import { env } from "#/lib/mod"
import { MetaProvider, Title } from "#/lib/meta"
import { AuthContextProvider } from "#/lib/auth"
import { I18n } from "#/lib/appearance/i18n"

// Contexts
import { LayoutContextProvider } from "#/layout.context"
import { CacheContextProvider } from "#/lib/cache/cache.context"

import { MOBILE_NAVBAR_HEIGHT } from "./common/mod"

void function bootstrap() {
	document.documentElement.classList.add("light")

	render(App, document.body)

	// TODO: https://bugzilla.mozilla.org/show_bug.cgi?id=1360870#c5
	if (!env.rt.is_firefox) {
		let sw_url = "/sw." + (import.meta.env.DEV ? "ts" : "js")
		navigator.serviceWorker.register(sw_url, { type: "module", scope: "/" })
	}
}()

function Navigator() {
	let MobileNavigator = lazy(() => import("./common/layout/navigator.mobile"))
	return <MobileNavigator />
}

function App() {
	let trace = tracing("App")

	// if (!import.meta.env.DEV) {
	// 	onMount(() => {
	// 		import("./telemetry").then(({ replay }) => {
	// 			requestIdleCallback(() => {
	// 				replay.start()
	// 					.then(() => trace.info("started replay recording"))
	// 					.catch((e) => {
	// 						trace.error("replay error", e)
	// 					})
	// 			})
	// 		})
	// 	})
	// }

	return [
		// if problem with cycling dependencies would appear, consider to use single context that will hold
		// event emitters / channels for each context. it should be called actor model idk
		<MetaProvider>
			<Title innerText={import.meta.env.DEV ? "[локал] UNISIM" : "UNISIM"} />
			<I18n>
				<LayoutContextProvider>
					<CacheContextProvider>
						<AuthContextProvider>
							<Navigator />
						</AuthContextProvider>
					</CacheContextProvider>
				</LayoutContextProvider>
			</I18n>
		</MetaProvider>,
		<Toaster
			toastOptions={{
				position: "bottom-center",
				duration: 1550
			}}
			containerStyle={{
				bottom: `calc(10px + ${MOBILE_NAVBAR_HEIGHT})`,
			}}
		/>,
	]
}
