import { Match, Switch } from "solid-js"
import { createMutable } from "solid-js/store"
import { toast } from "solid-toast"

import { MobilePage, MainHeader, dictionary } from "#/common/mod"
import { BackGroup, BasicInput, ContinueButton, Dropdown, Spinner, useDropdownContext } from "#/components/mod"
import { ROUTES, api, errorHandled, lang, langs, useAuth, useCache, useRouter } from "#/lib/mod"

export function BonusPageMain() {
	return <MobilePage id="bonus-main">
		<MainHeader class=":c: m-b-5" />
		<BonusPageInner />
	</MobilePage>
}

export function BonusPageStacked() {
	return <MobilePage id="bonus-stacked" class="uno-layer-v2:(p-b-10)">
		<BackGroup class=":c: m-b-5" />
		<BonusPageInner />
	</MobilePage>
}

function BonusPageInner() {
	let auth = useAuth(),
		cache = useCache(),
		router = useRouter(),
		trace = tracing("BonusPage")

	let state = createMutable({
		value: null as number,
		esim_id: null as string,
		get esim() {
			if (this.esim_id == null) return

			return cache.resolve("esims", this.esim_id)
		},

		loading_esims: false,
		submitting: false,
	})

	let err = createMutable({
		value: null as string,
		imsi: null as string,
	})

	let check = {
		imsi() {
			err.imsi = function() {
				if (!state.esim) {
					return "Выберите eSIM"
				}
				if (state.esim?.imsi.length !== 15)
					return "Это не похоже на валидный IMSI"

				return null
			}()
		},
		value_usd() {
			err.value = function() {
				if (state.value > auth.user.balance)
					return `Не хватает баланса`

				return null
			}()
		},
	}

	let canContinue = () => !Object.values(err).filter(Boolean).length

	queueMicrotask(load)

	async function load() {
		if (!cache.entities.esims.length) {
			state.loading_esims = true

			let response = await api.getEsims({})
				.finally(() => state.loading_esims = false)

			if (errorHandled(response, "[загрузка карт]"))
				return

			cache.update("esims", response.results)
		}
	}

	async function submit() {
		state.submitting = true

		for (let fn_key in check) check[fn_key]()

		if (!canContinue()) {
			state.submitting = false
			return
		}

		let result = await api.createOrder({
			product: "esim_balance",

			imsi: state.esim.imsi,
			balance_amount_to_use: state.value,
			bank_id: "stripe",

			user_lang: lang(),
		})

		state.submitting = false

		if (errorHandled(result, "[Списание бонусов]"))
			return

		auth.refetchUser()
	}

	let getShareUrl = () =>
		`ref.unisim.net/${auth.user.referral_code}`
	let getShareDescription = () =>
		`Купи eSIM для путешествий по 170+ странам с интернетом от 1 $/гб по ссылке и получи бонус 5% на баланс: ${getShareUrl()}`

	let tg = () => `https://telegram.me/share/?url=${getShareDescription()}`
	let ws = () => `https://api.whatsapp.com/send?text=${getShareDescription()}`
	let vk = () => `http://vk.com/share.php?url=${getShareUrl()}&comment=${getShareDescription()}`

	let email = () => `mailto:?body=${getShareDescription()}`

	let hint: HTMLDivElement = null,
		card: HTMLDivElement = null

	return <>
		<div
			class=":c: h-auto bg-(#D6FE1E no-repeat [position:100%_0%]) rounded-8px p-4 m-inline-4"
			// style={{ "background-image": `url(${UsdCoinUrl})` }}
			ref={ref => card = ref}
		>
			<div class=":c: flex items-center gap-1.5 c-#4D4D4D">
				<span class=":c: font-300" innerText={t().title} />
				<i class=":c: i-local:info size-4" onClick={({ currentTarget }) => {
					function close(e?: MouseEvent) {
						if (e && e.composedPath().some(x => x === hint)) {
							return
						}
						hint.remove()
						document.removeEventListener("click", close)
					}
					if (hint) try { close() } catch { }

					let pos = currentTarget.getBoundingClientRect()
					hint = <div
						class=":c: absolute flex-center bg-white c-#4d4d4d text-3.5 font-300 p-3 min-w-170px rounded-8px"
						style={{ left: pos.left + "px", top: pos.top + "px", "box-shadow": "4px 9px 20px 0 #757e9f40" }}
						innerText={t().hint}
					/> as HTMLDivElement

					document.addEventListener("click", close)
					card.appendChild(hint)
				}}
				/>
			</div>

			<span
				class=":c: font-500 text-16"
				children={auth.user.balance.toLocaleString(lang())}
			/>
			<i class=":c: i-local:earth inline-block size-9 m-l-1" />

			<BasicInput.Root class=":c: m-t-2">
				<BasicInput
					class=":c: uno-layer-v2:(bg-white rounded-6px b-(1px solid #C3CAD4) font-300 text-4)"
					placeholder="0"
					value={state.value}
					error={err.value}
					onInput={(e, { currentTarget: { value } } = e) => {
						err.value = null

						if (value === "") {
							return
						}

						let as_number = Number(value)
						if (isNaN(as_number) || as_number < 0 || as_number > 1_000_000) {
							e.currentTarget.value = String(state.value)
							return
						}

						state.value = as_number
					}}
				/>
				<BasicInput.IconW right x={0} iconc=":c: uno-layer-v1:size-4"
					Icon={p =>
						<svg viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...p}>
							<path d="M7.7225 8.98886C8.11178 8.94782 8.64618 8.82557 9.04385 8.38293C9.23714 8.16993 9.46936 7.90372 9.57573 7.49283C9.65103 7.20193 9.65028 6.86052 9.64957 6.53506L9.64957 6.53462C9.6495 6.50338 9.64943 6.47229 9.64943 6.44141V0H11.0001V6.44141C11.0001 7.80314 10.605 8.73607 9.92032 9.37523C9.41775 9.84035 8.70563 10.1744 7.7225 10.3194V12H6.47389V10.3997H5.06919V12H3.82058V10.3199C2.83252 10.1753 2.11812 9.84115 1.61522 9.37616C0.935952 8.73744 0.542969 7.80413 0.542969 6.44141V0H1.89365V6.44141C1.89365 6.46801 1.89361 6.49472 1.89357 6.52152C1.89306 6.85479 1.89253 7.20017 1.97039 7.49626C2.07755 7.90379 2.30775 8.16871 2.49635 8.37972L2.49875 8.3824L2.50117 8.38507C2.8995 8.82387 3.42961 8.94706 3.82058 8.98862V4.50137H5.06919V9.00278C5.07538 9.00276 5.08148 9.00275 5.08749 9.00275H6.45559C6.4616 9.00275 6.4677 9.00276 6.47389 9.00278L6.47389 4.50137H7.7225L7.7225 8.98886Z" fill="#808080" />
						</svg>
					}
				/>
			</BasicInput.Root>

			<Dropdown
				options={cache.entities.esims}
				search={(value) => cache.entities.esims.filter(e => [e.imsi, e.alias].some(str => str.includes(value)))}
				display={(esim) => `${esim.imsi} / ${esim.alias}`}
				apply={(esim, ctx) => {
					if (!esim) {
						ctx.input.value = ""
						return
					}
					ctx.input.value = `${esim.imsi} / ${esim.alias}`
				}}
				error={err.imsi}
				afterSelect={(esim, text, ctx) => {
					if (!esim) {
						ctx.input.value = ""
						return
					}
					state.esim_id = esim.id
					ctx.input.value = `${esim.imsi} / ${esim.alias}`
				}}
				Root={p =>
					<div {...p}
						classList={{ ...p.classList, ":c: uno-layer-v3:(rounded-8px b-(1px solid #C3CAD4) m-t-2)": true }}
					/>
				}
				Input={p =>
					<BasicInput
						{...p}
						classList={{
							...p.classList,
							":c: uno-layer-v2:(bg-white rounded-6px b-(1px solid #C3CAD4) font-300 text-4)": true,
						}}
						placeholder={t().esim_placeholder}
						value={state.esim?.id}
					/>
				}
				Suggestions={p =>
					<Dropdown.Suggestions {...p}
						classList={{
							...p.classList,
							":c: uno-layer-v3:(b-unset bg-white backdrop-blur-none)": true
						}}
						Suggestion={p => {
							let ctx = useDropdownContext()
							return <Dropdown.Suggestion {...p}
								classList={{
									...p.classList,
									":c: uno-layer-v3:(b-unset)": true,
									":c: uno-layer-v3:(bg-gray-100)": ctx.hover === p.item,
								}}
							/>
						}}
					/>
				}
			/>

			<ContinueButton
				classList={{
					":c: uno-layer-v0:(bg-black c-white flex-center gap-1 block w-full m-t-3 m-b-0)": true,
					":c: uno-layer-v0:(disabled:bg-#2C2C2C)": true,
				}}
				disabled={state.loading_esims || state.submitting || state.esim == null || !state.value}
				onClick={submit}
			>
				<Switch>
					<Match when={state.loading_esims}>
						<span innerText={dictionary().common.loading} />
						<Spinner class="size-5" />
					</Match>
					<Match when={state.submitting}>
						<span innerText={dictionary().common.loading} />
						<Spinner class="size-5" />
					</Match>
					<Match when={true}>
						<span innerText={t().withdraw} />
					</Match>
				</Switch>
			</ContinueButton>
		</div>

		{[
			[t().personal_link, getShareUrl()],
			[t().procomode, auth.user.referral_code.toUpperCase()]
		].map(([label, text]) =>
			<div class=":c: m-inline-8 [&+&]:m-t-4 m-t-6">
				<span class=":c: c-#4D4D4D font-300" innerText={label + ":"} />
				<div class=":c: flex items-center gap-1 m-t-1.5">
					<span class=":c: c-#FF5F7C font-500 text-xl" innerText={text} />
					<i
						class=":c: i-local:copy size-4 c-#ABABAB ptr"
						onClick={() => {
							if (UNISIM_BUILD_PLATFORM === "web") {
								navigator.clipboard?.writeText(text)
									.then(() => toast.success(t().copied))
									.catch(e => trace.error("Failed to copy link", e))
							}
							else if (UNISIM_BUILD_PLATFORM === "android") {
								Android.invokeEvent("clipboard_copy_text", { label, content: text, success_text: t().copied })
							}
						}}
					/>
				</div>
			</div>)}

		<div
			class=":c: flex items-center gap-4 m-inline-8 m-t-5"
			children={
				[
					["i-local:telegram", tg],
					[":c: i-local:whatsapp", ws],
					["i-local:vk", vk],
					["i-local:mail", email]
				].map(([icon, url]) =>
					<a class={`:c: c-#525252 size-6 inline-block ptr ${icon}`}
						onClick={() => {
							if (UNISIM_BUILD_PLATFORM === "web") {
								window.open(url(), "_blank")
							}
							else if (UNISIM_BUILD_PLATFORM === "android") {
								window.open("external:" + url(), "_top")
							}
						}}
					/>
				)}
		/>

		<Switch>
			<Match when={!auth.is_authenticated}>
				{function() {
					function Card(props) {
						return <div
							classList={{
								":c: rounded-8px b-(1px solid #C3CAD4) bg-#F1F3F6 relative m-inline-4 p-4 [&>*]:relative [&+&]:m-t-4 m-t-8": true,
							}}
						>
							<div
								class=":c: absolute! inset-0 overflow-hidden [&>svg]:(absolute right-0 bottom-0 h-full [--star-color:#DBE0E7])"
								innerHTML={PatternStars}
							/>
							<div class=":c: size-10 relative rounded-8px b-(1px solid #C3CAD4) [&>svg]:abs-centered [&>svg>path]:fill-black" children={<props.Digit />} />

							<div class=":c: relative m-t-4.5 font-400 leading-25px" children={props.text.header} />
							<div class=":c: relative text-sm font-200 m-t-2 leading-20px" children={props.text.description()} />
						</div>
					}
					let items = [Digit1, Digit2, Digit3] as const
					return items.map((Digit, i, _arr, text = t().cards_anon[i]) => <Card Digit={Digit} text={text} />)
				}()}

			</Match>
			<Match when={auth.is_authenticated}>
				{function() {
					function Card(props) {
						return <div
							classList={{
								":c: rounded-8px b-(1px solid #C3CAD4) bg-#F1F3F6 relative m-inline-4 p-4 [&>*]:relative [&+&]:m-t-4 m-t-8": true,
							}}
						>
							<div
								class=":c: absolute! inset-0 overflow-hidden [&>svg]:(absolute right-0 bottom-0 h-full [--star-color:#DBE0E7])"
								innerHTML={PatternStars}
							/>
							<div class=":c: size-10 relative rounded-8px b-(1px solid #C3CAD4) [&>svg]:abs-centered [&>svg>path]:fill-black" children={<props.Digit />} />

							<div class=":c: relative m-t-4.5 font-500 leading-20px" children={props.text.header} />
							<div class=":c: relative text-3.5 font-300 m-t-2 leading-18px" children={props.text.description()} />
						</div>
					}
					let items = [Digit1, Digit2, Digit3] as const
					return items.map((Digit, i, _arr, text = t().cards_authed[i]) => <Card Digit={Digit} text={text} />)
				}()}

			</Match>
		</Switch>
		<span class=":c: c-#9CBE00 font-300 underline flex items-center m-inline-4 m-t-4 ptr"
			onClick={[router.stack, ROUTES.referal_program.template]}
			children={[
				t().referal_rules,
				<svg width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M15.9854 13.3868L10.859 8.26038L10.3464 8.77304L9.83371 9.28569L13.7225 13.1745L4.02376 13.1745L4.02376 14.6245L13.7225 14.6245L9.83373 18.5133L10.3464 19.0259L10.859 19.5386L15.9854 14.4121C16.2686 14.129 16.2686 13.67 15.9854 13.3868Z" fill="#9CBE00" />
				</svg>
			]} />
	</>
}


import PatternStars from "./pattern-stars.svg?raw"

export let Digit1 = () => <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M0 7.2V2.4C3.49254 2.4 5.3194 1.44 7.2 0H12V19.2H18V24H1.2V19.2H7.2V6C5.18507 6.82286 2.85448 7.2 0 7.2Z" />
</svg>
export let Digit2 = () => <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M9.6 0C14.8299 0 18 3.3986 18 8.4C18 16.7916 5.76257 13.628 4.8 19.2H18V24H0V20.4C0 8.34965 13.2 14.5427 13.2 8.4C13.2 6.28531 12.0706 4.8 9.6 4.8C6.80856 4.8 4.8 6.47832 4.8 9.6H0C0 3.92727 4.24171 0 9.6 0Z" />
</svg>
export let Digit3 = () => <svg width="18" height="24" viewBox="0 0 18 24" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M10.8 4.8H1.2V0H16.8V3.6L12 9.6C15.3671 10.1035 18 11.035 18 15.6C18 20.2657 14.6035 24 9.6 24C4.31329 24 0 19.8042 0 14.4H4.8C4.8 17.421 6.67343 19.2 9.6 19.2C11.9916 19.2 13.2 17.5804 13.2 15.6C13.2 13.4517 12.0231 12 9.6 12H7.2L6 9.6L10.8 4.8Z" />
</svg>

let t = langs({
	ru: {
		title: "Бонусный баланс",
		hint: "1 бонус = 1 доллар",
		esim_placeholder: "eSIM для пополнения",
		withdraw: "Списать",
		personal_link: "Ваша ссылка",
		procomode: "Промокод",
		copied: "Скопировано",

		cards_anon: {
			0: {
				header: "Зайдите в личный кабинет",
				description: () => "Воспользуйтесь почтой, на которую офрмлена eSIM.",
			},
			1: {
				header: "Поделитесь уникальной ссылкой",
				description: () => "Или промокодом.",
			},
			2: {
				header: "Пользуйтесь интернетом бесплатно",
				description: () => "Начислим бонусы сразу после покупки друга.",
			},
		},
		cards_authed: {
			0: {
				header: "Рекомендуйте",
				description: () => "Делитесь ссылкой или промокодом.",
			},
			1: {
				header: "Получайте бонусы",
				description: () => <>Дарим <span class="c-#FF5F7C">5% бонусами вам и другу</span> за покупку по рекомендации.</>,
			},
			2: {
				header: "Пользуйтесь интернетом бесплатно",
				description: () => "Зачислите бонусы  на одну из ваших eSIM. 1 бонус = 1 $.",
			},
		},
		referal_rules: "Правила программы",
	},
	en: {
		title: "Bonus balance",
		hint: "1 bonus = 1 dollar",
		esim_placeholder: "Select eSIM",
		withdraw: "Withdraw",
		personal_link: "Your personal link",
		procomode: "Promocode",
		copied: "Copied",

		cards_anon: {
			0: {
				header: "Log in to your account",
				description: () => "Use the email address you used to sign up.",
			},
			1: {
				header: "Share your unique link",
				description: () => "Or a promo code.",
			},
			2: {
				header: "Use mobile data for free",
				description: () => "We'll credit your bonus balance as soon as your friend makes a purchase.",
			},
		},
		cards_authed: {
			0: {
				header: "Recommend",
				description: () => "Share your link or promo code.",
			},
			1: {
				header: "Get bonuses",
				description: () => <>Get a <span class="c-#FF5F7C"> 5% bonus for both you and your friend</span>  when they make a purchase using your referral.</>,
			},
			2: {
				header: "Use mobile data for free",
				description: () => "Deposit bonuses to one of your eSIMs. 1 bonus = $1.",
			},
		},
		referal_rules: "About referal program",
	},
})
