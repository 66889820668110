import { ripple } from "#/lib/appearance/ripple/ripple"
import { drop, type ComposableComponentProps } from "#/lib/mod"

export function ContinueButton(props: ComposableComponentProps<"button"> & { class?: string }) {
	let other = drop(props, "classList", "onContextMenu", "class")

	// class="cursor-no-drop"
	return (
		<button
			type="button"
			onContextMenu={e => e.preventDefault()}
			{...other}
			ref={(r) => {
				ripple(r)
				other.ref?.(r)
			}}
			classList={{
				":c: w-auto h-11 rounded-2.5 shrink-0 relative m-b-8 m-t-auto ptr": true,
				":c: b-style-none bg-green-500": true,
				":c: disabled:(uno-layer-v0:bg-#C3CAD4 cursor-no-drop)": true,
				[props.class]: props.class != null,
				...props.classList
			}}
		/>
	)
}
