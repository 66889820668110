// reexport lib modules

export * from "./tracing"
export * from "./auth"
export * from "./behaviour"
export * from "./cookies"
export * from "./schedulers"
export * from "./overlay"

// reexport common modules
export { ROUTES, useInPageView, ViewTransition, useRouter, Navigate, onPageWasNavigated } from "./navigation/mod"
export { api } from "./remote/api"
export {
	signal,

	drop, recompose,

	Ref, mapData, trackDeep,

	type ComponentLike,
	type ComposableComponentLike,
	type ComposableComponentProps,
} from "./rx/mod"
export { langs, lang } from "./appearance/i18n"

export { useCache } from "./cache/cache.context"
export { useLayout } from "../layout.context"

// Lib things too
import { toast } from "solid-toast"
import EventEmitter from "eventemitter3"

import { env } from "./behaviour"
import { api } from "./remote/api"
import { type lang, LANGS_META } from "./appearance/i18n"

export const
	TEXT_ENCODER = new TextEncoder(),
	TEXT_DECODER = new TextDecoder()

export const
	MILLISECONDS_IN_DAY = 1000 * 60 * 60 * 24,
	DEFAULT_LOCALE = "ru-RU"

export function formatFloatLike(value: string | number, clang: ReturnType<typeof lang>) {
	if (typeof value === "string") {
		try { value = parseFloat(value) } 
		catch (e) {
			gtrace.error(e)
			value = 0.00
		}
	}
	
	return new Intl.NumberFormat(LANGS_META[clang].locale_name, { maximumFractionDigits: 2 })
		.format(value)
}

export let CURRENCY_SYMBOLS = {
	RUB: "₽",
	USD: "$"
} as const

/**
```md
TODO: refactor to i18n module?
cardinal is number of items
ordinal is order of items
resolved categories/types could be debuggied via `plural_rule.resolvedOptions().pluralCategories`
ru-RU: one, few, many, other
```
*/
export let PLURALS = {
	CardinalRule: new Intl.PluralRules(DEFAULT_LOCALE, { type: "cardinal" }),
	OrdinalRule: new Intl.PluralRules(DEFAULT_LOCALE, { type: "ordinal" }),
}

export function doNextFrame<Fn extends FrameRequestCallback>(fn: Fn) {
	if (env.rt.is_firefox)
		return requestAnimationFrame(() => requestAnimationFrame(fn))

	return requestAnimationFrame(fn)
}

export let onlyDev = <T, F = T>(value: T, fallback?: F) =>
	import.meta.env.DEV ? value : fallback ?? (value?.constructor?.() as T)

export function cl(...strs: string[]) {
	return Object.fromEntries(strs.map(s => ([s, true]))) as Record<string, true>
}

export function truncateText(text: string, { max_chars = 200, max_words = 5, squeeze = true, ellipsis = false } = {}) {
	if (squeeze)
		text = text.replace(/\n+/g, "\n")

	let cap_iof = -1, i = 0
	for (let len = text.length, wc = 0, last_word_len = 0; i < len; i++) {
		if (text[i] !== " ") {
			last_word_len = last_word_len + 1
		}
		else {
			if (i === len - 1) break

			while (text[i + 1] === " ") {
				i++
				continue
			}

			if (last_word_len > 3) wc++

			last_word_len = 0

			if (wc >= max_words || i >= max_chars) {
				cap_iof = i
				break
			}
		}
	}

	if (ellipsis && cap_iof > -1) {
		text = text.slice(0, cap_iof) + "..."
		cap_iof += 3
	}

	return {
		text,
		truncated: cap_iof > -1,
	}
}

export function isDiscriminant<R, E, P extends keyof E>(target: R | E | {}, prefix: P): target is E {
	return target && typeof target === "object" && prefix in target
}
export let isApiError = <T, E extends api.ApiError>(target: T | E): target is E => isDiscriminant<T | E, E, "$api_error">(target, "$api_error")

export function errorHandled<
	T,
	R extends (T | api.ApiError),
	E extends R extends api.ApiError ? R : never
>(response: R, prefix?: string): response is E {
	if (isApiError(response)) {
		let parts = [], postfix: string
		if (prefix) parts.push(prefix)
		switch (response.$api_error) {
			case "network":
				postfix = `Проблемы с подключением`
				break
			case "parse":
			case "bad_response":
				postfix = `Неопозанный ответ от сервера. Попробуйте позже`
				break
			case "unknown":
				postfix = `Неизвестная ошибка`
				break
			case "remote":
				postfix = `${response.detail}`
				break
		}
		parts.push(postfix)
		toast.error(parts.join("\n"), { duration: 2500 })
		return true
	}

	return false
}

export let stringToColor = (text: string, lightness = 55, saturation = 76) =>
	crypto.subtle?.digest("SHA-1", TEXT_ENCODER.encode(text))
		.then(x => new Uint8Array(x))
		.then(x => x.join("").slice(16))
		.then(Number)
		.then(hash => `hsl(${hash % 360},${saturation}%,${lightness}%)`)


export type CancelSignal = ReturnType<typeof CancelSignal>
export function CancelSignal() {
	let emitter = new EventEmitter<{ "cancelled": [] }>()
	let signal = {
		cancelled: false,
		cancel() {
			emitter.emit("cancelled")
			signal.cancelled = true
		},
		onCancelled: emitter.on.bind(emitter, "cancelled"),
	}
	return signal
}


export let LocalStorageKeys = {
	OTP_KEY: "otp_key",
	JWT: "jwt",
	LOGIN_VIEW: "login_view",
	ONBOARDING_VIEW: "onboarding_view"
} as const

export let IdbStoreKeys = {
	// ONBOARDING_FORM: "onboarding_form"
} as const
