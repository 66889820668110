import { MobilePage, MainHeader } from "#/common/mod"
import { ROUTES, lang, langs, useRouter } from "#/lib/mod"
import { LANGS_META } from "#/lib/appearance/i18n"
import { HorizontalLine } from "#/components/hr"


export default function MorePage() {
	let router = useRouter()

	let Row = p => <div {...p}
		classList={{
			...p.classList,
			":c: h-9 flex items-center justify-between p-inline-4 ptr relative": true
		}}
		ref={ripple}
	/>

	return <MobilePage>
		<MainHeader />

		<div class=":c: flex flex-col gap-2 font-300 m-t-5">
			<Row onClick={() => router.stack(ROUTES.languages.template)}>
				<div class=":c: flex-center gap-1.5">
					{LanguageIcon}
					<span innerText={t().language} />
				</div>

				<div class=":c: flex items-center ptr" >
					{LANGS_META[lang()].name_native} <ChevronRightIcon />
				</div>
			</Row>

			<HorizontalLine class=":c: m-inline-4 m-t-3" />

			{([
				[t().privacy_policy, ROUTES.privacy.template],
				[t().user_agreement, ROUTES.terms.template],
				[t().referral_program_conditions, ROUTES.referal_program.template],
			] as const).map(([text, route]) =>
				<Row onClick={() => router.stack(route)}>
					<span innerText={text} />
					<ChevronRightIcon />
				</Row>
			)}

			<HorizontalLine class=":c: m-inline-4 m-t-3" />

			<Row onClick={() => router.stack(ROUTES.contacts.template)}>
				<span innerText={t().contacts} />
				<ChevronRightIcon />
			</Row>

			<Row onClick={() => window.open(t().review_link, "_blank")}>
				<span innerText={t().leave_review} />
				{PencilEditIcon}
			</Row>

			<Row onClick={() => router.stack({ path: ROUTES.onboarding.template, force: "replace" })}>
				<span innerText={"на онбординг"} />
				<ChevronRightIcon />
			</Row>

		</div>
	</MobilePage>
}

let LanguageIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<g clip-path="url(#clip0_2982_4822)">
		<path d="M1.66602 10.0003C1.66602 14.6028 5.39685 18.3337 9.99935 18.3337C14.6018 18.3337 18.3327 14.6028 18.3327 10.0003C18.3327 5.39783 14.6018 1.66699 9.99935 1.66699C5.39685 1.66699 1.66602 5.39783 1.66602 10.0003Z" stroke="#9CBE00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
		<path d="M10.8331 1.70898C10.8331 1.70898 13.3331 5.00065 13.3331 10.0007C13.3331 15.0007 10.8331 18.2923 10.8331 18.2923M9.16641 18.2923C9.16641 18.2923 6.66641 15.0007 6.66641 10.0007C6.66641 5.00065 9.16641 1.70898 9.16641 1.70898M2.19141 12.9173H17.8081M2.19141 7.08398H17.8081" stroke="#9CBE00" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
	</g>
	<defs>
		<clipPath id="clip0_2982_4822">
			<rect width="20" height="20" fill="white" />
		</clipPath>
	</defs>
</svg>

let ChevronRightIcon = () => <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path fill-rule="evenodd" clip-rule="evenodd" d="M12.9182 10.0001C12.9182 10.2135 12.8365 10.4268 12.674 10.5893L9.34068 13.9226C9.01484 14.2485 8.48818 14.2485 8.16234 13.9226C7.83651 13.5968 7.83651 13.0701 8.16234 12.7443L10.9165 9.99012L8.26651 7.24595C7.94734 6.91345 7.95651 6.38762 8.28734 6.06762C8.61818 5.74762 9.14651 5.75678 9.46568 6.08762L12.684 9.42095C12.8407 9.58345 12.9182 9.79178 12.9182 10.0001Z" fill="#4D4D4D" />
</svg>

let PencilEditIcon = <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M15.1615 0.395024L15.6003 0.829109C16.205 1.428 16.1135 2.48967 15.3943 3.20039L6.34499 12.1521L3.4131 13.213C3.04494 13.3469 2.68645 13.1733 2.61356 12.8268C2.58901 12.7011 2.6006 12.5711 2.64703 12.4515L3.74035 9.52625L12.7644 0.598823C13.4836 -0.111898 14.5568 -0.203865 15.1615 0.395024ZM5.95005 1.28527C6.04773 1.28527 6.14444 1.3043 6.23468 1.34127C6.32491 1.37824 6.4069 1.43244 6.47597 1.50076C6.54503 1.56908 6.59982 1.65018 6.6372 1.73945C6.67457 1.82871 6.69381 1.92438 6.69381 2.021C6.69381 2.11762 6.67457 2.21329 6.6372 2.30256C6.59982 2.39182 6.54503 2.47293 6.47597 2.54125C6.4069 2.60957 6.32491 2.66376 6.23468 2.70073C6.14444 2.73771 6.04773 2.75674 5.95005 2.75674H2.97503C2.58051 2.75674 2.20216 2.91177 1.9232 3.18772C1.64423 3.46368 1.48751 3.83795 1.48751 4.22821V13.0571C1.48751 13.4473 1.64423 13.8216 1.9232 14.0975C2.20216 14.3735 2.58051 14.5285 2.97503 14.5285H11.9001C12.2946 14.5285 12.673 14.3735 12.9519 14.0975C13.2309 13.8216 13.3876 13.4473 13.3876 13.0571V10.1141C13.3876 9.91898 13.466 9.73184 13.6055 9.59386C13.7449 9.45588 13.9341 9.37837 14.1314 9.37837C14.3286 9.37837 14.5178 9.45588 14.6573 9.59386C14.7968 9.73184 14.8751 9.91898 14.8751 10.1141V13.0571C14.8751 13.8376 14.5617 14.5861 14.0038 15.138C13.4458 15.6899 12.6891 16 11.9001 16H2.97503C2.186 16 1.42929 15.6899 0.871365 15.138C0.313439 14.5861 0 13.8376 0 13.0571V4.22821C0 3.44769 0.313439 2.69914 0.871365 2.14724C1.42929 1.59533 2.186 1.28527 2.97503 1.28527H5.95005Z" fill="#4D4D4D" />
</svg>

let t = langs({
	ru: {
		language: "Язык",
		privacy_policy: "Политика конфиденциальности",
		user_agreement: "Пользовательское соглашение",
		referral_program_conditions: "Условия реферальной программы",
		contacts: "Контакты",
		leave_review: "Оставить отзыв",
		review_link: "https://otzovik.com/reviews/esim_dlya_puteshestviy_unisim",
	},
	en: {
		language: "Language",
		privacy_policy: "Privacy Policy",
		user_agreement: "User Agreement",
		referral_program_conditions: "Referral Program Terms",
		contacts: "Contacts",
		leave_review: "Leave Review",
		review_link: "https://www.trustpilot.com/review/unisim.net",
	}
})
