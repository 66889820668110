import { ComponentProps, For, Match, Switch, onMount } from "solid-js"
import { createMutable, unwrap } from "solid-js/store"

import { CURRENCY_SYMBOLS, ROUTES, api, errorHandled, formatFloatLike, lang, langs, useCache, useRouter } from "#/lib/mod"
import { toast } from "solid-toast"


type Esim = api.SyncEsimResponse

export function Esims() {
	let router = useRouter(), cache = useCache()

	let state = createMutable({
		loading: false,

		syncing_id: null as string,

		editing_alias_id: null as string,

		esims: [] as Awaited<ReturnType<typeof loadEsims>>,

		alias_value: null as string,
	})

	queueMicrotask(loadEsims)

	async function loadEsims() {
		state.loading = true

		let response = await api.getEsims({})
			.finally(() => state.loading = false)

		if (errorHandled(response, "[загрузка карт]"))
			return

		cache.update("esims", response.results)

		state.esims = response.results

		return response.results
	}

	async function sync(esim: Esim) {
		if (state.syncing_id === esim.id)
			return

		state.syncing_id = esim.id

		let response = await api.syncEsim({ esim_id: esim.id })
			.finally(() => state.syncing_id = null)

		if (errorHandled(response)) {
			return
		}

		Object.assign(esim, response)
	}

	async function applyAlias(esim: Esim) {
		state.editing_alias_id = null

		if (esim.alias === state.alias_value) {
			return
		}

		let response = await api.renameEsim({ esim_id: esim.id, alias: state.alias_value })

		if (errorHandled(response)) {
			return
		}

		Object.assign(esim, response)
	}

	return <div class=":c: grid grid-flow-col gap-2.5 overflow-(x-auto y-clip) p-inline-4 scrollbar-none m-t-8">
		<For each={state.esims}
			children={esim =>
				<div class=":c: flex flex-col justify-between w-80 h-40 bg-gray-800 rounded-3 p-4 relative select-none">
					<div class=":c: flex items-center">
						<span class=":c: c-white font-700 text-9">{formatFloatLike(esim.last_balance, lang(), { minimumFractionDigits: 2 })} {CURRENCY_SYMBOLS.USD}</span>
						<RefreshIcon
							classList={{
								":c: ptr size-6 m-l-3": true,
								":c: animate-spin": state.syncing_id === esim.id,
							}}
							onClick={sync.bind(null, esim)}
						/>
						<div class=":c: p-block-3px p-inline-10px c-black bg-green-500 text-xs font-300 rounded-18px ptr m-l-auto"
							innerText={t().topup}
							onClick={() => router.stack({
								path: ROUTES.balance_sheet.template,
								props: { esim: unwrap(esim) }
							})}
						/>
					</div>

					<div class=":c: flex items-center gap-2.5 m-t-auto c-white text-5 font-500">
						<Switch>
							<Match when={state.editing_alias_id !== esim.id}>
								<span innerText={esim.alias} />
								<PencilIcon class=":c: size-4 ptr"
									onClick={() => {
										state.editing_alias_id = esim.id
									}}
								/>
							</Match>
							<Match when={state.editing_alias_id === esim.id}>
								<input
									class=":c: min-w-0 appearance-none m-0 p-0 b-none"
									value={esim.alias}
									ref={r => {
										state.alias_value = esim.alias
										onMount(r.focus.bind(r))
									}}
									onInput={({ currentTarget: { value } }) => state.alias_value = value}
								/>
								<CheckIcon class=":c: size-5 ptr" onClick={[applyAlias, esim]} />
							</Match>
						</Switch>
					</div>
					<div
						class=":c: text-xs m-t-1.5 c-gray-300 ptr"
						onClick={(e) => {
							if (UNISIM_BUILD_PLATFORM === "web") {
								navigator.clipboard?.writeText(esim.imsi)
									.then(() => toast.success("IMSI was copied to clipboard"))
									.catch(e => gtrace.error("Failed to copy link", e))
							}
							else if (UNISIM_BUILD_PLATFORM === "android") {
								Android.invokeEvent("clipboard_copy_text", { content: esim.imsi, success_text: "IMSI was copied to clipboard" })
							}
						}}>
						IMSI: {esim.imsi}
					</div>
				</div>
			}
		/>
	</div>
}

let t = langs({
	ru: {
		topup: "Пополнить",
	},
	en: {
		topup: "Top up",
	}
})


function RefreshIcon(props: ComponentProps<"svg">) {
	return <svg viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props} >
		<path fill-rule="evenodd" clip-rule="evenodd" d="M20.303 13.926C19.777 13.759 19.215 14.05 19.047 14.576C18.11 17.522 15.352 19.5 12.184 19.5C8.222 19.5 5 16.36 5 12.5C5 8.641 8.222 5.5 12.184 5.5C13.912 5.5 15.542 6.102 16.829 7.17L14.663 6.813C14.113 6.713 13.603 7.092 13.513 7.636C13.423 8.181 13.792 8.696 14.337 8.785L18.583 9.487C18.638 9.496 18.694 9.5 18.747 9.5C18.866 9.5 18.982 9.479 19.09 9.439C19.127 9.425 19.157 9.399 19.193 9.38C19.259 9.346 19.328 9.316 19.385 9.268C19.421 9.239 19.444 9.197 19.475 9.163C19.522 9.114 19.572 9.067 19.607 9.007C19.632 8.964 19.642 8.913 19.661 8.865C19.685 8.806 19.717 8.751 19.729 8.686L20.483 4.686C20.585 4.143 20.228 3.619 19.685 3.518C19.144 3.42 18.619 3.773 18.517 4.315L18.245 5.756C16.581 4.315 14.448 3.5 12.184 3.5C7.12 3.5 3 7.537 3 12.5C3 17.463 7.12 21.5 12.184 21.5C16.226 21.5 19.75 18.961 20.953 15.182C21.12 14.656 20.829 14.094 20.303 13.926Z" fill="#ABABAB" />
		<mask id="mask0_3003_11028" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="19">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M20.303 13.926C19.777 13.759 19.215 14.05 19.047 14.576C18.11 17.522 15.352 19.5 12.184 19.5C8.222 19.5 5 16.36 5 12.5C5 8.641 8.222 5.5 12.184 5.5C13.912 5.5 15.542 6.102 16.829 7.17L14.663 6.813C14.113 6.713 13.603 7.092 13.513 7.636C13.423 8.181 13.792 8.696 14.337 8.785L18.583 9.487C18.638 9.496 18.694 9.5 18.747 9.5C18.866 9.5 18.982 9.479 19.09 9.439C19.127 9.425 19.157 9.399 19.193 9.38C19.259 9.346 19.328 9.316 19.385 9.268C19.421 9.239 19.444 9.197 19.475 9.163C19.522 9.114 19.572 9.067 19.607 9.007C19.632 8.964 19.642 8.913 19.661 8.865C19.685 8.806 19.717 8.751 19.729 8.686L20.483 4.686C20.585 4.143 20.228 3.619 19.685 3.518C19.144 3.42 18.619 3.773 18.517 4.315L18.245 5.756C16.581 4.315 14.448 3.5 12.184 3.5C7.12 3.5 3 7.537 3 12.5C3 17.463 7.12 21.5 12.184 21.5C16.226 21.5 19.75 18.961 20.953 15.182C21.12 14.656 20.829 14.094 20.303 13.926Z" fill="white" />
		</mask>
		<g mask="url(#mask0_3003_11028)">
			<rect y="0.5" width="24" height="24" fill="#ABABAB" />
		</g>
	</svg>
}

function PencilIcon(props: ComponentProps<"svg">) {
	return <svg viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6786 7.61944L8.88195 5.82278L10.1806 4.52344L11.9766 6.31944L10.6786 7.61944ZM6.05261 12.2501L4.06795 12.4308L4.24395 10.4601L7.98861 6.71544L9.78595 8.51278L6.05261 12.2501ZM12.9353 5.39211L12.9346 5.39144L11.1093 3.56611C10.6153 3.07344 9.76661 3.05011 9.29861 3.52011L3.30128 9.51744C3.08395 9.73411 2.94928 10.0221 2.92128 10.3268L2.66861 13.1068C2.65128 13.3034 2.72128 13.4981 2.86128 13.6381C2.98728 13.7641 3.15728 13.8334 3.33261 13.8334C3.35328 13.8334 3.37328 13.8328 3.39328 13.8308L6.17328 13.5781C6.47861 13.5501 6.76595 13.4161 6.98261 13.1994L12.9806 7.20144C13.4659 6.71478 13.4453 5.90278 12.9353 5.39211Z" fill="white" />
		<mask id="mask0_3003_11037" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="3" width="12" height="11">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M10.6786 7.61944L8.88195 5.82278L10.1806 4.52344L11.9766 6.31944L10.6786 7.61944ZM6.05261 12.2501L4.06795 12.4308L4.24395 10.4601L7.98861 6.71544L9.78595 8.51278L6.05261 12.2501ZM12.9353 5.39211L12.9346 5.39144L11.1093 3.56611C10.6153 3.07344 9.76661 3.05011 9.29861 3.52011L3.30128 9.51744C3.08395 9.73411 2.94928 10.0221 2.92128 10.3268L2.66861 13.1068C2.65128 13.3034 2.72128 13.4981 2.86128 13.6381C2.98728 13.7641 3.15728 13.8334 3.33261 13.8334C3.35328 13.8334 3.37328 13.8328 3.39328 13.8308L6.17328 13.5781C6.47861 13.5501 6.76595 13.4161 6.98261 13.1994L12.9806 7.20144C13.4659 6.71478 13.4453 5.90278 12.9353 5.39211Z" fill="white" />
		</mask>
		<g mask="url(#mask0_3003_11037)">
			<rect y="0.5" width="16" height="16" fill="white" />
		</g>
	</svg>
}


function CheckIcon(props: ComponentProps<"svg">) {
	return <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
		<path fill-rule="evenodd" clip-rule="evenodd" d="M6.57555 11.9999C6.39155 11.9999 6.21555 11.9239 6.08955 11.7899L2.84755 8.33725C2.59488 8.06925 2.60888 7.64725 2.87688 7.39525C3.14555 7.14325 3.56755 7.15658 3.81888 7.42458L6.56888 10.3519L12.1742 4.21725C12.4235 3.94458 12.8449 3.92658 13.1169 4.17458C13.3882 4.42258 13.4069 4.84458 13.1589 5.11591L7.06755 11.7826C6.94288 11.9199 6.76555 11.9986 6.58021 11.9999H6.57555Z" fill="#D6FE1E" />
		<mask id="mask0_1991_14514" style="mask-type:luminance" maskUnits="userSpaceOnUse" x="2" y="4" width="12" height="8">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M6.57555 11.9999C6.39155 11.9999 6.21555 11.9239 6.08955 11.7899L2.84755 8.33725C2.59488 8.06925 2.60888 7.64725 2.87688 7.39525C3.14555 7.14325 3.56755 7.15658 3.81888 7.42458L6.56888 10.3519L12.1742 4.21725C12.4235 3.94458 12.8449 3.92658 13.1169 4.17458C13.3882 4.42258 13.4069 4.84458 13.1589 5.11591L7.06755 11.7826C6.94288 11.9199 6.76555 11.9986 6.58021 11.9999H6.57555Z" fill="white" />
		</mask>
		<g mask="url(#mask0_1991_14514)">
			<rect width="16" height="16" fill="#D6FE1E" />
		</g>
	</svg>
}
