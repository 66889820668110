import { drop } from "#/lib/mod"
import { type ComponentProps, type ParentProps } from "solid-js"
import { Spinner } from "./spinner"
import { UnisimLogoIcon } from "./mod"

export function InfoOverlay(props: ParentProps & ComponentProps<"div">) {
	let other = drop(props, "class", "classList")

	return <div {...other} classList={{
		":c: abs-centered max-w-inherit w-full": true,
		[props.class]: !!props.class,
		...props.classList,
	}} />
}

export let LoadingScreen = () => <div
	class=":c: light:bg-green-500 absolute inset-0"
	children={[
		<UnisimLogoIcon class="block m-inline-auto m-t-15dvh w-35" />,
		<Spinner class="absolute abs-centered-x bottom-10dvh uno-layer-v1:[&>i]:(size-12 c-black)"  />,
	]}
/>
