if (!Array.prototype.toSorted) {
	Array.prototype.toSorted = function(...args) {
		let new_array = [...this]
		return new_array.sort(...args)
	}
}

if (!globalThis.queueMicrotask) {
	globalThis.queueMicrotask = setTimeout
}

if (!Promise.withResolvers) {
	Promise.withResolvers = function() {
		let resolve, reject
		let promise = new Promise((res, rej) => {
			resolve = res
			reject = rej
		})
		return { promise, resolve, reject }
	}
}

if (!globalThis.requestIdleCallback) {
	globalThis.requestIdleCallback = setTimeout
	globalThis.cancelIdleCallback = clearTimeout
}

if(!AbortSignal.timeout) {
	AbortSignal.timeout = function(time) {
		const controller = new AbortController()

		setTimeout(() => controller.abort(new DOMException(`This signal is timeout in ${time}ms`, 'TimeoutError')), time)

		return controller.signal
	}
}

console.debug("polyfills are initialized =)")