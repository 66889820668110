import { UnisimTextLogo } from "#/components/mod"
import { useAuth } from "#/lib/auth"
import { ROUTES, useRouter } from "#/lib/mod"
import { Show } from "solid-js"


export function PageHeader() {
	let auth = useAuth()
	let router = useRouter()

	return <div class=":c: grid grid-cols-[1fr_1fr] items-center h-9 m-inline-4">
		<UnisimTextLogo class=":c: h-4.5" />
		{function() {
			let icon_class = ":c: size-7 justify-self-end ptr"

			return <Show
				when={auth.is_authenticated}
				fallback={
					<i
						classList={{ [icon_class]: true, ":c: i-hero:user-circle-solid": true }}
						onClick={() => router.switchTo(ROUTES.login.template)}
					/>
				}
			>
				<svg class={icon_class} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={auth.logout}>
					<path d="M12.4993 3.33398H4.16602V15.0007C4.16602 15.4427 4.34161 15.8666 4.65417 16.1792C4.96673 16.4917 5.39065 16.6673 5.83268 16.6673H12.4993M13.3327 12.5007L15.8327 10.0007M15.8327 10.0007L13.3327 7.50065M15.8327 10.0007H7.49935" stroke="black" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round" />
				</svg>
			</Show>
		}()}
	</div>
}
