import { drop, type ComposableComponentProps } from "#/lib/mod";

export function Spinner(props: ComposableComponentProps<'div'> & { class?: string }) {
	let other = drop(props, "class", "classList")
	return (
		<wrapper
			{...other}
			classList={{
				"flex-center": true,
				[props.class]: !!props.class,
				...props.classList,
			}}>
			<i class=":c: i-local:spinner max-h-full max-w-full animate-spin transform-origin-cc c-gray-600" />
		</wrapper>
	)
}
