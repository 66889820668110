import { langs } from "#/lib/mod"


export function Reviews() {
	return <>
		<div
			
			class=":c: grid grid-flow-col gap-2.5 overflow-(x-auto y-clip) p-inline-4 [&>div]:(bg-white rounded-3 p-4 w-72 relative select-none) scrollbar-none m-t-10">
			{Object.values(t().reviews).map((r) => <Review {...r} stars={5} />)}
		</div>

		<div class=":c: flex flex-col items-center gap-2">
			<div class=":c: flex items-center self-center m-t-5">
				<span class=":c: font-600 text-5 c-[#FF5F7C]">4,8/5</span>
				<svg class="w-auto h-5.5" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_2823_13757)">
						<path d="M11.9847 11.1132L13.4447 15.6079L8.50267 12.0159L11.9847 11.1132ZM16.5 6.20658H10.39L8.50333 0.392578L6.61 6.20791L0.5 6.19991L5.448 9.79791L3.55467 15.6072L8.50267 12.0159L11.558 9.79791L16.5 6.20658Z" fill="#FF5F7C" />
					</g>
					<defs>
						<clipPath id="clip0_2823_13757">
							<rect width="16" height="16" fill="white" transform="translate(0.5)" />
						</clipPath>
					</defs>
				</svg>
			</div>
			<span class="font-300">{t().trustpilot}</span>
		</div>
	</>
}

let Review = p => <div>
	<div class=":c: flex items-center justify-between ptr" onClick={() => window.open(p.url, "_blank")}>
		<div class="font-600">{p.who}</div>
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M12.5041 2.66702L4.21855 2.66699V3.49558V4.32413H10.5038L2.66602 12.1619L3.83777 13.3337L11.6756 5.49589V11.7811H12.5041H13.3327V3.49558C13.3327 3.03798 12.9617 2.66702 12.5041 2.66702Z" fill="black" />
		</svg>
	</div>

	<div class=":c: flex items-center gap-1 c-[#4D4D4D] m-t-2">
		<svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.26752 6.78406C7.65009 6.78406 8.01888 6.63235 8.29278 6.36232C8.56668 6.09229 8.72325 5.72605 8.72803 5.34417C8.73282 4.96229 8.58543 4.59605 8.3183 4.32602C8.05117 4.05599 7.68617 3.90429 7.30361 3.90429C6.92104 3.90429 6.55224 4.05599 6.27834 4.32602C6.00444 4.59605 5.84788 4.96229 5.84309 5.34417C5.84072 5.53326 5.87569 5.7205 5.94599 5.89519C6.01629 6.06989 6.12055 6.22862 6.25282 6.36232C6.38509 6.49603 6.54278 6.60209 6.71688 6.67445C6.89098 6.74681 7.07809 6.78406 7.26752 6.78406ZM7.33608 1.3125C8.40727 1.3125 9.42926 1.73726 10.1772 2.49335C10.9252 3.24943 11.3379 4.27491 11.3245 5.34417C11.2866 8.36793 7.19173 12.8316 7.19173 12.8316C7.19173 12.8316 3.20875 8.36793 3.24665 5.34417C3.26005 4.27491 3.69842 3.24944 4.46534 2.49335C5.23226 1.73726 6.2649 1.3125 7.33608 1.3125Z" stroke="#4D4D4D" />
		</svg>
		<div class="font-300">{p.where}</div>
	</div>

	<div class=":c: w-114px flex gap-6px m-t-6">
		{new Array(5).fill(null).map((_, i) => {
			if (i < p.stars) {
				return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M4.36875 16.5L5.5875 11.2313L1.5 7.6875L6.9 7.21875L9 2.25L11.1 7.21875L16.5 7.6875L12.4125 11.2313L13.6312 16.5L9 13.7062L4.36875 16.5Z" fill="#FF5F7C" />
				</svg>
			}

			return <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M6.6375 13.3688L9 11.9438L11.3625 13.3875L10.7437 10.6875L12.825 8.8875L10.0875 8.64375L9 6.09375L7.9125 8.625L5.175 8.86875L7.25625 10.6875L6.6375 13.3688ZM4.36875 16.5L5.5875 11.2313L1.5 7.6875L6.9 7.21875L9 2.25L11.1 7.21875L16.5 7.6875L12.4125 11.2313L13.6312 16.5L9 13.7062L4.36875 16.5Z" fill="#FF5F7C" />
			</svg>

		})}
	</div>
	<div class=":c: font-300 m-t-4 leading-20px">
		{p.text}
	</div>
</div>



let t = langs({
	ru: {
		trustpilot: "на Trustpilot",
		reviews: {
			0: {
				who: "Марта Агрба",
				"url": "https://www.trustpilot.com/users/65f8acd05f67270012f3ef48",
				where: "Нидерланды",
				"text": "<...> Я предложила своим друзьям купить UNISIM, но они решили использовать местные сим-карты. Мы были в туре по отдаленным островам, у меня была отличная связь, в то время как их сим-карты не работали :-) Вы можете доверять этой eSIM во время своих путешествий."
			},
			1: {
				who: "Гио Какабадзе",
				"url": "https://www.trustpilot.com/reviews/6566017f697e1c9254b51dd5",
				where: "Грузия",
				"text": "<...> Цена самая низкая на рынке, а скорость интернета нисколько не страдает, вы также можете делиться Интернетом с друзьями, что тоже довольно удобно. Настоятельно рекомендую эту eSIM."
			},
			2: {
				who: "Михаил Исраелашвили",
				"url": "https://www.trustpilot.com/users/6526b7a8dd31cd0012992319",
				where: "Бельгия",
				"text": "<...> Никогда не сталкивался с какими-либо техническими проблемами <...> очень удобно, когда я часто путешествовал из одной страны в другую, поскольку у меня был только один баланс для всех них, которым я легко мог управлять."
			},
			3: {
				who: "Алана Атарова",
				"url": "https://www.trustpilot.com/users/65ede89caf0fcf0012eef235",
				where: "Италия",
				"text": "Пользуюсь UNISIM уже 2 недели, путешествуя по Европе, работает отлично! Зарегистрироваться так просто) Цена самая низкая! Скорость интернета была отличной! Настоятельно рекомендую, супер полезный сервис!"
			},
			4: {
				who: "Рубен Карагезян",
				"url": "https://www.trustpilot.com/users/66046fa244935e00127ea6ea",
				where: "Польша",
				"text": "Лучший сервис, который может обеспечить вам ежеминутную связь с друзьями / родственниками / работой в любой стране мира. Не перестану им пользоваться."
			},
			5: {
				who: "Вито Мцаре",
				"url": "https://www.trustpilot.com/users/64f5dfd1aedc9d00126d3d08",
				where: "Грузия",
				"text": "<...> Я действительно впечатлен. Качество покрытия в большинстве стран, которые я посетил, было на высшем уровне, даже в некоторых отдаленных районах. Что еще более удивительно, так это скорость и дружелюбие их службы поддержки клиентов. <...>"
			},
			6: {
				who: "Анастасия Р",
				"url": "https://www.trustpilot.com/users/652bdba2ab515c0012336d43",
				where: "Гонконг",
				"text": "Впервые попробовала этой весной, абсолютно идеальный сервис для тех, кто много путешествует. Дешевые тарифы и легкий доступ к Интернету, где бы вы ни находились. Сразу после приземления в любой стране вы в Сети <...>"
			},
			7: {
				who: "Анонимный пользователь",
				"url": "https://www.trustpilot.com/users/65f0c1b98dae0f001290d72c",
				where: "Польша",
				"text": "Я пользовался сервисом в Турции, Италии и Франции, он очень удобен в использовании, цены одни из лучших и прост в использовании. Я пользовался многими подобными сервисами, но этот - лучший."
			},
			8: {
				who: "Дани Бонд",
				"url": "https://www.trustpilot.com/users/64fa1338bda1d800124580a9",
				where: "Грузия",
				"text": "Как цифровой кочевник, я в основном путешествую 24/7. Я опробовал большинство туристических ESIM, и UNISIM, безусловно, лучший из них. Это позволяет мне оставаться на связи в любой стране, куда я приезжаю, с помощью одной sim-карты <...>"
			},
			9: {
				who: "Георгий Попандопуло",
				"url": "https://www.trustpilot.com/users/652fad68ce049d0012dbd20d",
				where: "Германия",
				"text": "Очень удобный и доступный сервис <...> Пробовал его в четырех странах и никогда не испытывал проблем с UNISIM – подключение очень быстрое, легко пополнить счет и интуитивно понятный веб-сайт с возможностью установки eSIM в один клик."
			}

		}
	},
	en: {
		trustpilot: "on Trustpilot",
		reviews: {
			0: {
				who: "Marta Agrba",
				"url": "https://www.trustpilot.com/users/65f8acd05f67270012f3ef48",
				where: "Netherlands",
				"text": "<…> I offered my friends to buy UNISIM, but they decided to go with local SIMs. We were on a tour to remote islands, I had perfect connection while their SIMs didn't work :-) You can trust this eSIM during your travels."
			},
			1: {
				who: "Gio Kakabadze",
				"url": "https://www.trustpilot.com/reviews/6566017f697e1c9254b51dd5",
				where: "Georgia",
				"text": "<…> Price is the lowest at the market and the Internet speed does not suffer at all, you can also share the Internet with friends, which is also quite convenient. Highly recommend this eSIM."
			},
			2: {
				who: "Mikhail Israelashvili",
				"url": "https://www.trustpilot.com/users/6526b7a8dd31cd0012992319",
				where: "Belgium",
				"text": "<…> Have never faced any technical issues <…> very useful when I traveled frequently from one country to another as I just had one balance for all of them which I easily could manage."
			},
			3: {
				who: "Alana Atarova",
				"url": "https://www.trustpilot.com/users/65ede89caf0fcf0012eef235",
				where: "Italy",
				"text": "Have been using UNISIM for 2 weeks travelling around Europe, it works perfectly! So easy to register) The price is lowest! The Internet speed was excellent! Highly recommend, super useful service!"
			},
			4: {
				who: "Ruben Karagezyan",
				"url": "https://www.trustpilot.com/users/66046fa244935e00127ea6ea",
				where: "Poland",
				"text": "The best service which can provide you with every minute connection with friends/relatives/work in any country worldwide. Will not stop using it."
			},
			5: {
				who: "Vito Mware",
				"url": "https://www.trustpilot.com/users/64f5dfd1aedc9d00126d3d08",
				where: "Georgia",
				"text": "<…> I'm really impressed. The coverage quality in most of the countries I've visited has been top-notch, even in some remote areas. What's more surprising is the speed and friendliness of their customer support. <…>"
			},
			6: {
				who: "Anastasia R",
				"url": "https://www.trustpilot.com/users/652bdba2ab515c0012336d43",
				where: "Hong Kong",
				"text": "First tried this spring, absolutely perfect service for those who travel a lot. Cheap Tarifs and easy access to the Internet wherever you are. Just after you landed in any country you are online <…>"
			},
			7: {
				who: "Consumer from Poland",
				"url": "https://www.trustpilot.com/users/65f0c1b98dae0f001290d72c",
				where: "Poland",
				"text": "I used the service in Turkey, Italy and France, it’s very convenient to use, the prices are one of the best and easy to use. I’ve used a lot of similar services, but this one is the best."
			},
			8: {
				who: "Dani Bond",
				"url": "https://www.trustpilot.com/users/64fa1338bda1d800124580a9",
				where: "Georgia",
				"text": "As a digital nomad I basically travel 24/7. I’ve tried out most of the travel eSIMs and UNISIM is by far the best one. It allows me to seamlessly stay connected in every country I go with a single sim card  <…>"
			},
			9: {
				who: "Georgii Popandopulo",
				"url": "https://www.trustpilot.com/users/652fad68ce049d0012dbd20d",
				where: "Germany",
				"text": "Very convenient and affordable service  <…> Tried it in four countries and never had any problems with UNISIM - connection is very fast, easy to top up your account and intuitive website with possibility to install eSIM in one click."
			}
		}
	}
})